import React, {useState,useEffect} from "react";
import {doc, setDoc, collection, DocumentSnapshot, getDocs, getCountFromServer, getFirestore, updateDoc} from 'firebase/firestore';
import {auth_, uploadProfilePic} from '../../firebase';
import {getAuth, onAuthStateChanged, signOut} from "firebase/auth";
import { FirebaseError } from "firebase/app";
import { useNavigate} from 'react-router-dom';
import Popup from "../../components/Popup/Popup";

const GettingStartedUploadPhotoScreen = () =>{

    const [isErrorMessage, setErrorMessage] = useState(false);
    const [isSuccess, setSuccess] = useState(false);

    const navigate = useNavigate();

    const onPrevButton = ()=>{
        navigate("/getstarted_experience");

    }

    const onFinishButton = ()=>{
        navigate("/discover");
        
    }

 

    const [user, setUser] = useState("");
    const [photo, setPhoto] = useState(null);
    const [loading, setLoading] = useState(false);
    const [photoURL, setPhotoURL] = useState("https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_1280.png");

    const getUsers = async() =>{
        const auth = getAuth();
        onAuthStateChanged(auth, (user)=>{
            if(user){
                const userInfo = auth_.currentUser;
                // console.log("user:")
                // console.log(user.displayName);
                // const email =  user.email;
                // const displayName =  user.displayName;
                // const uid = user.uid;
                setUser(userInfo)
                // if(userInfo?.photoURL){
                //     setPhotoURL(userInfo.photoURL);
                // }
                
    
            }
        });
    }

    const handleProfilePicChange = (e)=>{
        if(e.target.files[0]){
            // setPhoto(e.target.files[0])
            setPhoto(e.target.files[0]);
           
            setPhotoURL(  URL.createObjectURL(e.target.files[0]));
        }

    }

    const handleUploadPicButton = ()=>{
        uploadProfilePic(photo, user, setLoading);
        successNotification();
        navigate("/discover");


    }

    const successNotification = () =>{
        setSuccess(!isSuccess);   
    }

    const errorNotification = () =>{
        setErrorMessage(!isErrorMessage);   
    }

    useEffect(()=>{
        getUsers();

        },[])

    return (
        <div>

                 {/* if popup is open dont open */}
                 {isSuccess && 
                    <div>
                    <Popup
                    handleClose = {successNotification}
                    content = {
                    <div>
                        <h2 className="success-popup">Congratulations!</h2>
                        <p>Your image was uploaded!</p>
                    </div>
                    }
                />
                </div>}


                {/* if popup is open dont open */}
                {isErrorMessage && 
                    <div>
                    <Popup
                    handleClose = {errorNotification}
                    content = {
                    <div>
                        <h2 className="error-popup">Error!</h2>
                        <p>There was an issue uploading your image. Try again!</p>
                    </div>
                    }
                />
                </div>}

            <h1>Getting Started!</h1>

            <h2>Upload A Profile Picture</h2>

            <div className="prof_bg">
                <img src={photoURL} alt="ProfilePic" className="profilePic"/>

                <input type="file" onChange={handleProfilePicChange} />
                <button disabled ={loading || !photo} onClick={handleUploadPicButton}>Upload & Finish</button>
            </div>

            <button onClick={onPrevButton}>Prev</button>
            {/* <button onClick={onFinishButton}>Finish</button> */}
        
        </div>

    );
};
export default GettingStartedUploadPhotoScreen;