import React, {useState} from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPeopleGroup } from '@fortawesome/free-solid-svg-icons';
import { useNavigate} from 'react-router-dom';
import {collection, DocumentSnapshot, getDoc, getDocs, setDocs,QuerySnapshot, addDoc, doc, setDoc, getFirestore} from 'firebase/firestore';
import {db, auth_} from './../../firebase';
import { useEffect } from "react";
import { getFID } from "web-vitals";
import addNotification from 'react-push-notification';
import Popup from "../../components/Popup/Popup";
// import {Notifications} from 'react-push-notification';



const CreateDiscussionBoard = () =>{
    const navigate = useNavigate();
    const documentSnapshot = new DocumentSnapshot();
    const user = auth_.currentUser;
    const date = new Date;
    const [isOpen, setIsOpen] = useState(false);
    const [isOpenErrorMessage, setIsOpenErrorMessage] = useState(false);
    const [isOpenDuplicateMessage, setIsOpenDuplicateMessage] = useState(false);
    const [isOpenEmptyMessage, setIsOpenEmptyMessage] = useState(false);


    const [db_Description, saveDBDescription] = useState("");

    const successNotification = () =>{
        // console.log("success message");
        setIsOpen(!isOpen);   
    }

    const warningNotification = () =>{
        // console.log("warning message");
        setIsOpenErrorMessage(!isOpenErrorMessage);   
    }

    const duplicateNotification = () =>{
        // console.log("duplicate message");
        setIsOpenDuplicateMessage(!isOpenDuplicateMessage);   
    }

    const emptyNotification = () =>{
        // console.log("empty message");
        setIsOpenEmptyMessage(!isOpenEmptyMessage);   
    }

    const onSubmitNewDiscussionBoardName = async (event) =>{
        event.preventDefault();
        try{
            // console.log("yo");
            const firestore = getFirestore();
            const name = await getDoc(doc(firestore,"DiscussionBoards",event?.target?.db_name.value));

            if(event?.target?.db_name?.value===null|| event?.target?.db_name?.value.trim()==="" || db_Description.trim()===""){
                emptyNotification();
            }else if(name.exists()){
                // console.log(name);
                duplicateNotification();
            }else{
            setDoc(doc(firestore,"DiscussionBoards",event?.target?.db_name.value),{
                creator: user?.displayName,
                creator_uid: user?.uid,
                creator_email: user?.email,
                dateCreated: date,
                discussionBoardName: event?.target?.db_name.value,
                discussionBoardNameID: event?.target?.db_name.value.replace(/ /g,"").toLowerCase(),
                description: db_Description
            });

            successNotification();
        }

        }catch(event){
            
            // console.log("Error Adding Document: ",event );
            warningNotification();


        }


    }


    return (
        <div>
            {/* if popup is open dont open */}
                    {isOpen && 
                    <div>
                    <Popup
                    handleClose = {successNotification}
                    content = {
                    <div>
                        <h2 className="success-popup">Congratulations!</h2>
                        <p>Your discussion board has been created!</p>
                    </div>
                    }
                />
                </div>}

                {isOpenErrorMessage&&<Popup
                  handleClose = {warningNotification}
                content = {
                <div>
                    <h2 className="error-popup">Error</h2>
                    <p>There was an issue creating your dashboard!</p>
                </div>
                }
            />}

            {isOpenEmptyMessage&&<Popup
                  handleClose = {emptyNotification}
                content = {
                <div>
                    <h2 className="error-popup">Error</h2>
                    <p>Fields can not be empty!</p>
                </div>
                }
            />} 
            
            {isOpenDuplicateMessage&&<Popup
                  handleClose = {duplicateNotification}
                content = {
                <div>
                    <h2 className="error-popup">Error</h2>
                    <p>Dashboard already exists!</p>
                </div>
                }
            />} 
                        
            <h2>Create A Community</h2>

            <form onSubmit={onSubmitNewDiscussionBoardName}>

                <label>
                    Discussion Board Name:
                    <input className="prof_bg_inputs" placeholder="Enter Discussion Board Name" type="text" name="db_name" ></input>

                </label>

                <br/>

     
                <label  className="prof_bg_inputs">Discussion Board Description</label>
                <textarea
                    rows={5} 
                    cols={35}
                    type="text"
                    value={db_Description}
                    onChange = {e => saveDBDescription(e.target.value)}
                    className="prof_bg_inputs"></textarea>
                <br/>
          
                <button type="submit">Submit</button>
            
            </form>

        </div>

    );
};
export default CreateDiscussionBoard;