import React, {useState} from "react";
import { useEffect } from "react";
import { useNavigate} from 'react-router-dom';

import Papa from "papaparse";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBook, faMagnifyingGlass, faPencil, faPeopleGroup } from '@fortawesome/free-solid-svg-icons';

const BookRecommendations = () =>{

    const [csvData, parsedCsvData] = useState([]);
    const navigate = useNavigate();

    const onWRateNowButton  = () =>{
 
        navigate('/finishedreading');

    }

    var file = require("./../books/BookRecommendationList.csv")
    const parseFile = () =>{
        Papa.parse(file, {
            download: true,
            header: false,
            complete: results =>{
                // console.log(results.data)
                parsedCsvData(results.data)
            },
        });

    }

    useEffect(()=>{
        parseFile();
    },[])

    return (
        <main>
            <div>
                <h1>Book Recommendations</h1>
                <p>Rate more books you have read to get better personalized recommendations</p>
                <button onClick={onWRateNowButton}>Rate Books Now!</button>

                
                {/* <div onClick ={onWRateNowButton} className="card-container"> */}
                        {/* {
                            csvData?.slice(0,6).map((data,idx)=>(
                                <div className="card">
                                <br/>
                                {data[2]? <img src={data[2]} height={100} width = {50}/> :
                                <p><FontAwesomeIcon icon={faBook} size ="2xl"/></p>}
                                <p>{data[0]}</p>
                                {data[1]? "by " + data[1] : ""}
                                {/* <button>View</button> */}
                                {/* </div> */}
    
                            {/* )) */}
                            
                        {/* }  */}
                    {/* </div> */}


                    <div onClick ={onWRateNowButton} className="card-container">
                        {
                            csvData?.slice(0,6).map((data,idx)=>(
                                 <img src={data[2]? data[2]:""} className="img-book"/>
    
                            ))
                            
                        }
                    </div>
                    <button>Refresh</button>

            </div>
        </main>
    );
};

export default BookRecommendations;