import React, { useState, useEffect } from "react";
import { useNavigate, useParams, useLocation} from 'react-router-dom';
import {db, auth_} from './../firebase';
import {collection, DocumentSnapshot, getDocs, QuerySnapshot, setDoc, getDoc, doc, getFirestore} from 'firebase/firestore';

import axios from 'axios';


const SearchBooksScreen = () =>{
    const navigate = useNavigate();

    const params = useParams();
    const user = auth_.currentUser;
    const location = useLocation();
    const [book, setBook] = useState("");
    const [result, setResult] = useState([]);
    const [apiKey, setApiKeyhowReader] = useState("AIzaSyBRBD3CnTCZUrc6Qc4WX4PfBKk_9KVHdrw");

    function handleChange(event){
        const book = event.target.value;
        setBook(book);
    }

    function handleSubmit(event){
        event.preventDefault();
        axios.get("https://www.googleapis.com/books/v1/volumes?q="
        +book+"&key="
        +apiKey+"&maxResults=4").then(
            data =>{
                console.log(data.data.items);
                setResult(data.data.items);
            }
        )
       
    }

    const onBookDetailsButton  = (book_name) =>{
        navigate('/book_details/'+book_name,{
            state: {
                id: book_name
            }
        });
    }


    const onToFinishedBooksShelf = async (title, author, search, event ) =>{
        event.preventDefault();
        let date = new Date();
        date = date.toString();
        try{

            const firestore = getFirestore();

    
            const read = await getDoc(doc(firestore, "Users",user.uid, "Finished Book",title));
            const current = await getDoc(doc(firestore, "Users",user.uid, "Currently Reading",title));
            const want = await getDoc(doc(firestore, "Users",user.uid, "Want to Read",title));
    

            if(read.exists || current.exists || want.exists){
                console.log("book exists in shelves. Would you like to move it?");

            }else{
                setDoc(doc(firestore,"Users",user.uid, "Finished Book", title),{
                    Authors: author,
                    DateandTime: date,
                    bookTitle: title,
                    searchTerm: search

                });
                console.log("done!");
            }

        }catch(event){
            console.log("Error Adding Book: "+event );

        }


    }


    const onToCurrentlyReadingBooksShelf = async (title, author, search, event ) =>{
        event.preventDefault();
        let date = new Date();
        date = date.toString();
        try{

            const firestore = getFirestore();

    
            const read = await getDoc(doc(firestore, "Users",user.uid, "Finished Book",title));
            const current = await getDoc(doc(firestore, "Users",user.uid, "Currently Reading",title));
            const want = await getDoc(doc(firestore, "Users",user.uid, "Want to Read",title));
    

            if(read.exists || current.exists || want.exists){
                console.log("book exists in shelves. Would you like to move it?");

            }else{
                setDoc(doc(firestore,"Users",user.uid, "Finished Book", title),{
                    Authors: author,
                    DateandTime: date,
                    bookTitle: title,
                    searchTerm: search

                });
                console.log("done!");
            }

        }catch(event){
            console.log("Error Adding Book: "+event );

        }


    }

    const onToWantToReadBooksShelf = async (title, author, search, event ) =>{
        event.preventDefault();
        let date = new Date();
        date = date.toString();
        try{

            const firestore = getFirestore();

    
            const read = await getDoc(doc(firestore, "Users",user.uid, "Finished Book",title));
            const current = await getDoc(doc(firestore, "Users",user.uid, "Currently Reading",title));
            const want = await getDoc(doc(firestore, "Users",user.uid, "Want to Read",title));
    

            if(read.exists || current.exists || want.exists){
                console.log("book exists in shelves. Would you like to move it?");

            }else{
                setDoc(doc(firestore,"Users",user.uid, "Currently Reading", title),{
                    Authors: author,
                    DateandTime: date,
                    bookTitle: title,
                    searchTerm: search

                });
                console.log("done!");
            }

        }catch(event){
            console.log("Error Adding Book: "+event );

        }


    }

    const onToWishlistBooksShelf = async (title, author, search, event ) =>{
        event.preventDefault();
        let date = new Date();
        date = date.toString();
        try{

            const firestore = getFirestore();

    
            const wishlist = await getDoc(doc(firestore, "Users",user.uid, "Wishlist",title));
       

            if(wishlist.exists){
                console.log("book exists in shelves. Would you like to move it?");

            }else{
                setDoc(doc(firestore,"Users",user.uid, "Wishlist", title),{
                    Authors: author,
                    DateandTime: date,
                    bookTitle: title,
                    searchTerm: search

                });
                console.log("done!");
            }

        }catch(event){
            console.log("Error Adding Book: "+event );

        }


    }


    const onToFavoritesBooksShelf = async (title, author, search, event ) =>{
        event.preventDefault();
        let date = new Date();
        date = date.toString();
        try{

            const firestore = getFirestore();

    
            const favorities = await getDoc(doc(firestore, "Users",user.uid, "Favorites",title));
    

            if(favorities.exists){
                console.log("book exists in shelves. Would you like to move it?");

            }else{
                setDoc(doc(firestore,"Users",user.uid, "Favorites", title),{
                    Authors: author,
                    DateandTime: date,
                    bookTitle: title,
                    searchTerm: search

                });
                console.log("done!");
            }

        }catch(event){
            console.log("Error Adding Book: "+event );

        }


    }


    return (
        <div>
            {/* <h1>{location.state.id}</h1>
            <img src={result.map(book=>(book.volumeInfo.imageLinks.thumbnail))}/>
            <p>{result.map(book=>(book.volumeInfo.description))}</p> */}
            <form onSubmit={handleSubmit}>
                <div>
                    <input className="search_input" onChange={handleChange} placeholder="Enter Book Name" type="text"/>
                    <input className="search_input" type = "submit" value="search"/>
                </div>
                <div className="card-container">
                    {result.map(book => (
                        <div onClick={() =>onBookDetailsButton(book.volumeInfo.title)} className="card">
                            <h3>{book.volumeInfo.title}</h3>
                            <img src={book.volumeInfo.imageLinks!==undefined?book.volumeInfo.imageLinks.thumbnail:''}/>

                        </div>
                    ))}
                </div>
            </form>








            <button>Read</button>
            <button>Currently Read</button>
            <button>Want to Read</button>

            <button>Add to Wishlist</button>
            <button>Add to Favorites</button>

        </div>

    );
};
export default SearchBooksScreen;