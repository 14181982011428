import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { useNavigate} from 'react-router-dom';
import { faBoltLightning, faChess, faArrowTrendUp, faDollarSign, faFire, faSchool, faChalkboardTeacher, faStore } from '@fortawesome/free-solid-svg-icons';


const BookCategories = () =>{
    const navigate = useNavigate();

    const onRaisingMoneyButton  = () =>{
 
        navigate('/raising_money');
    
    }

    const onNonprofitButton  = () =>{
 
        navigate('/nonprofit');
    
    }   
    
    const onBeginnerEntrepreneurButton  = () =>{
 
        navigate('/beginner_entrepreneurship_books');
    
    }

    const onBusinessStrategyButton  = () =>{
 
        navigate('/business_strategy');
    
    }

    const onFinanceButton  = () =>{
 
        navigate('/finance');
    
    }
        
    const onFranchiseButton  = () =>{
 
        navigate('/franchise');
    
    }


    const onPopularBooksButton  = () =>{
 
        navigate('/popular_books');
    
    } 
    
    const onFinancialFreedomBooksButton  = () =>{
 
        navigate('/financial_freedom');
    
    } 
    
    return (
        <main>
            <section className="category-div">
                <div >
                    <h1>Popular Categories</h1>
                    <button class="book-categories" onClick={onBusinessStrategyButton}>  
                        Business Strategy
                        <br/>
                        <br/>
                        <FontAwesomeIcon icon={faChess} size ="2xl"/>
                    </button>
                    <button class="book-categories" onClick={onFinanceButton}>
                        Finance  
                        <br/>
                        <br/>
                        <FontAwesomeIcon icon={faArrowTrendUp} size ="2xl"/>
                    </button>
                    <button class="book-categories" onClick={onFranchiseButton}>
                        Franchise
                        <br/>
                        <br/>
                        <FontAwesomeIcon icon={faStore} size ="2xl"/>
                    </button>
                    <button class="book-categories" onClick={onNonprofitButton}>
                        Nonprofit Business
                        <br/>
                        <br/>
                        <FontAwesomeIcon icon={faSchool} size ="2xl"/>
                    </button>
                    <button class="book-categories" onClick={onRaisingMoneyButton}>
                        Raising Money For Your Business
                        <br/>
                        <br/>
                        <FontAwesomeIcon icon={faDollarSign} size ="2xl"/>
                    </button>
                    <button class="book-categories" onClick={onBeginnerEntrepreneurButton}>
                        Starting A Business/Entrepreneurship Lessons
                        <br/>
                        <br/>
                        <FontAwesomeIcon icon={faChalkboardTeacher} size ="2xl"/>
                    </button>
                    <button class="book-categories" onClick={onPopularBooksButton}>
                        Popular Books
                        <br/>
                        <br/>
                        <FontAwesomeIcon icon={faFire} size ="2xl"/>
                    </button>
                    <button class="book-categories" onClick={onFinancialFreedomBooksButton}>
                        Financial Freedom
                        <br/>
                        <br/>
                        <FontAwesomeIcon icon={faBoltLightning} size ="2xl"/>
                    </button>                    

                </div>
            </section>
        </main>


    );
};

export default BookCategories;