import { Avatar,  Card } from "antd";
import React from "react";

const {Meta} = Card;

const ListCard = ({title, image}) =>{
    return(
        <div style={{ minWidth:"20rem", margin:"auto", overflowWrap:"break-word",  whiteSpace: "pre-wrap"}}>
                <Card hoverable style={{textAlign:"left", marginBottom:"1rem", backgroundColor: "#b0bec5"}}>
                    <Meta
                    avatar= {<Avatar shape="square" 
                    size={{md:140, lg:140, xl:140, xxl:140}}
                    src={image} className="img-book"/>}
                        title = {title}
                        // description=" sample description"

                    />

                </Card>
        </div>

    );
}

export default ListCard;