import React, {useState} from "react";
import { useNavigate, useLocation} from 'react-router-dom';
import {collection, setDoc, doc, deleteDoc, getFirestore, getDoc, DocumentSnapshot, getDocs, QuerySnapshot} from 'firebase/firestore';
import {db, auth_, getProfilePic} from './../firebase';
import Popup from "../components/Popup/Popup";




const BookRatings = () =>{

    const location = useLocation();
    const user = auth_.currentUser;

    const [isOpenErrorMessage, setIsOpenErrorMessage] = useState(false);


    const [bookHelpedBusiness, setBookHelpedBusinessRB] = useState('no');
    const [rating, setRating] = useState('');
    const [bookReviewText, savebookReviewText] = useState('');

              {/* Marketing */}
                {/* Accounting */}
                {/* Raising Capital */}
                {/* Franchise */}  
                {/* Product Management */}   
                {/* Sales */} 
                {/* Finance */}  
                {/* Beginners */}
                {/* Business Acquisition */}
    const [marketingChecked, setMarketingChecked] = useState(false);
    const [accountingChecked, setAccountingChecked] = useState(false);
    const [raisingCapitalChecked, setRaisingCapitalChecked] = useState(false);
    const [franchiseChecked, setFranchiseChecked] = useState(false);
    const [manageTeamsChecked, setManageTeamsChecked] = useState(false);
    const [productManagementChecked, setProductManagementChecked] = useState(false);
    const [salesChecked, setSalesChecked] = useState(false);
    const [financeChecked, setFinanceChecked] = useState(false);
    const [beginnersChecked, setBeginnersChecked] = useState(false);
    const [businessAcquisitionChecked, setBusinessAcqusitionChecked] = useState(false);


    const warningNotification = () =>{
        // console.log("warning message");
        setIsOpenErrorMessage(!isOpenErrorMessage);   
    }

    const onSubmitReview =(e)=>{
        e.preventDefault();

        console.log(bookHelpedBusiness);
        console.log(rating);

        console.log(marketingChecked);
        console.log(accountingChecked);
        console.log(raisingCapitalChecked);
        console.log(franchiseChecked);
        console.log(productManagementChecked);
        console.log(salesChecked);
        console.log(financeChecked);
        console.log(beginnersChecked);
        console.log(businessAcquisitionChecked);

        console.log(bookReviewText);

        const date = new Date;

        setDoc(doc(db,"BookRatings",location?.state?.book_title,user.uid),{
            bookGoodFor: "Marketing="+marketingChecked+"; Sales="+salesChecked+"; Accounting="+accountingChecked+"; Finance="+financeChecked+"; RaisingCapital="+raisingCapitalChecked+"; Beginners="+beginnersChecked+"; Franchise="+franchiseChecked+"; ManagingTeams="+manageTeamsChecked+"; ProductManagement="+productManagementChecked+"; BusinessAcquisitions="+businessAcquisitionChecked,
            bookHelpedBusiness: bookHelpedBusiness,
            rating: rating,
            review: bookReviewText,
            reviewCreated: date,
            uid: user.uid,
        });
    } 
    
    const handleRBChange = (value) =>{
        setBookHelpedBusinessRB(value)
    }
    const handleRatingChange = (event) =>{
        setRating(event.target.value);
    }
    
    // checkboxes
    const handleMarketingCheckboxChange = () =>{
        setMarketingChecked(!marketingChecked);
    }
    const handleAccountingCheckboxChange = () =>{
        setAccountingChecked(!accountingChecked);
    } 
    const handleRaisingCapitalCheckboxChange = () =>{
        setRaisingCapitalChecked(!raisingCapitalChecked);
    } 
    const handleFranchiseCheckboxChange = () =>{
        setFranchiseChecked(!franchiseChecked);
    }
    const handleManagingTeamsCheckboxChange = () =>{
        setManageTeamsChecked(!manageTeamsChecked);
    }      
    const handleProductManagementCheckboxChange = () =>{
        setProductManagementChecked(!productManagementChecked);
    } 
    const handleSalesCheckboxChange = () =>{
        setSalesChecked(!salesChecked);
    } 
    const handleFinanceCheckboxChange = () =>{
        setFinanceChecked(!financeChecked);
    } 
    const handleBeginnerCheckboxChange = () =>{
        setBeginnersChecked(!beginnersChecked);
    } 
    const handleBusinessAcquisitionCheckboxChange = () =>{
        setBusinessAcqusitionChecked(!businessAcquisitionChecked);
    }     


    return(

        <div>

        {isOpenErrorMessage&&<Popup
                  handleClose = {warningNotification}
                content = {
                <div>
                    <h2 className="error-popup">Error</h2>
                    <p>Rating can not be blank!</p>
                </div>
                }
            />}

            <h2>Rate & Review: {location?.state?.book_title}</h2>

            <form onSubmit={onSubmitReview}>
                <div className="book-rating-div">
                <label>Did this book help you with your business</label>
                <div>
                <label>
                    <input type ="radio" value = "yes" checked={bookHelpedBusiness === "yes"} onChange={()=>handleRBChange("yes")}/>
                    Yes
                </label>
                </div>
                <div>
                <label>
                    <input type ="radio" value = "no"  checked={bookHelpedBusiness === "no"} onChange={()=>handleRBChange("no")}/>
                    No
                </label>
                </div>
                </div>
        
                <div className="book-rating-div">
                <label>This book is good for?
                <div>
                    <input type ="checkbox" value ={marketingChecked} onChange={handleMarketingCheckboxChange}/>
                    <span>Marketing</span>
                </div>
                <div>
                    <input type ="checkbox" value ={accountingChecked} onChange={handleAccountingCheckboxChange}/>
                    <span>Accounting</span>
                </div>
                <div>
                    <input type ="checkbox" value ={raisingCapitalChecked} onChange={handleRaisingCapitalCheckboxChange}/>
                    <span>Raising Capital</span>
                </div>
                <div>
                    <input type ="checkbox" value ={franchiseChecked} onChange={handleFranchiseCheckboxChange}/>
                    <span>Franchise</span>
                </div>
                <div>
                    <input type ="checkbox" value ={manageTeamsChecked} onChange={handleManagingTeamsCheckboxChange}/>
                    <span>Managing Teams</span>
                </div>                
                <div>
                    <input type ="checkbox" value ={productManagementChecked} onChange={handleProductManagementCheckboxChange}/>
                    <span>Product Management</span>
                </div>
                <div>
                    <input type ="checkbox" value ={salesChecked} onChange={handleSalesCheckboxChange}/>
                    <span>Sales</span>
                </div>
                <div>
                    <input type ="checkbox" value ={financeChecked} onChange={handleFinanceCheckboxChange}/>
                    <span>Finance</span>
                </div>
                <div>
                    <input type ="checkbox" value ={beginnersChecked} onChange={handleBeginnerCheckboxChange}/>
                    <span>Beginners</span>
                </div>
                <div>
                    <input type ="checkbox" value ={businessAcquisitionChecked} onChange={handleBusinessAcquisitionCheckboxChange}/>
                    <span>Business Acquisition</span>
                </div>
                </label>
                </div>


                {/* check boxes: */}
                {/* Marketing */}
                {/* Accounting */}
                {/* Raising Capital */}
                {/* Franchise */}  
                {/* Product Management */}   
                {/* Sales */} 
                {/* Finance */}  
                {/* Beginners */}
                {/* Business Acquisition */} 
                <div className="book-rating-div">
                <label>Rate Book?
                {/* 1 - 5 (1/4) */}
                <select  onChange={handleRatingChange}>
                    <option value="1">1</option>
                    <option value="1.25">1.25</option>
                    <option value="1.5">1.5</option>
                    <option value="1.75">1.75</option>
                    <option value="2">2</option>
                    <option value="2.25">2.25</option>
                    <option value="2.5">2.5</option>
                    <option value="2.75">2.75</option>
                    <option value="3">3</option>
                    <option value="3.25">3.25</option>
                    <option value="3.5">3.5</option>
                    <option value="3.75">3.75</option>
                    <option value="4">4</option> 
                    <option value="4.25">4.25</option>
                    <option value="4.5">4.5</option>
                    <option value="4.75">4.75</option>
                    <option value="5">5</option>                                                                              
                </select>
                </label>
                </div>

                <div className="book-rating-div">
                <label>Write a Review?

                <textarea
                 rows={5} 
                 cols={35}
                 type="text"
                 value={bookReviewText}
                 onChange = {e => savebookReviewText(e.target.value)}></textarea>
                </label>
                </div>

                <button type="submit"> Submit Review</button>
            </form>
        </div>
    );
}

export default BookRatings;