import React, { useState,useEffect } from "react";
import { useNavigate, useParams, useLocation} from 'react-router-dom';
import {collection, DocumentSnapshot, getDocs, getDoc, setDoc, doc, deleteDoc, getCountFromServer, getFirestore} from 'firebase/firestore';
import {auth_, db, getProfilePic} from './../firebase';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBookReader, faCheck} from '@fortawesome/free-solid-svg-icons';
import {v4 as uuidv4} from 'uuid';
import {MdChevronLeft, MdChevronRight} from "react-icons/md";


const ReaderProfile = () =>{
    // const params = useParams();
    const location = useLocation();
    const navigate  = useNavigate();
    const user = auth_.currentUser;

    // const [user, setUser] = useState(props.location.state ? 
    //     props.location.state:'')

    // console.log("PROPS");
    // console.log( location.state.id.displayName);

    const [followingCount, countFollowing] = useState("")
    const [followersCount, countFollowers] = useState("")
    const [booksReadCount, countFinishedBooks] = useState("")
    const [reader, showUserInfo] = useState([]);

    const [FinishedReadingBooks, showFinishedReadingBooks] = useState([]);
    const [CurrentlyReadingBooks, showCurrentlyReadingBooks] = useState([]);
    const [WantToReadBooks, showWantToReadBooks] = useState([]);
    const [Wishlist, showWishlistBooks] = useState([]);
    const [Favorites, showFavoriteBooks] = useState([]);
    const [isFollowingReader, setAsFollowingReader] = useState(false);

    const [aboutText, showAboutText] = useState();
    const [profBackgroundText, showProfBackgroundText] = useState("");
    const [companyDescriptionText, showCompanyDescriptionText] = useState("");
    const [roleInCompanyText, showRoleInCompany] = useState("");
    const [ownershipYears, showOwnershipYears] = useState("");
    const [industryText, showIndustryText] = useState("");

    const [photoURL, setPhotoURL] = useState("https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_1280.png");


    const slideLeft_finished = ()=>{
        var slider = document.getElementById("finishedSlider")
        // sroll left by 500px
        slider.scrollLeft = slider.scrollLeft - 500;

    }

    const slideRight_finished = ()=>{
        var slider = document.getElementById("finishedSlider")
        // sroll right (plus sign) by 500px
        slider.scrollLeft= slider.scrollLeft + 500;

    }

    const slideLeft_current = ()=>{
        var slider = document.getElementById("currentSlider")
        // sroll left by 500px
        slider.scrollLeft = slider.scrollLeft - 500;

    }

    const slideRight_current = ()=>{
        var slider = document.getElementById("currentSlider")
        // sroll right (plus sign) by 500px
        slider.scrollLeft= slider.scrollLeft + 500;

    }

    const slideLeft_want = ()=>{
        var slider = document.getElementById("wantSlider")
        // sroll left by 500px
        slider.scrollLeft = slider.scrollLeft - 500;

    }

    const slideRight_want = ()=>{
        var slider = document.getElementById("wantSlider")
        // sroll right (plus sign) by 500px
        slider.scrollLeft= slider.scrollLeft + 500;

    }
     

    const getAboutandExperienceInfo = async () =>{
        try{

        const aboutAndExperienceInformation =  await getDoc(doc(db, "Users", location.state.id));
        if(aboutAndExperienceInformation.data()?.photoURL){
            setPhotoURL(aboutAndExperienceInformation.data().photoURL);
        }
            if(aboutAndExperienceInformation.data()?.about){
            showAboutText(aboutAndExperienceInformation.data().about);
            }

            if(aboutAndExperienceInformation.data()?.experience?.professional_background){
                showProfBackgroundText(aboutAndExperienceInformation.data().experience.professional_background);
            }

            if(aboutAndExperienceInformation.data()?.experience?.company_desc){
                showCompanyDescriptionText(aboutAndExperienceInformation.data().experience.company_desc);
            }

            if(aboutAndExperienceInformation.data()?.experience?.company_role){
                showRoleInCompany(aboutAndExperienceInformation.data().experience.company_role);
            }

            if(aboutAndExperienceInformation.data()?.experience?.company_years_in){
                showOwnershipYears(aboutAndExperienceInformation.data().experience.company_years_in);
            }

            if(aboutAndExperienceInformation.data()?.experience?.industry){
                showIndustryText(aboutAndExperienceInformation.data().experience.industry);
            }
        }catch(error){
            console.log(error);
        }
    
    
    
        
       
    }
    const followReader = async (reader_name, reader_uid, reader_email) =>{
        const firestore = getFirestore();
        const date = new Date;
        const followUniqueID = uuidv4();

        // console.log("reader ");
        // console.log(reader_name);
        // console.log(reader_uid);
        // console.log(reader_email);

       
        const followingReader = await getDoc(doc(firestore,"Users",reader_uid,"Followers", user.uid));

        if(followingReader.data()){
            console.log("Member already is following!");

            setAsFollowingReader(!isFollowingReader);
            deleteDoc(doc(firestore,"Users",reader_uid,"Followers", user.uid));
            deleteDoc(doc(firestore,"Users",user.uid,"Following", reader_uid));

            if(followingReader.followID){
                deleteDoc(doc(firestore,"Users",reader_uid,"Notifications", user.uid + followingReader.followID + reader_uid));
            }

          


        }else{
            // console.log("Member is now following!");
            setAsFollowingReader(!isFollowingReader);

            try {

            setDoc(doc(firestore,"Users", user.uid,"Following", reader_uid),{
                DateandTime: date,
                followingDisplayName: reader_name,
                followingEmail: reader_email,
                followID: followUniqueID,
                followingUID: reader_uid,
            });
            
            setDoc(doc(firestore,"Users",reader_uid,"Followers", user.uid),{
                DateandTime: date,
                followerDisplayName: user.displayName,
                followerEmail: user.email,
                followerUID: user.uid,
            });

            setDoc(doc(firestore,"Users",reader_uid,"Notifications", user.uid + followUniqueID+reader_uid),{
                DateandTime: date,
                followerDisplayName: user.displayName,
                followerEmail: user.email,
                followID: followUniqueID,
                followerUID: user.uid,
                type: "follow",
                isRead: false
            });

            setDoc(doc(firestore,"mail", user.uid + followUniqueID+reader_uid),{
                to: reader_email,
                message: {
                    subject: "You have a new follower!",
                    html:  user?.displayName + " just started following you! <code><br/><button><a href='https://entrepreneurshipbooksapp.com/notifications'>View</a></button></code>",

                }
            });

            setDoc(doc(firestore,"Users",user.uid,"Following", reader_uid),{
                DateandTime: date,
                followingDisplayName: user.displayName,
                followID: followUniqueID,
                followingUID: reader_uid,
            });

        }catch(error){
            // console.log("following error");
            console.log(error);

    }
}

    }

    const getFollowers = async() =>{
        const firestore = getFirestore();
        const c = collection(firestore, "Users",location.state.id, "Followers");
        const count = await getCountFromServer(c);
        countFollowers(count.data().count);
        }

    const getFollowing = async() =>{
            const firestore = getFirestore();
            const c = collection(firestore, "Users",location.state.id, "Following");
            const count = await getCountFromServer(c);
            countFollowing(count.data().count);
            } 


    const getCurrentlyReadingBooks = async() =>{
    
                    await getDocs(collection(db, "Users", location.state.id, "Currently Reading")).then((querySnapshot)=>{
                        const book = querySnapshot.docs.map((doc)=>({
                            ...doc.data()
                        }));
        
                            // console.log("BOOKS:");
                            // console.log(book);
                            showCurrentlyReadingBooks(book);
                
                    });     
        }
        
        
        const getFinishedReadingBooks = async() =>{
                        await getDocs(collection(db, "Users", location.state.id, "Finished Book")).then((querySnapshot)=>{
                            const book = querySnapshot.docs.map((doc)=>({
                                ...doc.data()
                            }));
                                // console.log("BOOKS:");
                                // console.log(book);
                                showFinishedReadingBooks(book);       
                        });   
            }
        
            const getWantToReadBooks = async() =>{
                        await getDocs(collection(db, "Users", location.state.id, "Want to Read")).then((querySnapshot)=>{
                            const book = querySnapshot.docs.map((doc)=>({
                                ...doc.data()
                            }));
                                // console.log("BOOKS:");
                                // console.log(book);
                                showWantToReadBooks(book);       
                        });   
            } 
            
            
            const onFollowingButton  = () =>{
                navigate("/"+location?.state?.username.toLowerCase().replace(/ /g,"")+'/following',{
                    state:{
                        username: location?.state?.username,
                        id: location?.state?.id
                    }
                });
            }

    const onFollowersButton  = () =>{
                navigate("/"+location?.state?.username.toLowerCase().replace(/ /g,"")+'/followers',{
                    state:{
                        username: location?.state?.username,
                        id: location?.state?.id
                    }
                });
            }

            const messageReader = (person)=>{
                navigate("/inbox/"+person.displayName.toLowerCase().replace(/ /g,""),{
                    state:{
                        id: person.uid
                    }
                })
            }

            const checkFollowing = async () =>{
                try{
        
                    const firestore = getFirestore();
                    const following = await getDoc(doc(firestore,"Users",user?.uid,"Following",location?.state?.id));
                    // console.log(member);
                    if(following.data()){
                        // console.log(following.data());
                        setAsFollowingReader(!isFollowingReader);
                    }else{
                        setAsFollowingReader(isFollowingReader);
                    }
                }catch(e){
                        console.log(e);
                    }
        
            }
        


    const getUser = async() =>{
   
                const userInfo = await getDoc(doc(db, "Users", location?.state?.id));
               
                showUserInfo(userInfo.data());

                // const userFollowing = await getDoc(doc(db, "Users", location?.state?.id, "Followers", user.uid));
                // // console.log("Test FOLLOW");
                // // console.log(userFollowing.data());
                // if(userFollowing.data()){

                //     setAsFollowingReader(!isFollowingReader)
                // }

             
            }

    useEffect(()=>{
        getFollowers();
        getFollowing();
        getFinishedReadingBooks();
        getCurrentlyReadingBooks();
        getWantToReadBooks();
        getAboutandExperienceInfo();
        getUser();
        checkFollowing();

        
    },[followingCount, followersCount])


    return (
        <div>
            <h1>{location?.state?.username }</h1>
            {/* <img src={photoURL} alt="ProfilePic" className="profilePic"/> */}
            <img src={reader.photoURL? reader.photoURL : photoURL} alt="ProfilePic" className="profilePic"/>        


            <p><a className = "a_following_followers" onClick={()=>onFollowersButton()}>{followersCount} Followers</a> and <a className = "a_following_followers" onClick={()=>onFollowingButton()}>{followingCount} Following</a></p>

            {/* {isFollowingReader?<p>hi</p>:<p>no</p>} */}

            {isFollowingReader? <button onClick={()=>followReader(location?.state?.username, location?.state?.id, location?.state?.email )} className="button_followed_reader">Following <FontAwesomeIcon icon={faCheck}/></button>
                :<button onClick={()=>followReader(location?.state?.username,location?.state?.id, location?.state?.email )}>Follow</button>}

                {user.uid !== reader.uid? <button onClick={()=>messageReader(reader)} >Message </button>
                :""}
                        
            <div className="horizontal-line"></div>


            <div className="profile-info-background">
            <h2>Professional Background</h2>
            
            <h3>About</h3>
            <div className="card-profile">
            <label className="prof_bg_inputs">About</label>
            <div className="horizontal-line"></div>
            <p>{aboutText ? aboutText : "No response yet. "}</p>
            </div>

            <h3>Experience</h3>
            <div className="card-profile">
            <label className="prof_bg_inputs">Professional Background</label>
            <div className="horizontal-line"></div>
            <p>{profBackgroundText ? profBackgroundText : "No response yet."}</p>
            </div>

            <div className="card-profile">
            <label className="prof_bg_inputs">Company Description</label>
            <div className="horizontal-line"></div>
            <p>{companyDescriptionText ? companyDescriptionText : "No response yet."}</p>
            </div>

            <div className="card-profile">
            <label className="prof_bg_inputs">Your role in that company</label>
            <div className="horizontal-line"></div>
            <p>{roleInCompanyText ? roleInCompanyText : "No response yet."}</p>
            </div>

            <div className="card-profile">
            <label className="prof_bg_inputs">Years of Ownership Experience</label>
            <div className="horizontal-line"></div>
            <p>{ownershipYears ? ownershipYears : "No response yet."}</p>
            </div>

            <div className="card-profile">
            <label className="prof_bg_inputs">Industry</label>
            <div className="horizontal-line"></div>
            <p>{industryText ? industryText : "No response yet."}</p>
            </div>

            </div>

            <div className="horizontal-line"></div>

            <h1>Library</h1>


            <h3>Finished Reading </h3>
            {/* <div className="card-container">
                    {
                        FinishedReadingBooks?.map((book,idx)=>(
                            <div className="card">
                                <br/>
                                <img src={book.bookImage} height={100} width={60}/>
                                <h2 key={idx}>{book.bookTitle}</h2>
                            </div>

                        ))
                        
                    }
            </div> */}

            <div className="carosel-homepage-books">
            <MdChevronLeft className="slider-buttons" onClick={slideLeft_finished} size={40}/>

                        <div id="finishedSlider" className="sliding-div">
                           {
                            FinishedReadingBooks?.slice(0,4).map((book,idx)=>(
                           
                                     <img className="img-book" src={book.bookImage? book.bookImage: ""} /> 
                    
                                ))  
                            }
                        </div>
                        <MdChevronRight className="slider-buttons" onClick={slideRight_finished} size={40}/>


            </div>
            <h3>Currently Reading</h3>
            {/* <div className="card-container">
                    {
                        CurrentlyReadingBooks?.map((book,idx)=>(
                            <div className="card">
                                <br/>
                                <img src={book.bookImage} height={100} width={60}/>
                                <h2 key={idx}>{book.bookTitle}</h2>
                            </div>

                        ))
                        
                    }


            </div> */}

        <div className="carosel-homepage-books">
        <MdChevronLeft className="slider-buttons" onClick={slideLeft_current} size={40}/>

                        <div id="currentSlider" className="sliding-div">
                           {
                            CurrentlyReadingBooks?.slice(0,4).map((book,idx)=>(
                           
                                     <img className="img-book" src={book.bookImage? book.bookImage: ""} /> 
                    
                                ))  
                            }
                        </div>
                        <MdChevronRight className="slider-buttons" onClick={slideRight_current} size={40}/>


            </div>

            
            <h3>Want To Read </h3>
            {/* <div className="card-container">
                    {
                        WantToReadBooks?.map((book,idx)=>(
                            <div className="card">
                                <br/>
                                <img src={book.bookImage} height={100} width={60}/>
                                <h2 key={idx}>{book.bookTitle}</h2>
                            </div>

                        ))
                        
                    }
            </div> */}

            <div className="carosel-homepage-books">
            <MdChevronLeft className="slider-buttons" onClick={slideLeft_want} size={40}/>

                        <div id="wantSlider" className="sliding-div">
                           {
                            WantToReadBooks?.slice(0,4).map((book,idx)=>(
                           
                                     <img className="img-book" src={book.bookImage? book.bookImage: ""} /> 
                    
                                ))  
                            }
                        </div>
                        <MdChevronRight className="slider-buttons" onClick={slideRight_want} size={40}/>


            </div>
        </div>

    );
};
export default ReaderProfile;