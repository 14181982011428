import React, {useState} from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuestion } from '@fortawesome/free-solid-svg-icons';
import { useNavigate, useLocation} from 'react-router-dom';
import {collection,getDoc, doc,  DocumentSnapshot, getDocs, QuerySnapshot} from 'firebase/firestore';
import {db, auth_} from './../../firebase';
import { useEffect } from "react";




const DiscussionBoardQuestionsScreen = () =>{
    const navigate = useNavigate();
    const documentSnapshot = new DocumentSnapshot();
    const user = auth_.currentUser;
    const location = useLocation();

    const [DiscussionBoardQuestions, showDiscussionBoardQuestions] = useState([]);
    const [author, getAuthorInfo] = useState([]);

    const [photoURL, setPhotoURL] = useState("https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_1280.png");

    const questionArray = [];
    const authorArray = [];


    const onViewButton  = (club, question, details) =>{
        navigate('/discussionboardquestion/'+club.toLowerCase().replace(/ /g,"")+"/"+question.toLowerCase().replace(/ /g,"") ,{
            state:{
                user_question: question, 
                club_name: club,
                question_details: details
            }

        });
    }

    const onAskQuestionButton  = (db_name) =>{
        navigate('/creatediscussionboardquestion',{
            state:{
                id: db_name
            }
        });
    }




    const getDiscussionBoardQuestions = async() =>{

        const dbQuestions = await getDocs(collection(db, "DiscussionBoards",location?.state?.id, "Questions"));
        const questions =  dbQuestions.docs;
        // questionArray.push(questionInfo.data());
        // showDiscussionBoardQuestions(questions.data());

        // console.log(questions.data());



        questions.forEach( async(question) => {
            // const questionInfo =  await getDoc(doc(db, "Users", question.data().author_uid));
            const questionAuthor = await getDoc(doc(db, "Users", question.data().author_uid));


            questionArray.push(question.data());
            showDiscussionBoardQuestions(questionArray);
            // console.log(question.data());


            authorArray.push(questionAuthor.data());
            getAuthorInfo(authorArray);
          });

    }


    const onViewReaderProfileButton  = (reader) =>{
     
        navigate('/reader_profile/'+reader.displayName.toLowerCase().replace(/ /g,''),{
        state: {
            id: reader.uid,
            username: reader.displayName,
            email: reader.email
        }
    });
    }
    

    useEffect(()=>{
        getDiscussionBoardQuestions();
    },[])


    return (
        <div>
            
            <button onClick={()=>onAskQuestionButton(location?.state?.id)}>Ask A Question</button>

            {/* <p>{DiscussionBoardQuestions.question}</p> */}

              <div className="card-container-conversations"> 
                    {
                        DiscussionBoardQuestions?.map((question, idx) => (
                           
                        <div onClick={()=>onViewButton(location?.state?.id, question?.question, question?.details)} className="conversations-card question">
                            <div className="user-info-on-conversation-card" onClick={()=>onViewReaderProfileButton(author[idx])}>
                            <img src={author[idx].photoURL? author[idx].photoURL : photoURL} alt="ProfilePic" className="profilePic"/>        
                            <h2>Asked by {author[idx].displayName}</h2>
                            <h2 key={idx}>Asked on {question?.dateAsked}</h2>
                            </div>

                            <div className="info-on-conersation-card" >
                            <h2 key={idx}>{question?.question}</h2>
                            <p key={idx}>{question?.details}</p>
                            </div>
                           



                            {/* <button onClick={()=>onViewButton(location.state.id, question.question)}>View</button> */}
                            </div>
                        
                        ))
                    }
                    </div> 

        </div>

    );
};
export default DiscussionBoardQuestionsScreen;