import React, { useState, useEffect } from "react";
import { useNavigate, useParams, useLocation} from 'react-router-dom';
import { doc, collection, DocumentSnapshot, getDoc, getDocs, QuerySnapshot, getFirestore, setDoc} from 'firebase/firestore';
import {db, auth_} from './../../firebase';
import axios from 'axios';
import Popup from 'reactjs-popup';


const BookClubBookDetails = () =>{
    const params = useParams();
    const navigate = useNavigate();
    const user = auth_.currentUser;
    const location = useLocation();
    const [book, setBook] = useState("");
    const [result, setResult] = useState([]);
    const [apiKey, setApiKeyhowReader] = useState("AIzaSyBRBD3CnTCZUrc6Qc4WX4PfBKk_9KVHdrw");

    function handleChange(event){
        const book = event?.target?.value;
        setBook(book);
    }

    function handleSubmit(book){
        // event.preventDefault();
        axios.get("https://www.googleapis.com/books/v1/volumes?q="
        +book+"&key="
        +apiKey+"&maxResults=1").then(
            data =>{
                // console.log(data.data.items);
                setResult(data?.data?.items);
            }
        )
       
    }
    const onSearchBookButton  = () =>{
        navigate('/search_books');
    }



    const onToFinishedBooksShelf = async (title, category, image, event ) =>{
        event.preventDefault();
        let date = new Date();
        date = date.toString();
        try{

            const firestore = getFirestore();

    
            const read = await getDoc(doc(firestore, "Users",user?.uid, "Finished Book",title));
            const current = await getDoc(doc(firestore, "Users",user?.uid, "Currently Reading",title));
            const want = await getDoc(doc(firestore, "Users",user?.uid, "Want to Read",title));
            if(read.exists || current.exists || want.exists){
                // return (<div><p>Already exists!</p></div>);
                console.log("book exists in shelves. Would you like to move it?");

            }else{
                setDoc(doc(firestore,"Users",user?.uid, "Finished Book", title),{
                    DateandTime: date,
                    book_category: category,
                    book_image:image,
                    book_title: title

                });
                console.log("done!");
            }

        }catch(event){
            console.log("Error Adding Book: "+event );

        }


    }


    const onToCurrentlyReadingBooksShelf = async (title, category, image, event ) =>{
        event.preventDefault();
        let date = new Date();
        date = date.toString();
        try{

            const firestore = getFirestore();

    
            const read = await getDoc(doc(firestore, "Users",user?.uid, "Finished Book",title));
            const current = await getDoc(doc(firestore, "Users",user?.uid, "Currently Reading",title));
            const want = await getDoc(doc(firestore, "Users",user?.uid, "Want to Read",title));
    

            if(read.exists || current.exists || want.exists){
                return (<div></div>);

                {/* {console.log("book exists in shelves. Would you like to move it?")}; */}
                

            }else{
                setDoc(doc(firestore,"Users",user?.uid, "Finished Book", title),{
                    DateandTime: date,
                    book_category: category,
                    book_image:image,
                    book_title: title

                });
                console.log("done!");
            }

        }catch(event){
            console.log("Error Adding Book: "+event );

        }


    }

    const onToWantToReadBooksShelf = async (title, category, image, event ) =>{
        event.preventDefault();
        let date = new Date();
        date = date.toString();
        try{

            const firestore = getFirestore();

    
            const read = await getDoc(doc(firestore, "Users",user?.uid, "Finished Book",title));
            const current = await getDoc(doc(firestore, "Users",user?.uid, "Currently Reading",title));
            const want = await getDoc(doc(firestore, "Users",user?.uid, "Want to Read",title));
    

            if(read.exists || current?.exists || want?.exists){
                console.log("book exists in shelves. Would you like to move it?");

            }else{
                setDoc(doc(firestore,"Users",user?.uid, "Currently Reading", title),{
                    DateandTime: date,
                    book_category: category,
                    book_image:image,
                    book_title: title

                });
                console.log("done!");
            }

        }catch(event){
            console.log("Error Adding Book: "+event );

        }


    }

    const onToWishlistBooksShelf = async (title, category, image, event ) =>{
        event.preventDefault();
        let date = new Date();
        date = date.toString();
        try{

            const firestore = getFirestore();

    
            const wishlist = await getDoc(doc(firestore, "Users",user?.uid, "Wishlist",title));
       

            if(wishlist.exists){
                console.log("book exists in shelves. Would you like to move it?");

            }else{
                setDoc(doc(firestore,"Users",user?.uid, "Wishlist", title),{
                    DateandTime: date,
                    book_category: category,
                    book_image:image,
                    book_title: title

                });
                console.log("done!");
            }

        }catch(event){
            console.log("Error Adding Book: "+event );

        }


    }


    const onToFavoritesBooksShelf = async (title, category, image, event ) =>{
        event.preventDefault();
        let date = new Date();
        date = date.toString();
        try{

            const firestore = getFirestore();

    
            const favorities = await getDoc(doc(firestore, "Users",user?.uid, "Favorites",title));
    

            if(favorities.exists){
                console.log("book exists in shelves. Would you like to move it?");

            }else{
                setDoc(doc(firestore,"Users",user?.uid, "Favorites", title),{
                    DateandTime: date,
                    book_category: category,
                    book_image:image,
                    book_title: title

                });
                console.log("done!");
            }

        }catch(event){
            console.log("Error Adding Book: "+event );

        }


    }

    useEffect(()=>{
        handleSubmit(location?.state?.book_title)
    },[])

    return (
        <div>
            <h1>{location?.state?.book_title}</h1>

            {result.map(
                book=>(
                    <div>
                        <img src={book.volumeInfo?.imageLinks!==undefined?book.volumeInfo.imageLinks.thumbnail:''}/>
                        <p>{book?.volumeInfo?.description}</p>
                    </div>
                ))}

           
            {/* <button onClick={onSearchBookButton}>Search More Books</button> */}

            <button>Buy Book</button>
            <button onClick={(e)=>onToFinishedBooksShelf(location?.state?.book_title, location?.state?.book_category, location?.state?.book_image,e)}>Finished Reading</button>
            <button onClick={(e)=>onToCurrentlyReadingBooksShelf(location?.state?.book_title, location?.state.book_category, location?.state?.book_image,e)}>Currently Read</button>
            

        </div>

    );
};
export default BookClubBookDetails;