import { useNavigate, useLocation} from 'react-router-dom';
import React, {useState, useEffect} from "react";
import {collection, deleteDoc, DocumentSnapshot, getFirestore, getDoc, setDoc, getDocs, doc} from 'firebase/firestore';
import {db, auth_} from './../firebase';


const Following = () =>{

    const [photoURL, setPhotoURL] = useState("https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_1280.png");
    const [readersFollowing, showFollowing] = useState([]);
    const followingArray = [];

    const location = useLocation();
    const navigate = useNavigate();


    const getFollowing = async() =>{

        const userFollowing = await getDocs(collection(db, "Users", location?.state?.id, "Following"));
        const uf = userFollowing.docs;
        uf.forEach( async(following) => {
            const userInfo =  await getDoc(doc(db, "Users", following.data().followingUID));

            followingArray.push(userInfo.data());
            showFollowing(followingArray);
          });

    }

    const onViewReaderProfileButton  = (user) =>{
     
        navigate('/reader_profile/'+user.displayName.toLowerCase().replace(/ /g,""),{
        state: {
            id: user.uid,
            username: user.displayName
        }
    });
    }

    useEffect(()=>{
        getFollowing();
        
    },[])

    return (
        <div>
            <h2>{location?.state?.username} Following</h2>
        <div className="following_follower_list">
        {
        readersFollowing?.filter(following => following.displayName!=="").map((following,idx)=>( 
            <div onClick={() => onViewReaderProfileButton(following)} className="following_follower_info"> 
                <img src={following.photoURL? following.photoURL : photoURL} alt="ProfilePic" className="profilePic"/>        
                <h2 key={idx}>{following.displayName}</h2>     
                <div className='horizontal-line'></div>
            </div> 


        ))    

     }



    </div>
    </div>
    );
}

export default Following;