import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBook, faMagnifyingGlass, faPencil, faPeopleGroup } from '@fortawesome/free-solid-svg-icons';
import React from 'react';

export const feats = [
    {
        icon: <FontAwesomeIcon icon={faBook} size ="2xl"/>,
        name:'Find Books',
        desc: 'Find books to help grow your business.'
    },

    {
        icon: <FontAwesomeIcon icon={faPeopleGroup} size ="2xl"/>,
        name:'Find Readers',
        desc: 'Connect with other readers.'
    },

    {
        icon: <FontAwesomeIcon icon={faPencil} size ="2xl"/>,
        name:'Keep Track',
        desc: 'Keep track of the books you are reading.'
    },


]



