import React, {useState} from "react";
import { useNavigate} from 'react-router-dom';
import {collection, DocumentSnapshot, getDocs, QuerySnapshot} from 'firebase/firestore';
import {db, auth_} from './../../firebase';
import { FirebaseError } from "firebase/app";
import FlatList from 'flatlist-react';
import { useEffect } from "react";
import Card from './../../components/Card';
import {getAuth} from "firebase/auth";


import { faBook, faMagnifyingGlass, faPencil, faPeopleGroup } from '@fortawesome/free-solid-svg-icons';
import ListCard from "../../components/ListView/ListCard";



const CurrentlyReadingShelf = () =>{
    const navigate = useNavigate();
    const documentSnapshot = new DocumentSnapshot();
    const user = auth_.currentUser;


    const [Books, showCurrentlyReadBooks] = useState([]);


const getCurrentlyReadBooks = async() =>{

    await getDocs(collection(db, "Users", user.uid, "Currently Reading")).then((querySnapshot)=>{
            const book = querySnapshot.docs.map((doc)=>({
                ...doc.data()
            }));
                showCurrentlyReadBooks(book);
        
        });   


    }

    useEffect(()=>{
        getCurrentlyReadBooks();
    },[])
      
        
    return (
        <main>
            <div>
                <h1>Currently Reading</h1>
                {/* <div className="card-container">
                    {
                        Books?.map((book,idx)=>(
                            <div className="card">
                                <br/>
                                <img src={book.bookImage} height={100} width={60}/>
                                <h2 key={idx}>{book.bookTitle}</h2>
                            </div>
                        ))   
                    }
                </div> */}

            {/* <div className="card-container"> */}
                    {
                        Books?.map((book,idx)=>(
                            // <div className="card">
                                // <br/>
                                // <img src={book.bookImage} className="img-book"/>
                                <div>
                                <ListCard  image={book.bookImage}
                                title= {book.bookTitle}/>
                                </div>
                                // <h2 key={idx}>{book.bookTitle}</h2>
                            // </div>
                        ))   
                    }
                {/* </div>  */}
            </div>
        </main>
    );
};

export default CurrentlyReadingShelf;