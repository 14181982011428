import React from 'react';

const Popup = props =>{
    return (
        <div className='popup-box'>
            <div className='box'>
                {/* <button className='popup-btn-close' onClick={props.handleClose}>x</button> */}
                {props.content}
                <button onClick={props.handleClose}>Close</button>
            </div>
        </div>
    )

}

export default Popup;