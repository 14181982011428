import React, {useState} from 'react';
import { signInWithEmailAndPassword, signInWithPopup, signInWithRedirect, getRedirectResult} from 'firebase/auth';
import { auth_, googleProvider, oAuthProvider,db } from '../firebase';
import { NavLink, useNavigate} from 'react-router-dom';
import { useEffect } from "react";
import {Link } from "react-router-dom";

import {collection, DocumentSnapshot, getDocs, getDoc, setDoc, doc, deleteDoc, getCountFromServer, getFirestore} from 'firebase/firestore';

// Import the functions you need from the SDKs you need

import { initializeApp } from "firebase/app";

import { getAnalytics } from "firebase/analytics";

import {initializeAppCheck, ReCaptchaEnterpriseProvider, ReCaptchaV3Provider} from "firebase/app-check"
import Popup from '../components/Popup/Popup';
import { scryRenderedComponentsWithType } from 'react-dom/test-utils';
import { faTry } from '@fortawesome/free-solid-svg-icons';

const firebaseConfig = {
  apiKey: "AIzaSyBRBD3CnTCZUrc6Qc4WX4PfBKk_9KVHdrw",
  authDomain: "entrepreneurshipbooksapp.com",
  projectId: "entrepreneurship-book-club-app",
  storageBucket: "entrepreneurship-book-club-app.appspot.com",
  messagingSenderId: "235508464978",
  appId: "1:235508464978:web:b0d6f058d402ae841d8e8b",
  measurementId: "G-EZC9MQHSJ9"
};

const app = initializeApp(firebaseConfig);



if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
    window.FIREBASE_APPCHECK_DEBUG_TOKEN = '3eb1dfa4-f6e2-4afb-a34f-02cb61e18df2';
  }


// const appCheck = initializeAppCheck(app,{
//     provider: new ReCaptchaEnterpriseProvider('6LcYaz4pAAAAALIzUOYTWzRUe4x4NMGg7JpbYneZ'),
//     // isTokenAutoRefreshEnabled: true,
//     });


initializeAppCheck(app,{
    provider: new ReCaptchaV3Provider('6LcNp4EpAAAAAHfyF3rLUH5Uzvmc0X9QYk2LPNnb'),

    // isTokenAutoRefreshEnabled: true,
    });

const Login = () =>{
    const signInWithGoogleRedirect = async () => {
        await signInWithRedirect(auth_, googleProvider);
        // navigate('/discover');
    };

    const navigate = useNavigate();
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [isOpen, setIsOpen] = useState(false);
    const [isOpenErrorMessage, setIsOpenErrorMessage] = useState(false);

    const [type, setType] = useState("password");
    const [checked, setChecked]  = useState(false);




    // empty array means run this function once when mount
    useEffect( () =>{

        
        
        const redirectToLogin = async () =>{
            const response = await getRedirectResult(auth_);
            let date = new Date();
            date = date.toString();
            // console.log(response); 
            if(response){

                // response.user.uid

                const checkUser = await getDoc(doc(db, "Users", response.user.uid));

                if(checkUser.exists()){
                    console.log("check user");
                    successNotification();
                    navigate('/discover');


                }else{
                    // console.log("response");
                    // console.log(response.user.uid);
                    setDoc(doc(db,"Users",response.user.uid),{
                        accountCreated: date,
                        name: response.user.displayName,
                        displayName: response.user.displayName.replace(/ /g,"").toLowerCase(),
                        email: response.user.email,
                        registration_method: "Google Button",
                        website: "used_web_version_not_app",
                        uid: response.user.uid,
                        
                    });
                    successNotification();
                    navigate('/getstarted_basic_info');
                }

                

            }
            
        }
        redirectToLogin();

        },[])

        // Initialize Firebase

        const successNotification = () =>{
            // console.log("success message");
            setIsOpen(!isOpen);   
        }
        
        const warningNotification = () =>{
            // console.log("warning message");
            setIsOpenErrorMessage(!isOpenErrorMessage);   
        }
    const analytics = getAnalytics(app);

    const handleViewPasswordToggle = () =>{
        setChecked(!checked);

        if(type === "password"){
            setType("text");
        }else{
            setType("password");
        }
    }

    const onLogin = async(e) =>{
        await window.RecaptchaVerifier;
        e.preventDefault();
        signInWithEmailAndPassword(auth_, email, password).then((userCredential)=>{
            // signed in
            const user = userCredential.user;
            navigate('/discover');
            console.log(user);
            successNotification();
        }).catch((error)=>{
            const errorCode = error.code;
            const errorMessage = error.message;
            console.log(errorCode, errorMessage);
            warningNotification();
            

        });

    }

    const loginWithApple = async()=>{
        try {
            console.log("A");
            const appleProvider = oAuthProvider;
            console.log("B");
            console.log(appleProvider);
            const result = await signInWithPopup(auth_, appleProvider);
            console.log(result);
            console.log("C");
            const user = result.user;
            console.log("D");
            console.log(user);
            
        } catch (error){
            console.log(error);
            
        }

    }



    // const signInWithGoogle = async() =>{
    //     try{
    //         await window.RecaptchaVerifier;
            
    //         await signInWithPopup(auth, googleProvider).then((userCredential)=>{
    //             // signed in
    //             const user = userCredential.user;
    //             navigate('/discover');
           
    //         }).catch((error)=>{
    //             console.log("login error 1");
    //             const errorCode = error.code;
    //             const errorMessage = error.message;
    //             // console.log(errorCode, errorMessage);
    //             warningNotification();
    
    //         });
    //     }
    //     catch(error){
    //         // console.log("login error 1");
    //         console.log(error)
    //     }

    // }


    const signInWithGoogle = () =>{
        try{

            // await window.RecaptchaVerifier;
            // const auth = getAuth();
            // const {user} = await signInWithRedirect(auth, googleProvider);
            // console.log(user);

            // await getRedirectResult(auth, googleProvider)
            //     .then((result) => {
            //         // console.log(result);
            //         // This gives you a Google Access Token. You can use it to access Google APIs.
            //         // const credential = GoogleAuthProvider.credentialFromResult(result);
            //         // const token = credential.accessToken;

            //         // The signed-in user info.
            //         const user = result.user;
            //         // console.log("redirect user: "+ user.uid)
            //         navigate('/discover');
            //         successNotification();
            //         // IdP data available using getAdditionalUserInfo(result)
            //         // ...
            //     }).catch((error) => {
            //         // Handle Errors here.
            //         console.log("issue");
            //         const errorCode = error.code;
            //         const errorMessage = error.message;
            //         // The email of the user's account used.
            //         const email = error.customData.email;
            //         // The AuthCredential type that was used.
            //         const credential = GoogleAuthProvider.credentialFromError(error);
            //         warningNotification();
            //         // ...
            //     });
       
        }
        catch(error){
            // console.log("login error 1");
            console.log(error)
        }

    }    


    return (
        <main>
            <section>
                <div>

                {/* if popup is open dont open */}
                {isOpen && 
                    <div>
                    <Popup
                    handleClose = {successNotification}
                    content = {
                    <div>
                        <h2 className="success-popup">Congratulations!</h2>
                        <p>Your login was successful!</p>
                    </div>
                    }
                />
                </div>}


                {/* if popup is open dont open */}
                {isOpenErrorMessage && 
                    <div>
                    <Popup
                    handleClose = {warningNotification}
                    content = {
                    <div>
                        <h2 className="error-popup">Errors!</h2>
                        <p>Your login was not successful. Please check your login credentials!</p>
                    </div>
                    }
                />
                </div>}
                    <h1 className='text-10xl underline'>Login</h1>
                    <form onSubmit={onLogin}>
                        <div>

                            {/* <label htmlFor='email-address'>
                                Email
                            </label> */}
                            <input
                                class= "input_login"
                                id = "email-address"
                                name = "email"
                                type = "email"
                                required
                                placeholder='Email'
                                onChange={(e)=>setEmail(e.target.value)}
                            />
                        </div>
                        <div >
                            {/* <label htmlFor='password'>
                                Password
                            </label> */}
                            <input
                                class= "input_login"
                                id = "password"
                                name = "password"
                                type = {type}
                                required
                                placeholder='Password'
                                onChange={(e)=>setPassword(e.target.value)}
                            />
                            <div className='show-out'>
                            <input
                                id="x"
                                type = "checkbox"
                                checked = {checked}
                                onChange = {handleViewPasswordToggle}
                            />
                            <label for="x" className='show-password'>Show Password</label>
                            </div>
                         </div>
                        <p>By continuing, you agree to Entrepreneuship Books App <Link to="/termsofuse">Terms of Use</Link> and <Link to="/privacypolicy">Privacy Notice</Link>.</p>
                        <div>
                            <button class="button_login" type="submit" >
                                Login
                            </button>
                        </div>                       
                    </form>
                    <div>
                            <button class="button_google" onClick ={signInWithGoogleRedirect}>
                                Sign in With Google
                            </button>
                    </div> 
                    {/* <div>
                            <button class="button_google" onClick ={loginWithApple}>
                                Sign in With Apple
                            </button>
                    </div>  */}
                    
                    <p className="text-sm text-white text-center">
                        New Here? {' '}
                        <NavLink to="/register">
                            Sign Up
                        </NavLink>
                    </p>
                </div>
            </section>
        </main>


    );
};

export default Login;