import React from "react";
import {HomeGeneralContainer,
    HomeGeneralP} from "./HomeElements";


const HomeGeneral = () => {
    return (
        <HomeGeneralContainer>
        
            {/* <HomeGeneralP>The Entrepreneurship Book app is more than just a social media platform; it's a dedicated community where business leaders and aspiring entrepreneurs converge to discover the best books for professional and financial growth. Beyond merely rating and tracking books, our members actively recommend and engage in enriching discussions.</HomeGeneralP> */}
            <HomeGeneralP>The Entrepreneurship Book app is a dedicated community platform where business leaders and aspiring entrepreneurs converge to discover educational resources for professional and financial growth. Beyond merely rating and tracking books, and other educational resources, our members actively recommend and engage in enriching discussions.</HomeGeneralP>
  
        </HomeGeneralContainer>
    );
}

export default HomeGeneral;