import React, {useEffect, useState} from 'react';
import {auth_, db} from "../../firebase"
import { addDoc, doc, setDoc, getDoc, collection, serverTimestamp } from 'firebase/firestore';
import {v4 as uuidv4} from 'uuid';


const SendMessage = ({scroll, person}) =>{
    const [message, setMessage] = useState("");
    const inboxMessageUniqueID = uuidv4();

    // console.log("person");
    // console.log(person);
    
    const {uid,displayName, photoURL} = auth_.currentUser;

    const [reader, showUserInfo] = useState([]);


const getUser = async() =>{
   
    const userInfo = await getDoc(doc(db, "Users", person?.toString().trim()));
   
    showUserInfo(userInfo.data());
 
}

    const checkSender_and_Recipient = async()=>{
        const messageStarted = await getDoc(doc(db, "Users", uid, "Inbox", person?.toString().trim()));
        const messageStartedReader = await getDoc(doc(db, "Users", person?.toString().trim(), "Inbox", uid));
        

        if(!messageStarted.data()){
            await setDoc(doc(db, "Users", uid, "Inbox", person?.toString().trim()),{
                name: reader?.displayName,
                avatar: photoURL,
                uid: person
            });
            return;
        }

        if(!messageStartedReader.data()){
            await setDoc(doc(db, "Users", person?.toString().trim(), "Inbox", uid),{
                name: displayName,
                avatar: photoURL,
                uid
            });
            return;
        }
    }

    const sendMessage = async(event)=>{
        event.preventDefault();

        if(message.trim()===""){
            alert("Enter valid message!");
            return;
        }

        checkSender_and_Recipient();

        // console.log(uid);
        // console.log(displayName);
        // console.log(photoURL);
        // console.log(message);

        try{
        

        await addDoc(collection(db, "Users", uid, "Inbox", person?.toString().trim(), "messages"),{
            text: message,
            name: displayName,
            avatar: photoURL,
            isRead: false,
            createdAt: serverTimestamp(),
            uid
        });


        await addDoc(collection(db, "Users", person?.toString().trim(), "Inbox", uid, "messages"),{
            text: message,
            name: displayName,
            avatar: photoURL,
            isRead: false,
            createdAt: serverTimestamp(),
            uid
        });

        setDoc(doc(db,"mail", uid + inboxMessageUniqueID+person?.toString().trim()),{
            to: reader.email,
            message: {
                subject: "You have a new message in your inbox!",
                html:  displayName + " just sent you a message in your inbox! <code><br/><button><a href='https://entrepreneurshipbooksapp.com/inbox'>View</a></button></code>",

            }
        });


    }catch(e){
        console.log("error");
        console.log(e);
    }
        setMessage("");
        scroll.current.scrollIntoView({behavior: "smooth"});
    }

    useEffect(()=>{
        getUser();
    },[]);

  

    return(
        <form className='send-message' onSubmit={(e)=>sendMessage(e)}>
            <label htmlFor='messageInput' hidden>
                Enter Message
            </label>
            <input
            id='messageInput'
            name="messageInput"
            type="text"
            className='form-input__input'
            placeholder='type message...'
            value={message}
            onChange={(e)=>setMessage(e.target.value)}
            />
            <button type="submit">Send</button>

        </form>

    );
};

export default SendMessage;
