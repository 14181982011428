import React from 'react';
import apple from './Images/apple.png';
import android from './Images/google.png';

import {Box, Container, Row, Column, FooterLink, Heading} from './FooterElements';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInstagram } from '@fortawesome/free-brands-svg-icons';

const Footer = () =>{
    // const instagram = <FontAwesomeIcon icon={faInstagram}/>
    return(
        <Box>
            <h2>© Entrepreneurship Books App 2024</h2>
            <p>Connect With Us</p>
            <a href="https://www.instagram.com/entrepreneurshipbooksapp/">
                <FontAwesomeIcon icon={faInstagram} size ="xl"/></a>
            <Row>
                <Column>
                    {/* <FooterLink href = "#">About</FooterLink> */}
                    {/* <FooterLink href = "/getpremium">Get Premium</FooterLink> */}
                    {/* <FooterLink href = "/contactus">Contact Us</FooterLink> */}
                    <FooterLink href = "/blogs">Blogs</FooterLink>
                    <p class="p_footer">Download:</p>
                    <a href="https://apps.apple.com/us/app/entrepreneurship-books/id1631155648"><img class="download_img_button"src={apple} alt="download for apple"/></a>
                    <a href="https://play.google.com/store/apps/details?id=com.entrep_bookclub_app.bookclub"><img class="download_img_button" src={android} alt="download for android"/></a>
                </Column>
                <Column>
                    <FooterLink href = "/termsofuse">Terms of Use</FooterLink>
                    <FooterLink href = "/privacypolicy">Privacy Policy</FooterLink>
                </Column>
            </Row>

        </Box>
    );
};


export default Footer;