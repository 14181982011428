import styled from "styled-components";

export const HomeGeneralContainer = styled.div`
    // min-height: 100vh;
    padding: 5rem calc((100vw-1300px)/2);
    background: #b0bec5;
    
`;

export const HomeGeneralP = styled.p`
    // font-size: 1.5rem;
    margin: 2rem;
    color: #000000; 
`;

