import { getNextKeyDef } from "@testing-library/user-event/dist/keyboard/getNextKeyDef";
import React, {useState,useEffect, useRef} from "react";
import { useNavigate} from 'react-router-dom';
import {Nav,NavLink, NavMenu, Hamburger} from "./NavbarElements";
import {Link } from "react-router-dom";
import { auth_, db, fetchNotifications } from "../../firebase";
import {getAuth, onAuthStateChanged, signOut} from "firebase/auth";
import {collection, setDoc, doc, deleteDoc, getFirestore, getDoc, DocumentSnapshot, getDocs, QuerySnapshot} from 'firebase/firestore';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClock, faHouse, faBoltLightning, faInstagram, faChess, faArrowTrendUp, faDollarSign, faFire, faSchool, faChalkboardTeacher, faStore, faPenToSquare, faPeopleCarryBox, faSearch, faBook, faBookOpen, faBookOpenReader, faSwatchbook, faBars, faBell, faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { connectStorageEmulator } from "firebase/storage";


const LoggedInNavbar = () => {

    const user = auth_.currentUser;

    const [openCommunityNav, setCommunityNavOpen] = React.useState(false);
    const [openProfileNav, setProfileNavOpen] = React.useState(false);
    const navigate = useNavigate();

    const [isOpen, setIsOpen] = useState(false);
    let [notifications, setNotifications] = useState([]);
    let [messages, setMessages] = useState([]);


    const menuRef = useRef(null);
    const communityMenuRef = useRef(null);
    const profileMenuRef = useRef(null);

    const buttonRef = useRef(null);


    const toggleMenu = () =>{
        console.log(true);
        setIsOpen((open)=>!open)

    }

    const handleCommunityNavOpen =() =>{
        setCommunityNavOpen(!openCommunityNav);
    };

    const handleProfileNavOpen =() =>{
        setProfileNavOpen(!openProfileNav);
    };


    const logout = async()=>{
        try{
            
            await signOut(auth_);
            console.log("signing out now");
            navigate('/');
            window.location.reload();
        }catch(err){

            console.error(err);
        }
    }

    // const notifications = fetchNotifications();


    const fetchNotifications = async () =>{
        await getDocs(collection(db, "Users", user.uid, "Notifications")).then((querySnapshot)=>{
          let messages = querySnapshot.docs.map((doc)=>({
              ...doc.data()
          }));
            setNotifications(messages); 

    });  
}

const fetchMessages = async () =>{
    await getDocs(collection(db, "Users", user.uid, "Inbox")).then((querySnapshot)=>{
    let messages = querySnapshot.docs.map((doc)=>({
                  ...doc.data()
              }));

    // console.log(messages);
    setMessages(messages);
        });  
        }

const something = ()=>{
    navigate(
        "/notifications",{
            state: {
                notificationsRead: isNotificationRead?.length
            }
        }
    )
}


const inbox = ()=>{
    navigate(
        "/inbox"
    )
}

const handleOutsideClick = (event) =>{
    if(menuRef.current && 
        !menuRef.current.contains(event.target) &&
        event.target !== buttonRef.current){
            setIsOpen(false);

    }

    if(communityMenuRef.current && 
        !communityMenuRef.current.contains(event.target) &&
        event.target !== buttonRef.current){
            setCommunityNavOpen(false);

    }


    if(profileMenuRef.current && 
        !profileMenuRef.current.contains(event.target) &&
        event.target !== buttonRef.current){
            setProfileNavOpen(false);

    }

    
  
}


useEffect(()=>{
    fetchNotifications();
    fetchMessages();

    document.body.addEventListener('click', handleOutsideClick);
    return () => document.body.removeEventListener('click', handleOutsideClick);


},[]);
    // console.log("yo");
    // console.log(notifications);

    let isNotificationRead = "";
    let isMessageRead = "";

    if(notifications){
        isNotificationRead = notifications?.filter((item)=> item.isRead===false).
        map((notif)=>notif.isRead);
    }

    if(messages){
        isMessageRead = messages?.
        map((message)=>message.name);
    }
    return (
    
        <nav className="nav">
            <ul className="nav-menu">
                <div className="nav-force-row">
                <li>
                    <Link to="/">EntreprenershipBooksApp</Link>
                </li>
                {/* <li>
                    <Link to="/">{isNotificationRead?.length}</Link>
                </li> */}
            
                <li className="dont_switch">
                    {isNotificationRead?.length? <a className="active-notificaitions" onClick={something}><FontAwesomeIcon icon={faBell}/>{isNotificationRead?.length}</a>:
                <Link to="/notifications"><FontAwesomeIcon icon={faBell}/></Link>}
                </li>
                
                <li className="dont_switch">
                {isMessageRead?.length? <Link className="active-notificaitions" to="/inbox"><FontAwesomeIcon icon={faEnvelope}/>{isMessageRead?.length}</Link>:
                <Link to="/inbox"><FontAwesomeIcon icon={faEnvelope}/></Link>}
                </li>

                </div>
                
                <li  ref ={menuRef} className="nav-menu-hamburger" onClick ={toggleMenu}>Menu <FontAwesomeIcon icon={faBars}/></li>
                <ul className={`nav-menu-items fullscreen`}>
             
                <li >
                    <Link to="/discover">Home</Link>
                </li>
                <li>
                    <Link to="/mybooks">My Books</Link>
                </li>   
                <li onClick={handleCommunityNavOpen}>
                    <Link ref ={communityMenuRef}>Community
                    {openCommunityNav? (
                        <div className="dropdown">
                            <ul className="menu">
                                <li className="menu-item">
                                    <Link to="/bookclubs">Book Clubs</Link>
                                </li>
                                <li className="menu-item">
                                    <Link to="/discussionboards">Discussion Forums</Link>
                                </li>
                            </ul>
                        </div>)
                    : ""}</Link>

                </li >   
                <li onClick={handleProfileNavOpen} >
                    <Link ref ={profileMenuRef}>Profile
                    {openProfileNav? (
                        <div className="dropdown">
                            <ul  className="menu">
                                <li  className="menu-item">
                                    <Link to="/profile">Your Profile</Link>
                                </li>
                                <li className="menu-item">
                                    <Link to="/settings">Account Settings</Link>
                                </li>
                                <li className= "li-nav">
                                    <Link to="/ebooks_unleashed">Ebooks Unleashed!</Link>
                                </li >
                                <li className="menu-item">
                                    <Link to="/blogs">Blogs/Articles</Link>
                                </li>
                                <li className="menu-item">
                                    <Link to="/categories">Book Categories</Link>
                                </li>
                                <li className="menu-item">
                                    <Link to="/bookrecs">Book Recommendations</Link>
                                </li>
                                <li className="menu-item">
                                    <Link to="/search_books">Search Books</Link>
                                </li>
                                <li className="menu-item">
                                    <Link to="/readers">Find Readers</Link>
                                </li>
                                <li className="menu-item">
                                    <Link to="/reading_challenge">Reading Challenge</Link>
                                </li>
                                <li className="menu-item">
                                    <Link to="/bookclubs">Book Clubs</Link>
                                </li>
                                <li className="menu-item">
                                    <Link to="/discussionboards"> Discussion Boards</Link>
                                </li>
                                <li className="menu-item">
                                    <Link onClick={logout}>Sign Out</Link>
                                </li>
                            </ul>
                        </div>)
                    : ""}</Link>
                </li > 
            
                </ul> 

                {/*---------------------------  */}

                <ul className={`nav-menu-items hamburger ${isOpen? "is-open":""}`}> 
                <li className= "li-nav">
                    <Link to="/discover">Home</Link>
                </li>
                <li className= "li-nav">
                    <Link to="/mybooks">My Books</Link>
                </li>
                <li className= "li-nav">
                    <Link to="/ebooks_unleashed">Ebooks Unleashed!</Link>
                </li >             
                <li className= "li-nav">
                    <Link to="/bookclubs">Book Clubs</Link>
                </li >
                <li className= "li-nav">
                    <Link to="/discussionboards">Discussion Forums</Link>
                </li>
                <li className= "li-nav">
                    <Link to="/profile">Your Profile</Link>
                </li>
                <li className= "li-nav">
                    <Link to="/settings">Account Settings</Link>
                </li>
                <li className="menu-item">
                    <Link to="/blogs">Blogs/Articles</Link>
                </li>
                <li className= "li-nav">
                    <Link to="/categories">Book Categories</Link>
                </li>
                <li className= "li-nav">
                    <Link to="/bookrecs">Book Recommendations</Link>
                </li>
                <li className= "li-nav">
                    <Link to="/search_books">Search Books</Link>
                </li>
                <li className= "li-nav">
                    <Link to="/readers">Find Readers</Link>
                </li>
                <li className= "li-nav">
                    <Link to="/reading_challenge">Reading Challenge</Link>
                </li>
                <li className= "li-nav">
                    <Link to="/discussionboards"> Discussion Boards</Link>
                </li>
                <li className= "li-nav">
                    <Link onClick={logout}>Sign Out</Link>
                </li>
                      
                </ul> 
            </ul>
        </nav>

    );
};


export default LoggedInNavbar;