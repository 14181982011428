import React from 'react';

const Cancel = () =>{
    return(
        <div>
            <h3>Something went wrong</h3>
            <a href='/'>Go To Homepage</a>
        </div>
    );

}

export default Cancel;