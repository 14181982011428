import styled from 'styled-components';
import home from '../../images/Home.png';



export const HeroContainer = styled.div`
    // background-color: #b0bec5;
    background: linear-gradient(to right, rgba(0,0,0,0.7), rgba(0,0,0,0.1)),
    url(${home});
    height: 100vh;
    background-position: center;
    background-size; cover;
    display: flex; 
    // width: 100vw;
    margin: 0 auto;

    @media screen and (max-width: 780px){
        // width: 100vw;
        // background-repeat: repeat;
        background-size: cover;
    }
 
`;

export const HeroContent = styled.div`
    height: calc(100vh-80px);
    max-height: 100%;
    padding: 0rem calc((100vw-1300px)/2);
`;

export const HeroItems = styled.div`
    // display: flex;
    // flex-diretion: column;
    justify-content: center;
    align-items: flex-start;
    height: 100vh;
    padding: 2rem;
    max-height: 650px;
    max-width: 100%;
    color: #ffffff;
    text-transform: uppercase;
    line-height: 1;
    font-weight: bold;
    @media screen and (max-width: 780px){
        // width: 100%;
    }
`;

export const HeroH1 = styled.h1`
    font-size: clamp(2.5rem, 10vw, 5rem);
    margin-bottom: 1rem;
    box-shadow: 3px 5px #ffffff;
    letter-spacing: 3px;
`;


export const HeroP = styled.p`
    font-size: clamp(2.5rem, 2vw, 3rem);
    margin-bottom: 2rem;
    // flex-wrap: wrap;
    padding: 2rem;
    margin: 0 auto;
    @media screen and (max-width: 780px){
        // width: 100%;
        font-size: clamp(1.5rem, 1vw, 1.5rem);
    }
`;


export const HeroButton = styled.button`
    font-size: 1.4rem;
    padding: 1rem;
    border: none;
    flex-wrap: nowrap;
    background: #607d8b;
    color: #ffffff;
    transition: 0.2s ease-out;
    margin-bottom: 2rem;
    border-radius: 5px;

    @media screen and (max-width: 780px){
        // width: 100%;
        font-size: 1 rem;

    }
    

    &:hover{
        background: #b0bec5;
        transition: 0.2s ease-out;
        cursor: pointer;
        color: #000000;
    }
`;