import React, {useState} from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBookOpen } from '@fortawesome/free-solid-svg-icons';
import { useNavigate} from 'react-router-dom';
import {collection, DocumentSnapshot, getDocs, QuerySnapshot} from 'firebase/firestore';
import {db} from './../../firebase';
import { useEffect } from "react";




const BookClubHomeScreen = () =>{
    const navigate = useNavigate();
    const documentSnapshot = new DocumentSnapshot();

    const [BookClubs, showBookClubs] = useState([]);

    const getBookClubs = async() =>{
        await getDocs(collection(db, "BookClubs")).then((querySnapshot)=>{
            const bookclubs = querySnapshot.docs.map((doc)=>({
                ...doc?.data(), id:doc?.id, name: doc?.displayName
            }));

            if(bookclubs.name!==""){
                showBookClubs(bookclubs);
            }
            
            

        });   


    }
    

    const onCreateNewBookClubButton = ()=>{
        navigate('/createdbookclub');
    }

    const onViewBookClubButton = (club_name)=>{
        navigate('/bookclub/'+club_name,{
            state: {
                id: club_name
            }});
    }


    

    useEffect(()=>{
        getBookClubs();
    },[])


    return (
        <div>
            
            <button onClick={()=>onCreateNewBookClubButton()}>Create A Book Club +</button>

            <div className="card-container">
                    {
                        BookClubs?.map((club,idx)=>(
                            <div className="card">
                            <p><FontAwesomeIcon icon={faBookOpen} size ="2xl"/></p>
                            <h2 key={idx}>{club?.bookClubName}</h2>
                            <button onClick={()=>onViewBookClubButton(club?.bookClubName)}>View</button>
                            </div>
                        ))
                    }
                    </div>

        </div>

    );
};
export default BookClubHomeScreen;