import React, {useState, useRef} from 'react';
import {createUserWithEmailAndPassword, signInWithPopup, sendEmailVerification} from 'firebase/auth';
import { auth_,googleProvider } from '../firebase';
import { NavLink, useNavigate} from 'react-router-dom';
import Popup from '../components/Popup/Popup';
import {collection, DocumentSnapshot, getDocs, getDoc, setDocs,QuerySnapshot, addDoc, doc, setDoc, getFirestore} from 'firebase/firestore';
import {Link } from "react-router-dom";



const Register = () =>{
    const navigate = useNavigate();
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [passwordConfirm, setConfirmedPassword] = useState('');

    const [isOpen, setIsOpen] = useState(false);
    const [isOpenErrorMessage, setIsOpenErrorMessage] = useState(false);
    const [isEmptyMessage, setIsEmptyMessage] = useState(false);



    const emailRef = useRef();
    const passwordRef = useRef();
    const passwordConfirmRef = useRef();

    const [type, setType] = useState("password");
    const [checked, setChecked]  = useState(false);


    const successNotification = () =>{
        // console.log("success message");
        setIsOpen(!isOpen);   
    }

    const handleViewPasswordToggle = () =>{
        setChecked(!checked);

        if(type === "password"){
            setType("text");
        }else{
            setType("password");
        }
    }

    const warningNotification = () =>{
        // console.log("warning message");
        setIsOpenErrorMessage(!isOpenErrorMessage);   
    }


    const emptyNotification = () =>{
        // console.log("warning message");
        setIsEmptyMessage(!isEmptyMessage);   
    }


    
    


    const signInWithGoogle = async() =>{
        let date = new Date();
        date = date.toString();
        
        try{
           

            await window.RecaptchaVerifier;
            await signInWithPopup(auth_, googleProvider).then((userCredential)=>{
                // signed in
                const user = userCredential.user;
                const firestore = getFirestore();

                setDoc(doc(firestore,"Users",user.uid),{
                    accountCreated: date,
                    name: user.displayName,
                    displayName: user.displayName.replace(/ /g,'').toLowerCase(),
                    email: user.email,
                    registration_method: "Google Button",
                    website: "used_web_version_not_app",
                    uid: user.uid,
                    
                });
                successNotification();
                navigate('/getstarted_basic_info');
                // console.log("hi user");
                // console.log(user);
            }).catch((error)=>{
                const errorCode = error.code;
                const errorMessage = error.message;
                // console.log(errorCode, errorMessage);
    
            });
        }
        catch(error){
            console.log(error)
        }

    }

    const onSubmit = async (e) =>{
        e.preventDefault();
        const firestore = getFirestore();
        const date = new Date;
        console.log(e.target.password);
        if(e.target.password.value !== e.target.password2x.value){
            warningNotification();

        }else if(e.target.email.value.trim()===""){
            emptyNotification();


        }else if(e.target.password.value.trim()===""){
            emptyNotification();
        }else{

        // console.log(email);

        // const emailRegex = /^[^\s@]+]+$/;

        try{

            await createUserWithEmailAndPassword(auth_, email, password).then((userCredential)=>{
                // signed in
                const user = userCredential.user;
                // console.log("user");
                // console.log(user);
                // console.log(e.target.username.value);
                

                // user.sendEmailVerification();

                // user.sendEmailVerification().then(function(){
                //     console.log("")

                // }).catch(function(error){
                //     console.log(error.message);

                // });

                // await sendEmailVerification(user);

                setDoc(doc(firestore,"Users",user.uid),{
                    accountCreated: date,
                    // displayName: e.target.username.value,
                    email: email,
                    // firstName: e.target.firstName.value,
                    // lastName: e.target.lastName.value,
                    website: "used_web_version_not_app",
                    uid: user.uid,
                    
                });
                successNotification();
                navigate('/getstarted_basic_info');
                // navigate('/login');

            });
        }catch(error){
            const errorCode = error.code;
            const errorMessage = error.message;
            console.log(errorCode, errorMessage);

        };
    }

    }
    
    
    return (
        <main>
            <section>
                <div>

                {/* if popup is open dont open */}
                {isOpen && 
                    <div>
                    <Popup
                    handleClose = {successNotification}
                    content = {
                    <div>
                        <h2 className="success-popup">Verify your email!</h2>
                        <p>Verification email was sent. Please check your email to verify your account!</p>
                    </div>
                    }
                />
                </div>}

                {isOpenErrorMessage&&<Popup
                  handleClose = {warningNotification}
                content = {
                <div>
                    <h2 className="error-popup">Error</h2>
                    <p>Passwords do not match!</p>
                </div>
                }
            />}


            {isEmptyMessage&&<Popup
                  handleClose = {emptyNotification}
                content = {
                <div>
                    <h2 className="error-popup">Error</h2>
                    <p>Field can not be blank!!</p>
                </div>
                }
            />}
                    <h1>Register</h1>
                    <form onSubmit={onSubmit}>
                    {/* <div>
                        
                            <input
                                class = "input_register"
                                id = "first-name"
                                name = "firstName"
                                type = "text"
                                required
                                placeholder='First Name'
                                onChange={(e)=>setEmail(e.target.value)}
                            />
                        </div> */}
                        {/* <div>
                           
                            <input
                                class = "input_register"
                                id = "last-name"
                                name = "lastName"
                                type = "text"
                                required
                                placeholder='Last Name'
                                onChange={(e)=>setEmail(e.target.value)}
                            />
                        </div> */}
                        {/* <div>
                    
                            <input
                                class = "input_register"
                                id = "username"
                                name = "username"
                                type = "text"
                                required
                                placeholder='Username'
                                onChange={(e)=>setEmail(e.target.value)}
                            />
                        </div> */}
                        <div>
                        
                            <input
                                class = "input_register"
                                id = "email-address"
                                name = "email"
                                type = "email"
                                required
                                placeholder='Email Address'
                                onChange={(e)=>setEmail(e.target.value)}
                            />
                        </div>
                        <b>Please Note: Password must be atleast 6 characters long.</b>
                        <br/>
                        <b>Include uppercase letters and a mix of numbers and symbols.</b>                        
                        <div>

                            <input
                                class = "input_register"
                                id = "password"
                                name = "password"
                                type = {type}
                                required
                                placeholder='Password'
                                onChange={(e)=>setPassword(e.target.value)}
                            />
                        </div>
                        <div>
                        
                            <input
                                class = "input_register"
                                id = "repassword"
                                name = "password2x"
                                type = {type}
                                required
                                placeholder='Password (6 characters)'
                                onChange={(e)=>setConfirmedPassword(e.target.value)}
                            />
                        </div>
                        <div className='show-out'>
                        <input id ="x"
                                type = "checkbox"
                                checked = {checked}
                                onChange = {handleViewPasswordToggle}
                            />
                            <label for="x" className='show-password'>Show Password</label>
                        </div>
                        <p>By continuing, you agree to Entrepreneuship Books App <Link to="/termsofuse">Terms of Use</Link> and <Link to="/privacypolicy">Privacy Notice</Link>.</p>
                        <div>
                            <button type="submit" class="button_register">
                                Register
                            </button>
                        </div>                      
                    </form>
                    <div>
                            <button class="button_google" onClick ={() =>signInWithGoogle()}>
                                Register With Google
                            </button>
                        </div>   
                    <p className="text-sm text-white text-center">
                        Already have an account? {' '}
                        <NavLink to="/login">
                            Login
                        </NavLink>
                    </p>


                </div>
            </section>
        </main>

    );
};

export default Register;