import React, {useState} from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPeopleGroup } from '@fortawesome/free-solid-svg-icons';
import { useNavigate} from 'react-router-dom';
import {collection, DocumentSnapshot, getDocs, QuerySnapshot} from 'firebase/firestore';
import {db} from './../../firebase';
import { useEffect } from "react";




const DiscussionBoardsHomeScreen = () =>{
    const navigate = useNavigate();
    const documentSnapshot = new DocumentSnapshot();

    const [DiscussionBoards, showDiscussionBoards] = useState([]);

    const onCreateDashboardButton  = () =>{
        navigate('/creatediscussionboard');
    }

    
    
    const onViewButton  = (discussion_board_name) =>{
        navigate('/discussionboard/'+discussion_board_name.toLowerCase().replace(/ /g,""),{
            state: {
                id: discussion_board_name
            }
        });
    }

    const getDiscussionBoards = async() =>{
        await getDocs(collection(db, "DiscussionBoards")).then((querySnapshot)=>{
            const discussionboards = querySnapshot.docs.map((doc)=>({
                ...doc.data(), id:doc.id, name: doc.displayName
            }));

            if(discussionboards.name!==""){
                showDiscussionBoards(discussionboards);
            }
            
            

        });   


    }

    useEffect(()=>{
        getDiscussionBoards();
    },[])


    return (
        <div>
            
            <button onClick={onCreateDashboardButton}>Create A Discussion Board +</button>

            <div className="card-container">
                    {
                        DiscussionBoards?.map((boards,idx)=>(
                            <div onClick={()=>onViewButton(boards?.discussionBoardName)} className="card">
                            <p><FontAwesomeIcon icon={faPeopleGroup} size ="2xl"/></p>
                            <h2 key={idx}>{boards?.discussionBoardName}</h2>
                            <button onClick={()=>onViewButton(boards?.discussionBoardName)}>View</button>
                            </div>
                        ))
                    }
                    </div>

        </div>

    );
};
export default DiscussionBoardsHomeScreen;