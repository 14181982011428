import React from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faInstagram } from '@fortawesome/free-brands-svg-icons';
import Hero from './../components/Hero';
import HomeGeneral from './../components/Home';
import Features from './../components/Features';
import {feats} from './../components/Features/featureInfo';
import Footer from './../components/FooterBar';
import {HomeGeneralContainer,
    HomeGeneralP} from "./../components/Home/HomeElements";


import one from './../images/1-removebg.png';
import two from './../images/2-removebg.png';
import three from './../images/3-removebg.png';
import four from './../images/4-removebg.png';

// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { faClock, faHouse, faBoltLightning, faInstagram, faChess, faArrowTrendUp, faDollarSign, faFire, faSchool, faChalkboardTeacher, faStore, faPenToSquare, faPeopleCarryBox, faSearch, faBook, faBookOpen, faBookOpenReader, faSwatchbook } from '@fortawesome/free-solid-svg-icons';
import {Helmet} from "react-helmet";

const Home = () =>{
    return (
        <main>
            {/* <section> */}
            <Helmet>
                <title>The Entrepreneurship Books App</title>
                <meta name="description" content="Dynamic platform where book enthusiasts and aspiring entrepreneurs converge to discover, connect, and grow. Dive into a vast library of books spanning various genres, while accessing valuable advice and insights from experienced professionals across entrepreneurship and beyond. Join our thriving community to explore new horizons, forge meaningful connections, and embark on a journey of personal and professional development unlike any other."/>
                <meta name="keywords" content="book lover, entreprenuership, mentorship, networking, personal growth, professional development, advice platform, business books, career guidance, community engagement, expert insights, learning resources, skill-building, networking platform, online mentorship"/>
                <meta property="og:title" content="The Entrepreneurship Books App"/>
                <meta property="og:description" content="Welcome to our dynamic platform where book enthusiasts and aspiring entrepreneurs converge to discover, connect, and grow. Dive into a vast library of books spanning various genres, while accessing valuable advice and insights from experienced professionals across entrepreneurship and beyond. Join our thriving community to explore new horizons, forge meaningful connections, and embark on a journey of personal and professional development unlike any other."/>
                <meta property="og:url" content="https://www.entrepreneurshipbooksapp.com/"/>
            </Helmet>
                <Hero/>
                <HomeGeneral/>
                {/* <Features heading="Features" data={feats}></Features> */}
                 <h2>Features</h2>
                <div className="category-div">
                    {/* <div className="features"> */}
                        <FontAwesomeIcon icon={faClock} size ="2xl"/>
                        <br/>
                        <b>Reading Challenges</b>
                        <p>Motivate yourself to read more. Set goals to read more books to help you grow professionally. Be more knowledgable and successful</p>
                    {/* </div> */}
                    
                   {/* <div className="features"> */}
                        <FontAwesomeIcon icon={faPeopleCarryBox} size ="2xl"/>
                        <br/>
                        <b>Connect With Friends</b>
                        <p>Learn and grow with like-minded people all over the world.</p>

                    {/* </div>                     */}
                    {/* <div className="features"> */}
                        <FontAwesomeIcon icon={faSearch} size ="2xl"/>
                        <br/>
                        <b>Find Books and Get Recommendations</b>
                        <p>Search and get recommended books that help you grow personally and professionally.</p>

                    {/* </div> */}
      

                </div>
               <div>
                     <h2>Join Discussion Forums</h2>
                    <div className="card-container">
                        <div className="card-hero">
                            <h3>Real Estate</h3>
                            <FontAwesomeIcon icon={faHouse} size ="2xl"/>
                            <p>Whether you're a seasoned investor, a first-time homebuyer, or a curious enthusiast, this platform is your hub for all things related to real estate. Engage in lively conversations, share insights, seek advice, and stay updated on the latest trends and market news. From property investment strategies to home buying tips, renovation ideas to landlord-tenant issues, our forum is a vibrant community.</p>

                        </div>
                        <div className="card-hero">
                            <h3>Personal Finance</h3>        
                            <FontAwesomeIcon icon={faArrowTrendUp} size ="2xl"/>
                            <p>Whether you're striving for financial independence, planning for retirement, or aiming to manage your money better, this platform is your go-to resource. Engage in insightful discussions, share strategies, seek advice, and stay informed on the latest trends and news in personal finance. From budgeting tips to investment strategies, debt management to tax planning, our forum is a supportive community.</p>
                        </div>
                        <div className="card-hero">
                            <h3>Create your own</h3>
                            <FontAwesomeIcon icon={faPenToSquare} size ="2xl"/>

                        </div>
                    </div>
                </div>
                 <div>
                    <h2>Join Book Clubs</h2>
                    <div className="card-container">
                        <div className="card-hero">
                            <h3>Awesome Club</h3>
                            <FontAwesomeIcon icon={faBookOpen} size ="2xl"/>
                            <p>Meet dynamic community of innovators, dreamers, and doers dedicated to mastering the art and science of entrepreneurship. This book club is not just about reading; it's about igniting inspiration, fostering creativity, and sparking actionable insights to drive your business forward. Dive into engaging discussions, exchange ideas with fellow entrepreneurs, and tap into a wealth of knowledge and experience to fuel your entrepreneurial journey.</p>
                        </div>
                        <div className="card-hero">
                            <h3>Entrepreneurship Club</h3>
                            <FontAwesomeIcon icon={faBookOpen} size ="2xl"/>
                            <p>Dive into the world of business innovation, leadership, and success stories with fellow aspiring entrepreneurs and seasoned business owners. Select thought-provoking books that explores various aspects of entrepreneurship, from startup strategies to growth hacking, from leadership to marketing tactics. Engage in lively discussions, share insights, and exchange ideas as we dissect each book's key concepts and practical applications. </p>

                        </div>
                        <div className="card-hero">
                            <h3>Create your own</h3>
                            <FontAwesomeIcon icon={faSwatchbook} size ="2xl"/>

                        </div>
                    </div>
                </div>

                <h2>Motivate yourself to read more</h2>
                <p>Set goals to read more books to help you grow professionally. Be more knowledgable and successful. </p>
                <img className="img-hero" src={one}/>

               <h2>Find books to help you grow financially and professioally</h2>
                <p>Are you a novice entrepreneur? Are you a professional looking for books to help you get your next promotion?<br/>What about books to help you invest and get closer to financial freedom?<br/>Find what you are looking for here. </p>
                <img className="img-hero" src={four}/>

                <h2>Get personalized recommendations</h2>
                <p>Make our app your go to for business book recommendations</p>
                <img className="img-hero" src={two}/> 



{/* 00000000000000 */}
                {/* <div class="home">
                    <h1 class="public_screen">Book recommendation and tracking app for business-minded people.</h1>

                    <button></button>
                    <button></button>
                </div>
                <div class="home">
                    <h1 class="public_screen">The Entrepreneurship Book app is more than just a social media platform; it's a dedicated community where business leaders and aspiring entrepreneurs converge to discover the best books for professional and financial growth. Beyond merely rating and tracking books, our members actively recommend and engage in enriching discussions. Consider us the GoodReads tailored specifically for the business-minded. It's available for Android and Apple devices.</h1>

                    
                </div>
                <div class="home">
                    
                </div>
                <div class="home">
                    
                </div> */}
                {/* <Footer/> */}
            {/* </section> */}
        </main>

    );
};

export default Home;