import React, {useState,useEffect} from "react";
import {doc, setDoc, collection, DocumentSnapshot, getDocs, getCountFromServer, getFirestore, updateDoc} from 'firebase/firestore';
import {auth_, uploadProfilePic} from '../../firebase';
import {getAuth, onAuthStateChanged, signOut} from "firebase/auth";
import { FirebaseError } from "firebase/app";
import { useNavigate} from 'react-router-dom';
import Popup from "../../components/Popup/Popup";


const GettingStartedEnterExperienceScreen = () =>{

    const [profBackgroundText, saveProfBackgroundText] = useState("");
    const [companyDescriptionText, saveCompanyDescriptionText] = useState("");
    const [userIntentionDescriptionText, saveUserIntentionDescriptionText] = useState("");


    const [isErrorMessage, setErrorMessage] = useState(false);
    const [isSuccess, setSuccess] = useState(false);

    const [isEmpty, checkEmpty] = useState(false);

    const navigate = useNavigate();

    const onPrevButton  = () =>{
        navigate('/getstarted_experience');
    }

    const onNextButton  = () =>{
        navigate('/getstarted_profilepic');
    }

    const successNotification = () =>{
        setSuccess(!isSuccess);   
    }

    const errorNotification = () =>{
        setErrorMessage(!isErrorMessage);   
    }

    const emptyNotification = () =>{
        checkEmpty(!isEmpty);   
    }


    const [user, setUser] = useState("");
 
    const getUsers = async() =>{
        const auth = getAuth();
        onAuthStateChanged(auth, (user)=>{
            if(user){
                const userInfo = auth_.currentUser;
                // console.log("user:")
                // console.log(user.displayName);
                // const email =  user.email;
                // const displayName =  user.displayName;
                // const uid = user.uid;
                setUser(userInfo)
              
                
    
            }
        });
    }


    const professionalBackgroundResponses =(e)=>{
        e.preventDefault();
        const firestore = getFirestore();
        const date = new Date;

        if(profBackgroundText.trim()===""||
        companyDescriptionText.trim()===""||
        e.target.your_company_role.value.trim()===""||
        e.target.your_industry.value.trim()===""||
        e.target.years_in_company.value.trim()===""||
        userIntentionDescriptionText.trim()===""){
            
            
            emptyNotification();
            

        }else{

            try {

                updateDoc(doc(firestore,"Users",user.uid),{

                    experience:{
                    
                            professional_background: profBackgroundText,
                            company_desc: companyDescriptionText,
                            company_role: e.target.your_company_role.value,
                            company_years_in: e.target.years_in_company.value,
                            industry: e.target.your_industry.value
                    },

                    user_intentions: userIntentionDescriptionText

                        
                    }
                );

                successNotification();
                navigate('/getstarted_profilepic');
                
            } catch (error) {
                // console.log("prof_Experience");
                // console.log(error);
                errorNotification();

                
            }
        }

    }

 

    

    useEffect(()=>{
        getUsers();

        },[])

    return (
        <div>


            {/* if popup is open dont open */}
            {isSuccess && 
                    <div>
                    <Popup
                    handleClose = {successNotification}
                    content = {
                    <div>
                        <h2 className="success-popup">Congratulations!</h2>
                        <p>Your image was uploaded!</p>
                    </div>
                    }
                />
                </div>}


                {/* if popup is open dont open */}
                {isErrorMessage && 
                    <div>
                    <Popup
                    handleClose = {errorNotification}
                    content = {
                    <div>
                        <h2 className="error-popup">Error!</h2>
                        <p>There was an issue uploading your image. Try again!</p>
                    </div>
                    }
                />
                </div>}


            <h1>Getting Started!</h1>

            <h2>Tell Us About Your Experience</h2>
            <form onSubmit={professionalBackgroundResponses}>
            <div className="prof_bg">
            <div className='prof-edu-inputs'>
                <label  className="prof_bg_inputs">What is your professional background</label>
                <textarea
                    rows={5} 
                    cols={35}
                    type="text"
                    value={profBackgroundText}
                    onChange = {e => saveProfBackgroundText(e.target.value)}
                    className="prof_bg_inputs"></textarea>
            </div>
            <div className='prof-edu-inputs'>
                <label className="prof_bg_inputs">Your company</label>

            <textarea
                    rows={5} 
                    cols={35}
                    type="text"
                    value={companyDescriptionText}
                    onChange = {e => saveCompanyDescriptionText(e.target.value)}
                    className="prof_bg_inputs"></textarea>                
            </div>
            <div className='prof-edu-inputs'>
                <label className="prof_bg_inputs">Your role in that company</label>
                <input 
                name="your_company_role"
                type="text"           
                className="prof_bg_inputs"></input>
            </div>
            <div className='prof-edu-inputs'>
                <label className="prof_bg_inputs">How many years of ownership experience do you have</label>
                <input
                name="years_in_company"
                type="number" 
                className="prof_bg_inputs"></input>
            </div>
            <div className='prof-edu-inputs'>
                <label className="prof_bg_inputs">What industry are you in</label>
                <input 
                name="your_industry"
                type="text"
                className="prof_bg_inputs"></input>
            </div>
            <div className='prof-edu-inputs'>
                <label className="prof_bg_inputs">What do you hope to achieve or find in this app?</label>

            <textarea
                    rows={5} 
                    cols={35}
                    type="text"
                    value={userIntentionDescriptionText}
                    onChange = {e => saveUserIntentionDescriptionText(e.target.value)}
                    className="prof_bg_inputs"></textarea>                
            </div>
            </div>
            <button type="submit">Next</button>
            </form>


            <button onClick={onPrevButton}>Prev</button>
            {/* <button onClick={onNextButton}>Next</button> */}

        
        </div>

    );
};
export default GettingStartedEnterExperienceScreen;