import React, { useState, useEffect}  from 'react';
import {loadStripe} from '@stripe/stripe-js'

import ReactGA from 'react-ga';
import { useNavigate, useLocation, useParams, Link} from 'react-router-dom';

import { getAnalytics, logEvent } from 'firebase/analytics';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBook, faBookBookmark, faMagnifyingGlass, faPencil, faPeopleGroup, faThumbsUp } from '@fortawesome/free-solid-svg-icons';
import {doc, collection, addDoc, DocumentSnapshot, getDoc, getDocs, QuerySnapshot, getFirestore, setDoc} from 'firebase/firestore';
import {v4 as uuidv4} from 'uuid';
import {db, auth_} from './../../firebase';
import {Helmet} from "react-helmet";
import AdsComponent from './../../components/AdComponents';
import { Avatar,  Card } from "antd";

import ListCard from "./../../components/ListView/ListCard"


const EbooksUnleashed = ()=>{

    const {Meta} = Card;
    const analytics = getAnalytics();
    const firestore = getFirestore();
    const navigate = useNavigate();
    const documentSnapshot = new DocumentSnapshot();
    const user = auth_.currentUser;

    logEvent(analytics, "Ebooks Unleashed Lists Page");

    const [ebooks, showEbooks] = useState([]);

    const onEBookUnleashedDetailsButton  = (title, description, image, price, link, author, date, id) =>{

        navigate('/ebook_unleashed_detail/'+title,{
            state: {
                book_title: title,
                book_description: description,
                book_image: image,
                book_price: price,
                shop: link,
                book_author: author,
                book_published: date,
                book_category: "ebooks_unleashed",
                uid: id


            }
        });
    }

    const getEBookInfo= async() =>{

        await getDocs(collection(db, "Books")).then((querySnapshot)=>{
            const book = querySnapshot.docs.filter(doc => doc.data().ebooks_unleashed===true).map((doc)=>({
                ...doc.data()
            }));

            showEbooks(book);
        
            });
        }


    //     const loadCheckout = async(plan) =>{
    //         let planID = null;
    //         // if(plan == 4.99) planID = monthly;
    //         // else if(plan == 49.99) planID = annual;
        
    //         const firestore = getFirestore();
        
        
            
    //         // const stripe = await loadStripe("pk_test_51OfRMQBThrEa9Znh87bCpodFcGTeR5o2KucNcvMR0XWU43yo1KSHGD1kVOVJRXlkEJ8ltKWfLEHFPMn40apAKMKS00VUEoJOxT");

    //         // const stripe = await loadStripe("pk_live_51OfRMQBThrEa9Znh0WMfU46vJXeF9oltDSgM1WvoygzolcPiCCBQ5OvVv5R4b4FwPuo27gqoF0uzQ5GC8GYDdh0600R8TCYs8l");
        
    //         const newCollectionStripe =  collection(firestore, 'customers',user.uid,'checkout_sessions');
    //         const docRef = await addDoc(newCollectionStripe,{
    //             price: "price_1PJQwyBThrEa9ZnhMQv4ERga",
    //             success_url: window.location.origin,
    //             cancel_url: window.location.origin 
    //         });
        
            
    //     await getDocs(collection(db, "customers",user.uid,"checkout_sessions")).then((querySnapshot)=>{
    //         // const { sessionId } = querySnapshot.data();
        
    //         // console.log(querySnapshot)
        
    //         const sessionId = querySnapshot.docs.map((doc)=>({
    //             ...doc.data()
    //             // ...doc.data()
    //         }));
    //         // console.log("sessionId.length");
    //         // console.log(sessionId.length);
    //         // console.log(sessionId);
    //         // console.log(sessionId[0]["sessionId"]);
           
    //         const sess_id = sessionId[0]["sessionId"];
    //         if (sess_id) {
    //             // We have a session, let's redirect to Checkout
    //             // Init Stripe
    //             try{
    //             stripe.redirectToCheckout({sessionId: sess_id});
    //                 }
    //             catch(e){
    //                 // console.log("result")
    //                 console.log(e);
    //                 }
    //             }
        
    //         });
    //     }

    // const pdfCheckOut =()=>{
    //     <Link to="https://buy.stripe.com/test_6oE2cc2KF2NcgaQcMM"></Link>
    //     // navigate("https://buy.stripe.com/test_6oE2cc2KF2NcgaQcMM")
    // }

        

    useEffect(()=>{
        getEBookInfo();
        // logEvent(analytics, "Blog Post: "+ BlogInfo.title);

    },[])

    return(
        <div>
            <h1>Ebooks Unleashed</h1>
            <p>Books listed on this page are available to purchased or read directly on the site!</p>
            <h3>Use code <b>EbooksUnleashed24</b> to get 10% off any book in our new Ebooks Unleashed section on the website! Expires June 9th! </h3>
                    {
                        ebooks?.map((book, idx) => (

                            <div style={{minWidth:"20rem", margin:"auto", overflowWrap:"break-word",  whiteSpace: "pre-wrap"}} onClick ={()=>onEBookUnleashedDetailsButton(book.title,book.description,book.cover,book.price,book.shop_link,book.author, book.published, book.uid)}>
                            <Card hoverable style={{textAlign:"left", marginBottom:"1rem", backgroundColor: "#b0bec5"}} extra = {book.price}>
                                <Meta
                                avatar= {<Avatar shape="square" 
                                // size={{md:200, lg:200, xl:200, xxl:200}}
                                src={book.cover} className="img-book-ebooks"/>}
                                    
                                    description={book.description}
                                    title =  {book.title}
            
                                />
            
                            </Card>
                    </div>

                       
                          
                                // {/* <h1>{book.title}</h1>
                                // <img style={{height: "300px", width: "220px"}} src={book.cover} />
                                // <p>Published: {book.published}</p>
                                // <h3>Overview</h3>
                                // <p>{book.description}</p>

                                // <p>Price: {book.price}</p> */}
                                // {/* <button onClick={()=>loadCheckout(5.99)}>Buy Book To Read On Platform</button> */}
                                // {/* <Link to="https://buy.stripe.com/test_6oE2cc2KF2NcgaQcMM"><button>Buy Book PDF*</button></Link> */}



                        ))
                    }

        </div>
    );

}


export default EbooksUnleashed;

