import React, {useEffect, useRef, useState} from 'react';
import {
    query,
    collection, 
    orderBy,
    onSnapshot,
    limit,
    QuerySnapshot
} from 'firebase/firestore';
import {db, auth_} from '../../firebase';

import SendMessage from '../SendMessage';
import Message from '../Message';

import { map } from '@firebase/util';
import { useNavigate, useParams, useLocation} from 'react-router-dom';

const ChatBox = () =>{
    const [messages, setMessages] = useState([]);
    const scroll = useRef();
    const {uid,displayName, photoURL} = auth_.currentUser;

    const location = useLocation();

useEffect(()=>{
    const q = query(
        collection(db, "Users", uid, "Inbox", location?.state?.id, "messages"),
        orderBy("createdAt", "desc"),
        limit(50)
    );

    const unsubscribe = onSnapshot(q, (QuerySnapshot)=>{
        const fetchedMessages = [];
        QuerySnapshot.forEach((doc)=>{
            fetchedMessages.push({...doc.data(), id: doc.id});

        });

        const sortedMessages = fetchedMessages.sort(
            (a,b) => a.createdAt - b.createdAt
        );

        setMessages(sortedMessages);
    });
    return () =>unsubscribe;

    },[]);

    return (
        <main className='chat-box'>
            <div className='messages-wrapper'>
                {messages?.map((message)=>(
                    <Message key={message.id} message={message} />

                ))}

            </div>
            
            {/* when a new messaage enters the chat, the screen will scroll down  */}

            <span ref={scroll}></span>
            <SendMessage scroll={scroll} person={location?.state?.id}/>



        </main>

    );


}


export default ChatBox;