import React, {useState} from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuestion } from '@fortawesome/free-solid-svg-icons';
import { useNavigate, useLocation} from 'react-router-dom';
import {doc, collection, DocumentSnapshot, getDoc, getDocs, QuerySnapshot, getFirestore, setDoc} from 'firebase/firestore';
import {db, auth_} from './../../firebase';
import { useEffect } from "react";
import Popup from "../../components/Popup/Popup";

import {v4 as uuidv4} from 'uuid';



const ViewSpecificDiscussionBoardQuestion = () =>{
    const navigate = useNavigate();
    const location = useLocation();
    const documentSnapshot = new DocumentSnapshot();
    const user = auth_.currentUser;
    const firestore = getFirestore();

    const [DiscussionBoardQuestion, showDiscussionBoardQuestion] = useState([]);
    const [DiscussionBoardQuestionReplies, showDiscussionBoardQuestionReplies] = useState([]);
    const [author, getAuthorInfo] = useState([]);


    const [replier, getReplierInfo] = useState([]);




    const [isOpen, setIsOpen] = useState(false);
    const [isOpenErrorMessage, setIsOpenErrorMessage] = useState(false);
    const [isOpenEmptyMessage, setIsOpenEmptyMessage] = useState(false);
    const [photoURL, setPhotoURL] = useState("https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_1280.png");



    const repliesArray = [];
    const replierArray = [];

    const successNotification = () =>{
        // console.log("success message");
        setIsOpen(!isOpen);   
    }

    const warningNotification = () =>{
        // console.log("warning message");
        setIsOpenErrorMessage(!isOpenErrorMessage);   
    }

    const emptyNotification = () =>{
        // console.log("empty message");
        setIsOpenEmptyMessage(!isOpenEmptyMessage);   
    }
    const onSubmitButton  = () =>{
        navigate('/discussionboard/'+location?.state?.id.toLowerCase().replace(/ /g,""));
    }



    // location.state.id
    const getDiscussionBoardQuestion = async() =>{
        const question = await getDoc(doc(firestore, "DiscussionBoards",location?.state?.club_name, "Questions",location?.state?.user_question));
        showDiscussionBoardQuestion(question.data());


        const questionAuthor = await getDoc(doc(db, "Users", question.data().author_uid));
        getAuthorInfo(questionAuthor.data());

    }


    // const getDiscussionBoardQuestions = async() =>{

    //     const dbQuestions = await getDocs(collection(db, "DiscussionBoards",location?.state?.id, "Questions"));
    //     const questions =  dbQuestions.docs;
     
    //     questions.forEach( async(question) => {
    //         const questionAuthor = await getDoc(doc(db, "Users", question.data().author_uid));


    //         questionArray.push(question.data());
    //         showDiscussionBoardQuestions(questionArray);

    //         authorArray.push(questionAuthor.data());
    //         getAuthorInfo(authorArray);
    //       });

    // }


    const getDiscussionBoardQuestionReplies = async() =>{

        const dbQuestionReplies = await getDocs(collection(db, "DiscussionBoards",location?.state?.club_name, "Questions",location?.state?.user_question,"Replies"));
        const questionReplies =  dbQuestionReplies.docs;

        questionReplies.forEach( async(replies) => {
            const replier = await getDoc(doc(db, "Users", replies.data().replier_uid));


            repliesArray.push(replies.data());
            showDiscussionBoardQuestionReplies(repliesArray);

            replierArray.push(replier.data());
            getReplierInfo(replierArray);
          });    
    //    await getDocs(collection(db, "DiscussionBoards",location?.state?.club_name, "Questions",location?.state?.user_question,"Replies")).then((querySnapshot)=>{
    //         const replies = querySnapshot.docs.map((doc)=>({
    //             ...doc.data()
    //         }));

    //         showDiscussionBoardQuestionReplies(replies);

    //     });  
        
       }
   

        

    const onSubmitReply = async (event) =>{
        event.preventDefault();
        let date = new Date();
        date = date.toString();
        // console.log(location.state.club_name);
        const firestore = getFirestore();
        const discussionResponseUniqueID = uuidv4();


        try{

            if(event?.target?.db_reply?.value===null|| event?.target?.db_reply?.value.trim()===""){
                emptyNotification();
            }else{

            setDoc(doc(firestore,"DiscussionBoards",location?.state?.club_name, "Questions", location?.state?.user_question,"Replies", date),{
                dateReplied: date,
                replier: user?.displayName,
                replier_uid: user?.uid,
                replier_email: user?.email,
                reply: event?.target?.db_reply.value,
            });

            setDoc(doc(firestore,"Users",DiscussionBoardQuestion?.author_uid,"Notifications", DiscussionBoardQuestion?.author_uid + discussionResponseUniqueID+user.uid),{
                DateandTime: date,
                discussionBoardName: location?.state?.club_name,
                question: DiscussionBoardQuestion?.question,
                responsderDisplayName: user?.displayName,
                responseID: discussionResponseUniqueID,
                responderUID: user?.uid,
                type: "discussionboard_reply",
                isRead: false
            });

            setDoc(doc(firestore,"mail", DiscussionBoardQuestion?.author_uid + discussionResponseUniqueID+user.uid),{
                to: DiscussionBoardQuestion?.author_email,
                message: {
                    subject: "You have a new message!",
                    html:  user?.displayName + " responded to your question: "+ DiscussionBoardQuestion?.question + " in " + location?.state?.club_name +" <code><br/><button><a href='https://entrepreneurshipbooksapp.com/notifications'>View</a></button></code>",

                }
            });

            successNotification();
        }
      

        }catch(event){
            console.log("Error Adding Response: ",event );
            warningNotification();

        }


    }

    const onViewReaderProfileButton  = (reader) =>{
     
        navigate('/reader_profile/'+reader.displayName.toLowerCase().replace(/ /g,''),{
        state: {
            id: reader.uid,
            username: reader.displayName,
            email: reader.email
        }
    });
    }

    useEffect(()=>{
        getDiscussionBoardQuestion();
        getDiscussionBoardQuestionReplies();
    },[])


    return (
        <div>

                {/* if popup is open dont open */}
                {isOpen && 
                    <div>
                    <Popup
                    handleClose = {successNotification}
                    content = {
                    <div>
                        <h2 className="success-popup">Congratulations!</h2>
                        <p>Your response has been submitted!</p>
                    </div>
                    }
                />
                </div>}

                {isOpenErrorMessage&&<Popup
                  handleClose = {warningNotification}
                content = {
                <div>
                    <h2 className="error-popup">Error</h2>
                    <p>There was an issue submitting your response!</p>
                </div>
                }
            />}

            {isOpenEmptyMessage&&<Popup
                  handleClose = {emptyNotification}
                content = {
                <div>
                    <h2 className="error-popup">Error</h2>
                    <p>Please enter a response!</p>
                </div>
                }
            />} 

            <div className="card-container-conversations">

                <div className="conversations-card">
                    <div className="user-info-on-conversation-card" onClick={()=>onViewReaderProfileButton(author)}>
                        {/* <img src={photoURL} className="profilePic"/> */}
                        <img src={author.photoURL? author.photoURL : photoURL} alt="ProfilePic" className="profilePic"/>        
                        {/* <h2 >by {DiscussionBoardQuestion?.author}</h2> */}
                        <h2 >by {author?.displayName}</h2>
                        <h2 >Asked on {DiscussionBoardQuestion?.dateAsked}</h2>
                    </div>
                    <div className="info-on-conersation-card">
                        <h2 >{location?.state?.user_question}</h2>
                        <p>{location?.state?.question_details}</p>
                        {/* <button>Reply</button> */}
                    </div>
           

                        
                </div>

                    </div>
                    <div >
                        <form className="response-format" onSubmit={onSubmitReply}>
                        <textarea className="textarea-format" name="db_reply" placeholder="Enter response..."/>
                        <button type="submit">Submit Reply</button>
                        </form>
                    </div>
                    <div>
                           {
                        DiscussionBoardQuestionReplies?.map((responses, idx)=>(
                            <div className="conversations-card">
                            <div className="user-info-on-conversation-card" onClick={()=>onViewReaderProfileButton(replier[idx])}>
                                {/* <img src={photoURL} className="profilePic"/> */}
                                <img src={replier[idx].photoURL? replier[idx].photoURL : photoURL} alt="ProfilePic" className="profilePic"/>        
                                <h2 >by {replier[idx].displayName}</h2>
                                <h2 key={idx}>Replied on {responses?.dateReplied}</h2>                                

                            </div>
                            <div className="info-on-conversation-card">
                                <h2 key={idx}>{responses?.reply}</h2>
                            </div>

                            </div>
                        ))
                    }

                    </div>

                    <div className="horizontal-line"></div>

        </div>

    );
};
export default ViewSpecificDiscussionBoardQuestion;