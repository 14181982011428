import React, { useEffect,useState } from 'react';
import { useNavigate } from 'react-router-dom';
import firebase from '../../../firebase';


const Success = () =>{
    const [userId, setUserId] = useState("");
    const [sessionId, setSessionId] = useState("");
    const navigate = useNavigate();

    useEffect(()=>{
        firebase.auth().onAuthStateChanged((user)=>{
            if(user){
                setUserId(user.uid)
                const user_ref = firebase.collection("Users").doc(user.uid);
                user_ref.on('value',(snapshot) => {
                    const user = snapshot.val();
                    if(user){
                        setSessionId(user.subscription.sessionId||"")
                    }
                })
            }

        })

    }, [userId, sessionId]);


    const handlePaymentSuccess = () =>{
        fetch('/api/v1/paymentsuccess',{
            method: "POST",
            headers: {
                "Content-Type":"application/json"
            },
            body: JSON.stringify({sessionId: sessionId, firebaseId: userId})
        }).then((res)=>{
            if(res.ok) return res.json();
            return res.json().then(json=>Promise.reject(json));
        }).then(data=>{
            console.log(data.message);
            navigate("/");
        }).catch(e=>{
            console.log(e.error);
        });
    }

    return(
        <div>
            <h3>Payment Successful</h3>
            <button onClick={()=>handlePaymentSuccess()}>Proceed</button>
        </div>
    );

}



export default Success;