import React from "react";

const Card = ({name}) => {
    return(
        <div className="card">
            {/* <img src={icon} alt={name}></img> */}
            {/* <p>{img}</p> */}
            <h2>{name}</h2>
            <button>Follow</button>
        </div>
    );

};

export default Card;