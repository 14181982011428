import React, {useState} from "react";
import { useNavigate} from 'react-router-dom';
import {collection, setDoc, doc, onSnapshot, getCountFromServer, deleteDoc, getFirestore, getDoc, DocumentSnapshot, getDocs, QuerySnapshot} from 'firebase/firestore';
import {db, auth_, getProfilePic} from './../firebase';
import { FirebaseError } from "firebase/app";
import FlatList from 'flatlist-react';
import { useEffect } from "react";
import Card from './../components/Card';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBook, faBookReader, faCheck, faPerson, faPersonRifle, faUser} from '@fortawesome/free-solid-svg-icons';
import Papa from "papaparse";
import ReaderProfile from "./readerProfile"
import {v4 as uuidv4} from 'uuid';

import {MdChevronLeft, MdChevronRight} from "react-icons/md";
import Readers from "./readers";

import {Helmet} from "react-helmet";
import AdsComponent from './../components/AdComponents';
import { count } from "sneaks-api/models/Sneaker";


const Discover = () =>{

    
    const navigate = useNavigate();
    // const querySnapshot = new QuerySnapshot();
    const documentSnapshot = new DocumentSnapshot();
    const user = auth_.currentUser;



    const [Users, showReaders] = useState([]);
    const [DiscussionSnippets, showDiscussionSnippets] = useState([]);
    const [BookClubSnippets, showBookClubSnippets] = useState([]);
    const [BookClubMembers, getMembers] = useState([]);



    const [User, showReader] = useState([]);
    const [csvData, parsedCsvData] = useState([]);
    const [isFollowingReader, setAsFollowingReader] = useState(false);
    const [photoURL, setPhotoURL] = useState("https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_1280.png");
    const [currentFollower, showFollowers] = useState([]);
    const [eBooks, showEbooks] = useState([]);


    
    const readerArray = [];
    const followerArray = [];
    const clubMemberArray = [];
    const clubInforArray = [];
    
    const onBookRecsButton  = () =>{
        navigate('/bookrecs');
    }     

    const onPopularBooksButton  = () =>{
        navigate('/popular_books');
    }

    const onEBooksButton  = () =>{
        navigate('/ebooks_unleashed');
    }

    const onCategoryButton  = () =>{
        navigate('/categories');
    }

    const onReadersButton  = () =>{
        navigate('/readers');
    }


    // const checkFollowing = async (reader_uid)=>{
    //     const firestore = getFirestore();
    //     const followingReader = await getDoc(doc(firestore,"Users",reader_uid,"Followers", user.uid));

    //     if(followingReader.exists()){
    //         console.log("Member already is following!");

    //         setAsFollowingReader(!isFollowingReader);

    //     }else{
    //         console.log("Member is not following!");
    //         setAsFollowingReader(isFollowingReader);
        

    //     }



    // }


    const followReader = async (reader, reader_uid, reader_email) =>{
        const firestore = getFirestore();
        const date = new Date;
        const followUniqueID = uuidv4();
    
        // console.log(reader_uid);

        const followingReader = await getDoc(doc(firestore,"Users",reader_uid,"Followers", user.uid));

        if(followingReader.exists()){
            console.log("Member already is following!");

            setAsFollowingReader(!isFollowingReader);
            deleteDoc(doc(firestore,"Users",reader_uid,"Followers", user.uid));
            deleteDoc(doc(firestore,"Users",user.uid,"Following", reader_uid));
            if(followingReader.followID){
                deleteDoc(doc(firestore,"Users",reader_uid,"Notifications", user.uid + followingReader.followID + reader_uid));
            }

        }else{
            console.log("Member is now following!");
            setAsFollowingReader(!isFollowingReader);

            setDoc(doc(firestore,"Users", user.uid,"Following", reader_uid),{
                DateandTime: date,
                followingDisplayName: reader.displayName,
                followingEmail: reader_email,
                followID: followUniqueID,
                followingUID: reader_uid,
            });
            
            setDoc(doc(firestore,"Users",reader_uid,"Followers", user.uid),{
                DateandTime: date,
                followerDisplayName: user.displayName,
                followerEmail: user.email,
                followerUID: user.uid,
            });

            setDoc(doc(firestore,"Users",reader_uid,"Notifications", user.uid + followUniqueID+reader_uid),{
                DateandTime: date,
                followerDisplayName: user.displayName,
                followerEmail: user.email,
                followID: followUniqueID,
                followerUID: user.uid,
                type: "follow",
                isRead: false
            });

            setDoc(doc(firestore,"mail", user.uid + followUniqueID+reader_uid),{
                to: reader_email,
                message: {
                    subject: "You have a new follower!",
                    html:  user?.displayName + " just started following you! <code><br/><button><a href='https://entrepreneurshipbooksapp.com/notifications'>View</a></button></code>",

                }
            });

        }

    }

    const onBookDetailsButton  = (title, category, image, amazon_link, apple_link, bookshop_link) =>{

        navigate('/book_details/'+title,{
            state: {
                book_title: title,
                book_category: category,
                book_image: image,
                amazon: amazon_link,
                apple: apple_link,
                bookshop: bookshop_link,
            }
        });
    }

    const onSearchBookButton  = () =>{
        navigate('/search_books');
    }


    const onViewReaderProfileButton  = (user) =>{
     
        navigate('/reader_profile/'+user.displayName.toLowerCase().replace(/ /g,''),{
        state: {
            id: user.uid,
            username: user.displayName,
            email: user.email
        }
    });
    }

    const onBookClubButton = (club_name)=>{
        navigate('/bookclub/'+club_name,{
            state: {
                id: club_name
            }});

    }


    const onDiscussionQuestionButton = (club, question) =>{
        navigate('/discussionboardquestion/'+club.toLowerCase().replace(/ /g,"")+"/"+question.toLowerCase().replace(/ /g,"") ,{
            state:{
                user_question: question, 
                club_name: club
            }

        });
    }


    const getEbooks = async() =>{
        await getDocs(collection(db, "Books")).then((querySnapshot)=>{
            const books = querySnapshot.docs.slice(0,9).map((doc)=>({
                ...doc.data()
            }));
             showEbooks(books);
        });
    }


    const getReaders = async() =>{
        await getDocs(collection(db, "Users")).then((querySnapshot)=>{
            const reader = querySnapshot.docs.filter(doc => doc.data().displayName!=="" && doc.data().photoURL).slice(0,4).map((doc)=>({
                ...doc.data()
            }));
            // if(reader.displayName!=""){
                // showReaders(reader);
                // console.log(reader);
            // }


            
            reader.map(async (x)=>{
                // if(x.displayName!=="" && x.uid){
                readerArray.push(x);
                showReaders(readerArray);
                // console.log(x.displayName);
                // console.log(x.uid);
                const userFollowingInfo =  await getDoc(doc(db, "Users", user.uid, "Following", x.uid));
                // console.log(userFollowingInfo.data());
                if(userFollowingInfo.data()){
                followerArray.push(true);
                showFollowers(followerArray);
                // console.log(userFollowingInfo.data());
                }else{
                    followerArray.push(false);
                    showFollowers(followerArray);
                }
                
                
                // }
            });
        });   
    }

    const getDiscussionBoardQuestionSnippets = async() =>{
        await getDocs(collection(db, "DiscussionBoards","Novice Entrepreneurs","Questions")).then((querySnapshot)=>{
            const questions = querySnapshot.docs.map((doc)=>({
                ...doc.data()
            }));

            showDiscussionSnippets(questions);

        });   
    }


    const getBookClubSnippets = async() =>{
        const snippet = await getDocs(collection(db, "BookClubs"));
        const clubs  = snippet.docs;

            clubs.forEach( async(club) => {
            clubInforArray.push(club.data());
            showBookClubSnippets(clubInforArray);
                // console.log(club.data());
            const clubInfo =  await getCountFromServer(collection(db, "BookClubs", club.data().bookClubName, "Members"));

            // console.log(clubInfo.data().count)

            clubMemberArray.push(clubInfo.data().count);
            getMembers(clubMemberArray);
            });


        // });   
    }






    // const checkFollowing = ()=>{
    //     Users.map(reader=>{


    //         console.log(reader.displayName);
    //     });


    // }


    // const getReaders = async() =>{

    //     const readersInfo = await getDocs(collection(db, "Users"));
    //     const readerInfo = readersInfo.docs;

        // readerArray.push(readerInfo.data());
        // showReaders(readerArray);

        // readerInfo.forEach( async(reader) => {
            // const userInfo =  await getDoc(doc(db, "Users", reader.data().uid));

            // console.log(userInfo.data())

            // readerArray.push(userInfo.data());
            // showReaders(readerArray);

        


            // const userFollowingInfo =  await getDoc(doc(db, "Users", user.uid, "Following", reader.data().uid));

            // if(userFollowingInfo.data()){
            //     followerArray.push(!isFollowingReader);
            // }else{
            //     followerArray.push(isFollowingReader);
            // }

            
            // showFollowers(followerArray);

        
        //   });


        // await getDocs(collection(db, "Users")).then((querySnapshot)=>{
        //     const reader = querySnapshot.docs.map((doc)=>({
        //         ...doc.data(), id:doc.id, name: doc.displayName
        //     }));

        //     if(reader.name!==""){
        //         showReaders(reader);
    
                  
        //         // console.log(Users,reader)
        //     }


           
            

        // });   


        // const userFollowing = await getDoc(doc(db, "Users", location?.state?.id, "Followers", user.uid));
        // // console.log("Test FOLLOW");
        // // console.log(userFollowing.data());
        // if(userFollowing.data()){

        //     setAsFollowingReader(!isFollowingReader)
        // }
        


    // }
    


    var file = require("./../books/bookDatabase100_General.csv")
    const parseFile = () =>{
        Papa.parse(file, {
            download: true,
            header: false,
            complete: results =>{
                // console.log(results.data)
                parsedCsvData(results.data)
            },
        });
    }

    const onEBookUnleashedDetailsButton  = (title, description, image, price, link, author, date, id) =>{

        navigate('/ebook_unleashed_detail/'+title,{
            state: {
                book_title: title,
                book_description: description,
                book_image: image,
                book_price: price,
                shop: link,
                book_author: author,
                book_published: date,
                book_category: "ebooks_unleashed",
                uid: id


            }
        });
    }

    useEffect(()=>{
        getEbooks();
        getReaders();
        getDiscussionBoardQuestionSnippets();
        getBookClubSnippets();
        // getReader();
        parseFile();
        // checkFollowing();
        // console.log("i fire once");
    },[])

    const slideLeft_user = ()=>{
        var slider = document.getElementById("userSlider")
        // sroll left by 500px
        slider.scrollLeft = slider.scrollLeft - 500;

    }

    const slideRight_user = ()=>{
        var slider = document.getElementById("userSlider")
        // sroll right (plus sign) by 500px
        slider.scrollLeft= slider.scrollLeft + 500;

    }

    const slideLeft_pBooks = ()=>{
        var slider = document.getElementById("pBookSlider")
        // sroll left by 500px
        slider.scrollLeft = slider.scrollLeft - 500;

    }

    const slideRight_pBooks = ()=>{
        var slider = document.getElementById("pBookSlider")
        // sroll right (plus sign) by 500px
        slider.scrollLeft= slider.scrollLeft + 500;

    }



    const slideLeft_eBooks = ()=>{
        var slider = document.getElementById("eBookSlider")
        // sroll left by 500px
        slider.scrollLeft = slider.scrollLeft - 500;

    }

    const slideRight_eBooks = ()=>{
        var slider = document.getElementById("eBookSlider")
        // sroll right (plus sign) by 500px
        slider.scrollLeft= slider.scrollLeft + 500;

    }

    const onViewAllBookClubsButton = ()=>{
        navigate("/bookclubs")

    }

    const onViewAllDiscussionBoardButton = ()=>{
        navigate("/discussionboards")

    }
     

    return (
        <main>
           
                <div>
                    <AdsComponent className="ads-x" dataAdSlot='8393832390'/>
                    

                    <button onClick={onCategoryButton}>Browse Categories</button>
                    <button onClick={onBookRecsButton}>Book Recommendations</button>
                    <button onClick={onSearchBookButton}>Search More Books</button>

                    <h2>Connect With Other Readers</h2>
            
      


                    
                    <div className="carosel-homepage-books">
                        <MdChevronLeft className="slider-buttons" onClick={slideLeft_user} size={40}/>
                        <div id="userSlider" className="sliding-div">
                        {/* {
                        Users?.filter(reader => reader.displayName!=="").slice(0,4).map((reader,idx)=>( 
                             <div onClick={()=> onViewReaderProfileButton(reader)} className="card-home"> 
                             <img src={reader.photoURL? reader.photoURL : photoURL} alt="ProfilePic" className="profilePic"/>
                             
                            <h2 key={idx}>{reader.displayName}</h2> 
                        
                            {currentFollower[idx]? <button onClick={()=> followReader(user, reader.uid, reader.email)} className="button_followed_reader">Following <FontAwesomeIcon icon={faCheck}/></button>:<button onClick={()=> followReader(user, reader.uid, reader.email)}>Follow</button>}                             
                            <button onClick={()=>onViewReaderProfileButton(user)}>View Profile</button>

                      </div> 

                        ))    
       
                     } */}

{
                        Users?.map((reader,idx)=>( 
                            //  <div onClick={()=> onViewReaderProfileButton(reader)} className="card-home"> 
                            <div className="card-home"> 

                             <img src={reader.photoURL? reader.photoURL : photoURL} alt="ProfilePic" className="profilePic"/>
                             
                            <h2 key={idx}>{reader.name}</h2> 
                        
                            {currentFollower[idx]? <button onClick={()=> followReader(reader, reader.uid, reader.email)} className="button_followed_reader">Following <FontAwesomeIcon icon={faCheck}/></button>:<button onClick={()=> followReader(user, reader.uid, reader.email)}>Follow</button>}                             
                            <button onClick={()=>onViewReaderProfileButton(reader)}>View Profile</button>

                      </div> 

                        ))    
       
                     }

                        </div>
                        <MdChevronRight className="slider-buttons" onClick={slideRight_user} size={40}/>

                    </div> 
                   
                    <button onClick={onReadersButton}>Search For More Readers</button>


                    

                    <h2>Join Discussions Happening Now! - Beta</h2>
                    <button onClick={()=>onViewAllDiscussionBoardButton()}>View/Create More Discussions!</button>

                    <div className="carosel-homepage-books">
                        <MdChevronLeft className="slider-buttons" onClick={slideLeft_user} size={40}/>
                        <div id="userSlider" className="sliding-div">
                 

                            {DiscussionSnippets?.map((question,idx)=>( 
                            <div className={`card-home discover`} onClick={()=>onDiscussionQuestionButton("Novice Entrepreneurs", question.question)}> 
                            
                            <h2 key={idx}>{question.question}</h2> 

                      </div> 

                        ))    
       
                     }

                        </div>
                        <MdChevronRight className="slider-buttons" onClick={slideRight_user} size={40}/>

                    </div> 


                    <h2>Join Book Clubs! - Beta</h2>
                    <button onClick={()=>onViewAllBookClubsButton()}>View/Create More Book Clubs!</button>

                    <div className="carosel-homepage-books">
                        <MdChevronLeft className="slider-buttons" onClick={slideLeft_user} size={40}/>
                        <div id="userSlider" className="sliding-div">

                        {BookClubSnippets?.map((club,idx)=>( 
                            <div className={`card-home discover`} onClick={()=>onBookClubButton(club.bookClubName)}> 
                             
                            <h2 >{club.bookClubName}</h2> 
                            <div className="horizontal-line"></div>
                             <p><FontAwesomeIcon icon={faUser}/> {BookClubMembers[idx]} Members</p>
                        
                      </div> 

                        ))    
       
                     }

                        </div>
                        <MdChevronRight className="slider-buttons" onClick={slideRight_user} size={40}/>

                    </div> 



                    <h2>Ebooks Available To Read and Purchase On Platform</h2>
                    <button onClick={onEBooksButton}>View All Ebooks Available On The Platform</button>


                    <div className="carosel-homepage-books">
                    <MdChevronLeft className="slider-buttons" onClick={slideLeft_eBooks} size={40}/>

                        <div id="eBookSlider" className="sliding-div">
                           {
                            eBooks?.map((data,idx)=>(
                           
                                     <img className="img-book" src={data.cover} onClick={() =>onEBookUnleashedDetailsButton(data.title,data.description,data.cover,data.price,data.shop_link,data.author, data.published, data.uid)}/> 
                    
                                ))  
                            }
                        </div>
                        <MdChevronRight className="slider-buttons" onClick={slideRight_eBooks} size={40}/>


                    </div>


                    <h2>Popular on the Entrepreneurship Books App</h2>
                    <button onClick={onPopularBooksButton}>View All Popular Books</button>


                    <div className="carosel-homepage-books">
                    <MdChevronLeft className="slider-buttons" onClick={slideLeft_pBooks} size={40}/>

                        <div id="pBookSlider" className="sliding-div">
                           {
                            csvData?.slice(0,15).map((data,idx)=>(
                           
                                     <img className="img-book" src={data[2]? data[2]:""} onClick={() =>onBookDetailsButton(data[0],data[4],data[2], data[3], data[6], data[7])}/> 
                    
                                ))  
                            }
                        </div>
                        <MdChevronRight className="slider-buttons" onClick={slideRight_pBooks} size={40}/>


                    </div>
                    <AdsComponent  dataAdSlot='3973055866'/>

                </div>
        </main>
    );
                
};

export default Discover;