import React, {useState,useEffect} from "react";
import {collection, DocumentSnapshot, doc, getDoc, getDocs, getCountFromServer, getFirestore} from 'firebase/firestore';
import {auth_, db} from './../firebase';
import {getAuth, onAuthStateChanged} from "firebase/auth";

import { FirebaseError } from "firebase/app";

import { useNavigate} from 'react-router-dom';
import {MdChevronLeft, MdChevronRight} from "react-icons/md";


const Profile = () =>{

    const navigate = useNavigate();

    const user = auth_.currentUser;
    const [photoURL, setPhotoURL] = useState("https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_1280.png");

    // console.log("ace:");
    // console.log(ace);

    // const [user, setUser] = useState("");
    const [followingCount, countFollowing] = useState("")
    const [followersCount, countFollowers] = useState("")
    const [booksReadCount, countFinishedBooks] = useState("")

    const [FinishedReadingBooks, showFinishedReadingBooks] = useState([]);
    const [CurrentlyReadingBooks, showCurrentlyReadingBooks] = useState([]);
    const [WantToReadBooks, showWantToReadBooks] = useState([]);
    const [Wishlist, showWishlistBooks] = useState([]);
    const [Favorites, showFavoriteBooks] = useState([]);


    const [aboutText, showAboutText] = useState();

    
    const [profBackgroundText, showProfBackgroundText] = useState("");
    const [companyDescriptionText, showCompanyDescriptionText] = useState("");
    const [roleInCompanyText, showRoleInCompany] = useState("");
    const [ownershipYears, showOwnershipYears] = useState("");
    const [industryText, showIndustryText] = useState("");


    const slideLeft_finished = ()=>{
        var slider = document.getElementById("finishedSlider")
        // sroll left by 500px
        slider.scrollLeft = slider.scrollLeft - 500;

    }

    const slideRight_finished = ()=>{
        var slider = document.getElementById("finishedSlider")
        // sroll right (plus sign) by 500px
        slider.scrollLeft= slider.scrollLeft + 500;

    }



    const slideLeft_current = ()=>{
        var slider = document.getElementById("currentSlider")
        // sroll left by 500px
        slider.scrollLeft = slider.scrollLeft - 500;

    }

    const slideRight_current = ()=>{
        var slider = document.getElementById("currentSlider")
        // sroll right (plus sign) by 500px
        slider.scrollLeft= slider.scrollLeft + 500;

    }   
    
    const slideLeft_want = ()=>{
        var slider = document.getElementById("wantSlider")
        // sroll left by 500px
        slider.scrollLeft = slider.scrollLeft - 500;

    }

    const slideRight_want = ()=>{
        var slider = document.getElementById("wantSlider")
        // sroll right (plus sign) by 500px
        slider.scrollLeft= slider.scrollLeft + 500;

    }    

    const slideLeft_wish = ()=>{
        var slider = document.getElementById("wishSlider")
        // sroll left by 500px
        slider.scrollLeft = slider.scrollLeft - 500;

    }

    const slideRight_wish = ()=>{
        var slider = document.getElementById("wishSlider")
        // sroll right (plus sign) by 500px
        slider.scrollLeft= slider.scrollLeft + 500;

    }


    const slideLeft_favorite = ()=>{
        var slider = document.getElementById("favoriteSlider")
        // sroll left by 500px
        slider.scrollLeft = slider.scrollLeft - 500;

    }

    const slideRight_favorite = ()=>{
        var slider = document.getElementById("favoriteSlider")
        // sroll right (plus sign) by 500px
        slider.scrollLeft= slider.scrollLeft + 500;

    }

const getAboutandExperienceInfo = async () =>{

    try{
    const aboutAndExperienceInformation =  await getDoc(doc(db, "Users", user.uid));
        showAboutText(aboutAndExperienceInformation.data()?.about);
        showProfBackgroundText(aboutAndExperienceInformation.data()?.experience?.professional_background);
        showCompanyDescriptionText(aboutAndExperienceInformation.data()?.experience?.company_desc);
        showRoleInCompany(aboutAndExperienceInformation.data()?.experience?.company_role);
        showOwnershipYears(aboutAndExperienceInformation.data()?.experience?.company_years_in);
        showIndustryText(aboutAndExperienceInformation.data()?.experience?.industry);



    }catch(error){
        // console.log("error")
        console.log(error);
    }
   
}




const onEditProfessionalBackgroundButton = ()=>{
    navigate("/settings");
}
const getFollowers = async() =>{
    const firestore = getFirestore();
    // const c = collection(firestore, "Users","xUBvQxl5H9Uh0mMnCigFnxbqDNo1", "Followers");
    const c = collection(firestore, "Users",user.uid, "Followers");
    const count = await getCountFromServer(c);
    countFollowers(count.data().count);
    }

const getFollowing = async() =>{
        const firestore = getFirestore();
        const c = collection(firestore, "Users",user.uid, "Following");
        const count = await getCountFromServer(c);
        countFollowing(count.data().count);
        }

 const getFinishedBooksCount= async() =>{
  
        const firestore = getFirestore();
        // const c = collection(firestore, "Users","xUBvQxl5H9Uh0mMnCigFnxbqDNo1", "Finished Book");
        const c = collection(firestore, "Users", user.uid, "Finished Book");
        const count = await getCountFromServer(c);
        countFinishedBooks(count.data().count);
        }


    // const getUsers = async() =>{
    //     const auth = getAuth();
    //     onAuthStateChanged(auth, (user)=>{
    //         if(user){
    //             const userInfo = auth.currentUser;
    //             // console.log("user:")
    //             // console.log(user.displayName);
    //             // const email =  user.email;
    //             // const displayName =  user.displayName;
    //             // const uid = user.uid;
    //             setUser(userInfo)
    
    //         }
    //     });

    // }

    const getCurrentlyReadingBooks = async() =>{
        // "xUBvQxl5H9Uh0mMnCigFnxbqDNo1"
            // console.log("user:");
            // const auth = getAuth();
            // const users = auth.currentUser;

            // const users = onAuthStateChanged(auth, (user)=>{
            //     if(user){
            //         const userInfo = auth.currentUser;
            //         console.log("user:")
            //         console.log(user.displayName);
            //         // const email =  user.email;
            //         // const displayName =  user.displayName;
            //         // const uid = user.uid;
            //         return userInfo;
        
            //     }
            // });
            // console.log("user2: ")
            // console.log(users.displayName);

            await getDocs(collection(db, "Users", user.uid, "Currently Reading")).then((querySnapshot)=>{
                const book = querySnapshot.docs.map((doc)=>({
                    ...doc.data()
                }));
    
                // if(book.name!=""){
                    // console.log("BOOKS:");
                    // console.log(book);
                    showCurrentlyReadingBooks(book);
                // }
            });     
        }


        const getFinishedReadingBooks = async() =>{
                await getDocs(collection(db, "Users", user.uid, "Finished Book")).then((querySnapshot)=>{
                    const book = querySnapshot.docs.map((doc)=>({
                        ...doc.data()
                    }));
                        // console.log("BOOKS:");
                        // console.log(book);
                        showFinishedReadingBooks(book);       
                });   
            }

            const getWantToReadBooks = async() =>{
                await getDocs(collection(db, "Users", user.uid, "Want to Read")).then((querySnapshot)=>{
                    const book = querySnapshot.docs.map((doc)=>({
                        ...doc.data()
                    }));
                        // console.log("BOOKS:");
                        // console.log(book);
                        showWantToReadBooks(book);       
                });   
            }        
        
            const getWishlistBooks = async() =>{
                await getDocs(collection(db, "Users", user.uid, "Wishlist")).then((querySnapshot)=>{
                    const book = querySnapshot.docs.map((doc)=>({
                        ...doc.data()
                    }));
                        // console.log("BOOKS:");
                        // console.log(book);
                        showWishlistBooks(book);       
                });   
            } 


            const getFavoriteBooks = async() =>{
                await getDocs(collection(db, "Users", user.uid, "Favorites")).then((querySnapshot)=>{
                    const book = querySnapshot.docs.map((doc)=>({
                        ...doc.data()
                    }));
                        // console.log("BOOKS:");
                        // console.log(book);
                        showFavoriteBooks(book);       
                });   
            }    
            


    const onFollowingButton  = () =>{
                navigate("/"+user.displayName.toLowerCase().replace(/ /g,"")+'/following',{
                    state:{
                        username: user.displayName,
                        id: user.uid
                    }
                });
            }

    const onFollowersButton  = () =>{
                navigate("/"+user.displayName.toLowerCase().replace(/ /g,"")+'/followers',{
                    state:{
                        username: user.displayName,
                        id: user.uid
                    }
                });
            }


    const onReadingChallengeButton  = () =>{
        navigate('/reading_challenge');
    }

    const onFinishedReadingListButton  = () =>{
        navigate('/finishedreading');
    }   

    const onCurrentlyReadingListButton  = () =>{
        navigate('/currentlyreading');
    } 
    
    const onWantToReadListButton  = () =>{
        navigate('/wantotread');
    }  
    
    const onWishlistListButton  = () =>{
        navigate('/wishlist');
    }  

    const onFavoritesListButton  = () =>{
        navigate('/favorites');
    }  


    const documentSnapshot = new DocumentSnapshot();


    // const [Users, showReaders] = useState([]);


    // useEffect(()=>{
        // getUsers();
        // getFinishedBooksCount(user);
    // },[])

    useEffect(()=>{
        getFollowers();
        getFollowing();
        getFinishedBooksCount();
        getFinishedReadingBooks();
        getCurrentlyReadingBooks();
        getWantToReadBooks();
        getWishlistBooks();
        getFavoriteBooks();
        getAboutandExperienceInfo();

        if(user?.photoURL){
            setPhotoURL(user.photoURL);
        }

    },[])

    return (
        <div>

            <h1>Hello {user.displayName}!</h1>
            <p>Enjoy the app!</p>
            <br/>
            <img src={photoURL} alt="ProfilePic" className="profilePic"/>

            <p><a className = "a_following_followers" onClick={onFollowersButton}>{followersCount} Followers</a> and <a className = "a_following_followers" onClick={onFollowingButton}>{followingCount} Following</a></p>
        
            <p>You have read {booksReadCount} books</p>

    
            
            <h1>Reading Goals</h1>
            <button onClick={onReadingChallengeButton}>View/Create your reading goals here</button>
            <div className="horizontal-line"></div>

            <div className="profile-info-background">
            <h2>Professional Background</h2>
            <button onClick={onEditProfessionalBackgroundButton}>Edit</button>

            
            <h3>About</h3>
            <div className="card-profile">
            <label className="prof_bg_inputs">About</label>
            <div className="horizontal-line"></div>
            <p>{aboutText ? aboutText : "No response yet. Edit in settings."}</p>
            </div>

            <h3>Experience</h3>
            <div className="card-profile">
            <label className="prof_bg_inputs">Professional Background</label>
            <div className="horizontal-line"></div>
            <p>{profBackgroundText ? profBackgroundText : "No response yet. Edit in settings."}</p>
            </div>

            <div className="card-profile">
            <label className="prof_bg_inputs">Company Description</label>
            <div className="horizontal-line"></div>
            <p>{companyDescriptionText ? profBackgroundText : "No response yet. Edit in settings."}</p>
            </div>

            <div className="card-profile">
            <label className="prof_bg_inputs">Your role in that company</label>
            <div className="horizontal-line"></div>
            <p>{roleInCompanyText ? roleInCompanyText : "No response yet. Edit in settings."}</p>
            </div>

            <div className="card-profile">
            <label className="prof_bg_inputs">Years of Ownership Experience</label>
            <div className="horizontal-line"></div>
            <p>{ownershipYears ? ownershipYears : "No response yet. Edit in settings."}</p>
            </div>

            <div className="card-profile">
            <label className="prof_bg_inputs">Industry</label>
            <div className="horizontal-line"></div>
            <p>{industryText ? industryText : "No response yet. Edit in settings."}</p>
            </div>

            </div>


            <div className="horizontal-line"></div>


            
            <div className="horizontal-line"></div>
            <h1>Your Library</h1>

            <h3>Finished Reading </h3>

            
            {/* <div className="card-container">
                    {
                        FinishedReadingBooks?.slice(0,4).map((book,idx)=>(
                            <div className="card">
                                <br/>
                                <img src={book.bookImage} height={100} width={60}/>
                                <h2 key={idx}>{book.bookTitle}</h2>
                            </div>

                        ))
                        
                    }
            </div> */}

<div className="carosel-homepage-books">
            <MdChevronLeft className="slider-buttons" onClick={slideLeft_finished} size={40}/>

                        <div id="finishedSlider" className="sliding-div">
                           {
                            FinishedReadingBooks?.slice(0,4).map((book,idx)=>(
                           
                                     <img className="img-book" src={book.bookImage? book.bookImage: ""} onerror="this.src='https://nidcap.org/wp-content/uploads/2021/03/book.png';"/> 
                    
                                ))  
                            }
                        </div>
                        <MdChevronRight className="slider-buttons" onClick={slideRight_finished} size={40}/>


            </div>



            <button onClick={onFinishedReadingListButton}>View All</button>
            <h3>Currently Reading</h3>
            {/* <div className="card-container">
                    {
                        CurrentlyReadingBooks?.slice(0,4).map((book,idx)=>(
                            <div className="card">
                                <br/>
                                <img src={book.bookImage} height={100} width={60}/>
                                <h2 key={idx}>{book.bookTitle}</h2>
                            </div>

                        ))
                        
                    }


            </div> */}

            <div className="carosel-homepage-books">
            <MdChevronLeft className="slider-buttons" onClick={slideLeft_current} size={40}/>

                        <div id="currentSlider" className="sliding-div">
                           {
                            CurrentlyReadingBooks?.slice(0,4).map((book,idx)=>(
                           
                                     <img className="img-book" src={book.bookImage} /> 
                    
                                ))  
                            }
                        </div>
                        <MdChevronRight className="slider-buttons" onClick={slideRight_current} size={40}/>


            </div>
            <button onClick={onCurrentlyReadingListButton}>View All</button>
            <h3>Want To Read </h3>
            {/* <div className="card-container">
                    {
                        WantToReadBooks?.slice(0,4).map((book,idx)=>(
                            <div className="card">
                                <br/>
                                <img src={book.bookImage} height={100} width={60}/>
                                <h2 key={idx}>{book.bookTitle}</h2>
                            </div>

                        ))
                        
                    }
            </div> 

            <div className="carosel-homepage-books">
            <MdChevronLeft className="slider-buttons" onClick={slideLeft_want} size={40}/>

                        <div id="wantSlider" className="sliding-div">
                           {
                            WantToReadBooks?.slice(0,4).map((book,idx)=>(
                           
                                     <img className="img-book" src={book.bookImage} /> 
                    
                                ))  
                            }
                        </div>
                        <MdChevronRight className="slider-buttons" onClick={slideRight_want} size={40}/>


            </div>
            <button onClick={onWantToReadListButton}>View All</button>
            <h3>Wishlist </h3>
            {/* <div className="card-container">
                    {
                        Wishlist?.slice(0,4).map((book,idx)=>(
                            <div className="card">
                                <br/>
                                <img src={book.bookImage} height={100} width={60}/>
                                <h2 key={idx}>{book.bookTitle}</h2>
                            </div>

                        ))
                        
                    }
            </div> */}

            <div className="carosel-homepage-books">
            <MdChevronLeft className="slider-buttons" onClick={slideLeft_wish} size={40}/>

                        <div id="wishSlider" className="sliding-div">
                           {
                            Wishlist?.slice(0,4).map((book,idx)=>(
                           
                                     <img className="img-book" src={book.bookImage} /> 
                    
                                ))  
                            }
                        </div>
                        <MdChevronRight className="slider-buttons" onClick={slideRight_wish} size={40}/>


            </div>
            <button onClick={onWishlistListButton}>View All</button>
            <h3>Favorites </h3>
            {/* <div className="card-container">
                    {
                        Favorites?.slice(0,4).map((book,idx)=>(
                            <div className="card">
                                <br/>
                                <img src={book.bookImage} height={100} width={60}/>
                                <h2 key={idx}>{book.bookTitle}</h2>
                            </div>

                        ))
                        
                    }
            </div> */}


            <div className="carosel-homepage-books">
            <MdChevronLeft className="slider-buttons" onClick={slideLeft_favorite} size={40}/>

                        <div id="favoriteSlider" className="sliding-div">
                           {
                            Favorites?.slice(0,4).map((book,idx)=>(
                           
                                     <img className="img-book" src={book.bookImage} /> 
                    
                                ))  
                            }
                        </div>
                        <MdChevronRight className="slider-buttons" onClick={slideRight_favorite} size={40}/>


            </div>
            <button onClick={onFavoritesListButton}>View All</button> 
        </div>

    );
};
export default Profile;