import React, {useState} from "react";


const ContactUs = () =>{

    const [email, setEmail] = useState('');
    const [subject, setSubject] = useState('');
    const [message, setMessage] = useState('');



    return(
        // <section>
        <div>
            <h1>Contact Us</h1>
            <p>If you have any suggestions on feedback,<br/>
            please submit it here: <a href="https://bm93pem25sd.typeform.com/to/augvi2rM">Feedback Suggestions</a>
            <br/>
            If you have any book suggestions, <br/>
            please submit those here: <a href="https://bm93pem25sd.typeform.com/to/augvi2rM">Book suggestions</a>
            <br/>
            Anything else, please submit your questions or comments below.</p>
            <form>
            <div>  
                <label>Email: </label>       
                <input
                    // class = "input_register"
                    id = "Email"
                    name = "Email"
                    type = "email"
                    required
                    placeholder='Email'
                    onChange={(e)=>setEmail(e.target.value)}
                    />
            </div>
            <div>
                <label>Subject: </label>         
                <input
                    // class = "input_register"
                    id = "Subject"
                    name = "Subject"
                    type = "text"
                    required
                    placeholder='Subject'
                    onChange={(e)=>setSubject(e.target.value)}
                    />
            </div>
            <div> 
                <p>Message</p>
                <textarea
                    // class = "input_register"
                    className="textarea-format"
                    id = "Message"
                    name = "Message"
                    type = "text"
                    required
                    placeholder='Enter mesage'
                    onChange={(e)=>setMessage(e.target.value)}
                    />
            </div>
            <button type="submit">Submit</button>
            </form>
        </div>
        // </section>

    );
};

export default ContactUs;