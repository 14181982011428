import React, {useState,useEffect,useRef} from "react";

import {Nav,NavLink, NavMenu, Hamburger, Bars} from "./NavbarElements";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClock, faHouse, faBoltLightning, faInstagram, faChess, faArrowTrendUp, faDollarSign, faFire, faSchool, faChalkboardTeacher, faStore, faPenToSquare, faPeopleCarryBox, faSearch, faBook, faBookOpen, faBookOpenReader, faSwatchbook, faBars } from '@fortawesome/free-solid-svg-icons';
import './../../../src/App.css'
import {Link } from "react-router-dom";

const Navbar = () => {

    const [isOpen, setIsOpen] = useState(false);
    const menuRef = useRef(null);
    const buttonRef = useRef(null);


    const toggleMenu = () =>{
        setIsOpen((open)=>!open)

    }




    const handleOutsideClick = (event) =>{
        if(menuRef.current && 
            !menuRef.current.contains(event.target) &&
            event.target !== buttonRef.current){
                setIsOpen(false);

        }
    }

    useEffect(()=>{

        const hideMenu = e =>{
            // if(e.path.tagName !=="BUTTON"){
            //     setIsOpen(false);

            // }
            console.log("loggedin");
            console.log(e);
            
    
        }
        
        document.body.addEventListener('click', handleOutsideClick);
        return () => document.body.removeEventListener('click', handleOutsideClick);

    },[]);

    return (
       
    <nav className="nav" >
             <ul className="nav-menu">
             <li >  
                <a href="/" >EntrepreneurshipBooksApp</a>
            </li>
            <li ref ={menuRef} className="nav-menu-hamburger" onClick ={toggleMenu} >Menu <FontAwesomeIcon icon={faBars} /></li>
          
                <ul className={`nav-menu-items ${isOpen? "is-open":""}`}>
                    <li className= "li-nav">
                    <Link to="/login" > Login</Link>
                    </li>
                    <li className= "li-nav">
                        <Link to="/register"> Register</Link>
                    </li>
                </ul>
          

             </ul>

             </nav>
  


    );
};


export default Navbar;