import React, {useState} from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPeopleGroup } from '@fortawesome/free-solid-svg-icons';
import { useNavigate} from 'react-router-dom';
import {collection, DocumentSnapshot, getDocs, getDoc, setDocs,QuerySnapshot, addDoc, doc, setDoc, getFirestore} from 'firebase/firestore';
import {db, auth_} from './../../firebase';
import { useEffect } from "react";
import { getFID } from "web-vitals";
import addNotification from 'react-push-notification';
import Popup from "../../components/Popup/Popup";
// import {Notifications} from 'react-push-notification';



const CreateBookClub = () =>{
    const navigate = useNavigate();
    const documentSnapshot = new DocumentSnapshot();
    const user = auth_.currentUser;
    const date = new Date;
    const [isOpen, setIsOpen] = useState(false);
    const [isOpenErrorMessage, setIsOpenErrorMessage] = useState(false);
    const [isOpenDuplicateMessage, setIsOpenDuplicateMessage] = useState(false);
    const [isOpenEmptyMessage, setIsOpenEmptyMessage] = useState(false);


    const [bk_Description, saveBKDescription] = useState("");

    const successNotification = () =>{
        // console.log("success message");
        setIsOpen(!isOpen);   
    }

    const warningNotification = () =>{
        // console.log("warning message");
        setIsOpenErrorMessage(!isOpenErrorMessage);   
    }

    const duplicateNotification = () =>{
        // console.log("duplicate message");
        setIsOpenDuplicateMessage(!isOpenDuplicateMessage);   
    }

    const emptyNotification = () =>{
        // console.log("empty message");
        setIsOpenEmptyMessage(!isOpenEmptyMessage);   
    }

  


    const onSubmitNewBookClubName = async (event) =>{
        event.preventDefault();
        try{



            const firestore = getFirestore();
            // console.log(event?.target?.bc_name?.value);
            const name = await getDoc(doc(firestore,"BookClubs",event?.target?.bc_name?.value));
            // console.log(name);
            if(event.target.bc_name.value===null|| event?.target?.bc_name?.value.trim()===""|| bk_Description.trim()===""){
                emptyNotification();
            }else if(name.exists()){
                // console.log(name);
                duplicateNotification();
            }else{
                setDoc(doc(firestore,"BookClubs",event?.target?.bc_name.value),{
                    bookClubName: event?.target?.bc_name?.value,
                    bookClubNameID: event?.target?.bc_name?.value.replace(/ /g,"").toLowerCase(),
                    creator: user?.displayName,
                    creator_uid: user?.uid,
                    creator_email: user?.email,
                    dateCreated: date,
                    bookClubDescription: bk_Description
                });
                // console.log("Document Written with ID: ",dashboard.id )
                successNotification();

            }
  
        }catch(event){
            warningNotification();
            // console.log("Error Adding Document: ",event );

        }


    }
    
    const onSubmitButton  = () =>{
        navigate('/bookclubs');
    }



    // useEffect(()=>{
    //     createDiscussionBoards();
    // },[])


    return (
        <div>
            
            <h2>Create A Book Club</h2>
            {/* <button onClick={successNotification}>Click me</button> */}
            {/* if popup is open dont open */}
            {isOpen && 
                    <div>
                    <Popup
                    handleClose = {successNotification}
                    content = {
                    <div>
                        <h2 className="success-popup">Congratulations!</h2>
                        <p>Your book club has been created!</p>
                    </div>
                    }
                />
                </div>}

                {isOpenErrorMessage&&<Popup
                  handleClose = {warningNotification}
                content = {
                <div>
                    <h2 className="error-popup">Error</h2>
                    <p>There was an issue creating your book club!</p>
                </div>
                }
            />}

            {isOpenEmptyMessage&&<Popup
                  handleClose = {emptyNotification}
                content = {
                <div>
                    <h2 className="error-popup">Error</h2>
                    <p>Fields can not be empty!</p>
                </div>
                }
            />} 
            
            {isOpenDuplicateMessage&&<Popup
                  handleClose = {duplicateNotification}
                content = {
                <div>
                    <h2 className="error-popup">Error</h2>
                    <p>Book club already exists!</p>
                </div>
                }
            />} 
            <form onSubmit={onSubmitNewBookClubName}>

                <label>
                    Book Club Name:
                    <input  className="prof_bg_inputs" placeholder="Enter Book Club Name" type="text" name="bc_name" ></input>
                    </label>
                    
                    <label  className="prof_bg_inputs">Book Club Description</label>
                <textarea
                    rows={5} 
                    cols={35}
                    type="text"
                    value={bk_Description}
                    onChange = {e => saveBKDescription(e.target.value)}
                    className="prof_bg_inputs"></textarea>
                <br/>

               

                <br/>

                <button type="submit">Submit</button>
                <br/>
          
            </form>

        </div>

    );
};
export default CreateBookClub;