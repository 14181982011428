import React, {useState, useEffect} from 'react';
import { getAnalytics, logEvent } from 'firebase/analytics';
// import rss_parsers;
import Parser from 'rss-parser';


const NewsAgg=()=>{

    const analytics = getAnalytics();
    let parser = new Parser({
        mode: 'no-cors',
        // headers: { 'Access-Control-Allow-Origin': true},
       
    });
    const [f, setFeed] = useState('');

    const getFeed = async () => {

        let feed  = await parser.parseURL('https://medium.com/feed/.rss')
        console.log("hello")
        console.log(feed)
        setFeed(feed)

    }

    useEffect(() => {
        getFeed();
    }, [])


    // logEvent(analytics, "News Articles");

    return(
        <div>
            <h1>News Articles</h1>
            {/* <div> */}
            {/* <div>{
                    f?.map((c, idx) => (
                        <p>{c.title}</p>
                        ))
                    }</div>
            </div> */}

        </div>
    );

}

export default NewsAgg;