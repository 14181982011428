import React, {useState,useEffect} from "react";
import {collection, deleteDoc, DocumentSnapshot, getFirestore, getDoc, setDoc, getDocs, doc, getCountFromServer} from 'firebase/firestore';
import {auth_, db} from './../firebase';
import {getAuth, onAuthStateChanged} from "firebase/auth";

import { FirebaseError } from "firebase/app";
import { useNavigate} from 'react-router-dom';
import {HorizontalBar} from 'react-chartjs-2';
import Popup from "../components/Popup/Popup";

const ReadingChallenge = () =>{
    
    const user = auth_.currentUser;



    const navigate = useNavigate();

    const onFinishedBookButton  = () =>{
 
        navigate('/finishedreading');

    }

    // const [user, setUser] = useState("");
    const [booksReadCount, countFinishedBooks] = useState("")
    const [bookGoal, showBookGoal] = useState("")

    const [FinishedReadingBooks, showFinishedReadingBooks] = useState([]);
    const [isOpen, setIsOpen] = useState(false);
    const [isOpenErrorMessage, setIsOpenErrorMessage] = useState(false);
    const [isOpenDuplicateMessage, setIsOpenDuplicateMessage] = useState(false);
    const [isOpenEmptyMessage, setIsOpenEmptyMessage] = useState(false);

    const successNotification = () =>{
        // console.log("success message");
        setIsOpen(!isOpen);   
    }

    const warningNotification = () =>{
        // console.log("warning message");
        setIsOpenErrorMessage(!isOpenErrorMessage);   
    }

    const duplicateNotification = () =>{
        // console.log("duplicate message");
        setIsOpenDuplicateMessage(!isOpenDuplicateMessage);   
    }

    const emptyNotification = () =>{
        // console.log("empty message");
        setIsOpenEmptyMessage(!isOpenEmptyMessage);   
    }

    const getFinishedBooksCount= async() =>{
        const firestore = getFirestore();
        const c = collection(firestore, "Users", user.uid, "Finished Book");
        const count = await getCountFromServer(c);
        countFinishedBooks(count.data().count);
        }
    const getFinishedReadingBooks = async() =>{
            await getDocs(collection(db, "Users", user.uid, "Finished Book")).then((querySnapshot)=>{
                const book = querySnapshot.docs.map((doc)=>({
                    ...doc.data()
                }));
                    // console.log("BOOKS:");
                    // console.log(book);
                    showFinishedReadingBooks(book);       
            });   
        }

        const getBookGoal = async() =>{
            await getDocs(collection(db, "Users", user.uid, "ReadingChallenge")).then((querySnapshot)=>{
                const book = querySnapshot.docs.map((doc)=>({
                    ...doc.data()
                }));
                    // console.log("BOOKS:");
                    // console.log(book[0].numBooksWantReadGoalAmount);
                    showBookGoal(book[0].numBooksWantReadGoalAmount);       
            });   
        }
    const numBooksToRead = async(event)=>{
        event.preventDefault();

        try{

            const firestore = getFirestore();
            console.log(event.target.numBooks.value);
            // const books = await getDoc(doc(firestore,"Users",user.uid, "ReadingChallenge",  "TotalBookGoal"));
            // console.log(name);
            if(event.target.numBooks.value===null|| event.target.numBooks.value===0){
                emptyNotification();
            }
            // else if(books.exists()){
                // console.log(name);
                // duplicateNotification();
            // }
            else{
                setDoc(doc(firestore,"Users",user.uid, "ReadingChallenge",  "TotalBookGoal"),{
                    numBooksWantReadGoalAmount: event?.target?.numBooks?.value,
    
                });
                // console.log("Document Written with ID: ",dashboard.id )
                successNotification();

            }
  
        }catch(event){
            warningNotification();
            // console.log("Error Adding Document: ",event );

        }




    }

    useEffect(()=>{

        getFinishedBooksCount();
        getFinishedReadingBooks();
        getBookGoal();
        },[])

    return (
        <div>
            <h1>Reading Challenges</h1>
            {isOpen && 
                    <div>
                    <Popup
                    handleClose = {successNotification}
                    content = {
                    <div>
                        <h2 className="success-popup">Congratulations!</h2>
                        <p>Your book club has been created!</p>
                    </div>
                    }
                />
                </div>}

                {isOpenErrorMessage&&<Popup
                  handleClose = {warningNotification}
                content = {
                <div>
                    <h2 className="error-popup">Error</h2>
                    <p>There was an issue creating your book club!</p>
                </div>
                }
            />}

            {isOpenEmptyMessage&&<Popup
                  handleClose = {emptyNotification}
                content = {
                <div>
                    <h2 className="error-popup">Error</h2>
                    <p>Please enter name for your book club!</p>
                </div>
                }
            />} 
            
            {isOpenDuplicateMessage&&<Popup
                  handleClose = {duplicateNotification}
                content = {
                <div>
                    <h2 className="error-popup">Error</h2>
                    <p>Book club already exists!</p>
                </div>
                }
            />} 



            <h2>Your have read {booksReadCount? booksReadCount: "0"} Books!</h2>
            <HorizontalBar
                // width={100}
                // height={50}
                // options={{maintainAspectRatio:false}}
                data = {
                {
                    labels:[
                        "Books"
                    ],
                    datasets: [
                        {
                            label: "Books Completed (%)",
                            backgroundColor: 'rgba(96, 125, 139)',
                            data: [(booksReadCount/bookGoal)*100],
                            fill: true
                        }
                    ]
                }
            }/>

            <p>Great Job!</p>
            <p>How many books do you want to read?</p>

            <div>
                <form onSubmit={numBooksToRead}>
                    <input name = "numBooks" placeholder="Enter number books..."/>
                    <button type="submit">Submit</button>
                </form>
            </div>

            <div className="horizontal-line"></div>

            <h2>Finished Books</h2>
            {/* <div className="card-container">
                    {
                        FinishedReadingBooks?.slice(0,4).map((book,idx)=>(
                            <div className="card">
                                <br/>
                                <img src={book.bookImage} height={100} width={60}/>
                                <h2 key={idx}>{book.bookTitle}</h2>
                            </div>

                        ))
                        
                    }
            </div> */}


<div className="card-container">
                    {
                        FinishedReadingBooks?.slice(0,4).map((book,idx)=>(
                                <img src={book.bookImage} className="img-book"/>

                        ))
                        
                    }
            </div>

            <button onClick={onFinishedBookButton}>View All Books Read</button>

        </div>

    );
};
export default ReadingChallenge;