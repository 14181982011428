import React, {useState} from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBookOpen,faCheck,faQuestion } from '@fortawesome/free-solid-svg-icons';
import { useNavigate, useLocation} from 'react-router-dom';
import {collection, deleteDoc, DocumentSnapshot, getFirestore, getDoc, setDoc, getDocs, doc} from 'firebase/firestore';
import {db, auth_} from './../../firebase';
import { useEffect } from "react";
import Popup from "../../components/Popup/Popup";
import {v4 as uuidv4} from 'uuid';




const ViewBookClub = () =>{
    const navigate = useNavigate();
    const documentSnapshot = new DocumentSnapshot();
    const user = auth_.currentUser;
    const location = useLocation();
    const date = new Date;
    const [photoURL, setPhotoURL] = useState("https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_1280.png");


    const [DiscussionSections, showDiscussionSections] = useState([]);

    const [BookClub, showBookClub] = useState([]);

    const [bookClubCreatorInfo, getBookClubCreatorInfo] = useState([]);
    const [BookClubReplies, showBookClubReplies] = useState([]);
    const [responder, getResponderInfo] = useState([]);


    const [isOpen, setIsOpen] = useState(false);
    const [isOpenErrorMessage, setIsOpenErrorMessage] = useState(false);
    const [isOpenEmptyMessage, setIsOpenEmptyMessage] = useState(false);
    const [isOpenDuplicateMessage, setIsOpenDuplicateMessage] = useState(false);
    const [isPressedCreateNewSection, setNewSectionFromPressed] = useState(false);
    const [discussionSectionName, setDiscussionSectionName] = useState("");


    const [isJoined, setAsJoinedMember] = useState(false);


    const [bkSection_Description, saveBKSectionDescription] = useState("");


    const responseArray = [];
    const responderArray = [];

    const successNotification = () =>{
        // console.log("success message");
        setIsOpen(!isOpen);   
    }

    const warningNotification = () =>{
        // console.log("warning message");
        setIsOpenErrorMessage(!isOpenErrorMessage);   
    }

    const duplicateNotification = () =>{
        // console.log("duplicate message");
        setIsOpenDuplicateMessage(!isOpenDuplicateMessage);   
    }


    const emptyNotification = () =>{
        // console.log("empty message");
        setIsOpenEmptyMessage(!isOpenEmptyMessage);   
    }

    const onPastReadsButton  = (name) =>{
        navigate(name+'/pastreadshelf');
    }

    const onCurrentReadsButton  = (name) =>{
        navigate(name+'/currentreadshelf');
    }

    const onAddBookButton  = (name) =>{
        navigate('/bookclublibrary',{
            state:{
                club_name: name
            }
        });
    }


    const onViewMembers  = (name) =>{
        navigate("/"+name+'/members',{
            state:{
                club_name: name
            }
        });
    }

    const getBookClub = async() =>{



        const firestore = getFirestore();

        // console.log("book club");

        const bookClubCreationInfo = await getDoc(doc(firestore, "BookClubs", location?.state?.id));
        // console.log(u.data());

        showBookClub(bookClubCreationInfo?.data());  

        const creatorInfo = await getDoc(doc(firestore, "Users", bookClubCreationInfo?.data().creator_uid));
        getBookClubCreatorInfo(creatorInfo.data())

     

        // collection(firestore,"BookClubs").doc(location.state.id).get().then((snapshot)=>{
        //     const bookclub = snapshot.data();

            // console.log("book club");
            // console.log(bookclub);

            // showBookClub(bookclub);

        // });

        // const firestore = getFirestore();

        // const c = doc(firestore, "Users", location.state.id);

        // await getDocs(c).then((querySnapshot)=>{
        //     const bookclub = querySnapshot.docs.map((doc)=>({
        //         ...doc.data()
        //     }));

        //     if(bookclub.name!==""){
        //         showBookClub(bookclub);
        //     }
            
            

        // });   


    }

    const checkJoinedClub = async () =>{
        try{

            const firestore = getFirestore();
            const member = await getDoc(doc(firestore,"BookClubs",location?.state?.id,"Members",user?.uid));
            // console.log(member);
            if(member.exists()){
                console.log("Member already exist!");
                setAsJoinedMember(!isJoined);
            }else{
                setAsJoinedMember(isJoined);
            }
        }catch(e){
                console.log(e);
            }

    }




    const onJoinBookClub = async () =>{
        const joinBookclubUniqueID = uuidv4();

        try{

            const firestore = getFirestore();
            // const member = await getDoc(doc(firestore,"BookClubs",location.state.id,"Members",user.uid));
            // console.log(member);

            
            
            if(isJoined){
                
                console.log("Member already exist!");

                // setAsJoinedMember(!isJoined);
                deleteDoc(doc(firestore,"BookClubs",location?.state?.id,"Members",user.uid));


            }else{
                console.log("Member is now added!");
                setAsJoinedMember(!isJoined);
                // console.log(location?.state?.id);
                setDoc(doc(firestore,"BookClubs",location?.state?.id,"Members",user.uid),{
                    followerDisplayName: user?.displayName,
                    followerUID: user?.uid,
                    
                    DateandTime: date,
                });


                setDoc(doc(firestore,"Users",BookClub?.creator_uid,"Notifications", BookClub?.creator_uid+ joinBookclubUniqueID+user.uid),{
                    DateandTime: date,
                    bookclubname: BookClub?.bookClubName,
                    newMemberDisplayName: user?.displayName,
                    joinedBookClubID: joinBookclubUniqueID,
                    newMemberrUID: user?.uid,
                    type: "bookclub_member_added",
                    isRead: false
                });


                setDoc(doc(firestore,"mail", BookClub?.creator_uid+ joinBookclubUniqueID+user.uid),{
                    // to: BookClub?.creator_email,
                    to : bookClubCreatorInfo?.email,
                    message: {
                        subject: "A new person joined  your book club!",
                        html:  user?.displayName + " joined your book club "+ BookClub?.bookClubName +" <code><br/><button><a href='https://entrepreneurshipbooksapp.com/notifications'>View</a></button></code>",
    
                    }
                });

            }
  
        }catch(event){
            // warningNotification();
            console.log("Error Adding Member to Club: ",event );

        }


    }


    const getBookClubResponses = async() =>{

        const bookClubResponses = await getDocs(collection(db, "BookClubs",location?.state?.id, "Replies"));
        const responses = bookClubResponses.docs;

        responses.forEach( async(response) => {
            const responseAuthor = await getDoc(doc(db, "Users", response.data().replier_uid));

            responseArray.push(response.data());
            showBookClubReplies(responseArray);

            responderArray.push(responseAuthor.data());
            getResponderInfo(responderArray);
          });
         
        }    
    
        const onSubmitCommentButton = async (event) =>{
            event.preventDefault();
            let date = new Date();
            date = date.toString();
            // console.log(location.state.club_name);
            const firestore = getFirestore();
            const bookclubCommentCreatorID_ = uuidv4();

    
            try{
    
                if(event?.target?.db_reply?.value===null || event?.target?.db_reply?.value.trim()===""){
                    emptyNotification();
                }else{
    
                setDoc(doc(firestore,"BookClubs",location?.state?.id, "Replies", date),{
                    replier: user?.displayName,
                    reply: event?.target?.bc_comment?.value,
                    dateReplied: date,
                    replier_uid: user?.uid,
                    
                });

                setDoc(doc(firestore,"Users",BookClub?.creator_uid,"Notifications", BookClub?.creator_uid+ bookclubCommentCreatorID_+user.uid),{
                    DateandTime: date,
                    bookclubname: BookClub?.bookClubName,
                    comment: event?.target?.bc_comment?.value,
                    responderDisplayName: user?.displayName,
                    bookclubCommentCreatorID: bookclubCommentCreatorID_,
                    commentCreatorUID: user?.uid,
                    type: "bookclub_comment_added",
                    isRead: false
                });

                setDoc(doc(firestore,"mail", BookClub?.creator_uid+ bookclubCommentCreatorID_+user.uid),{
                    // to: BookClub?.creator_email,
                    to : bookClubCreatorInfo?.email,
                    message: {
                        subject: "You have a new message!",
                        html:  user?.displayName + " added a message in your book club "+ BookClub?.bookClubName +" <code><br/><button><a href='https://entrepreneurshipbooksapp.com/notifications'>View</a></button></code>",
    
                    }
                });
    
                successNotification();
            }
          
    
            }catch(event){
                console.log("Error Adding Response: ",event );
                warningNotification();
    
            }
    
    
        }
    
    const onPressedCreateNewSectionButton = () =>{
        setNewSectionFromPressed(!isPressedCreateNewSection)

    }

    const createDiscussionSection = async(event) =>{
        event.preventDefault();
        console.log(discussionSectionName);
        const bookclubSectionCreatorID_ = uuidv4();
        try{

            const firestore = getFirestore();
            const section = await getDoc(doc(firestore,"BookClubs",location?.state?.id, "BookClubDiscussionSections",discussionSectionName));
            // console.log(name);
            if(discussionSectionName===null|| discussionSectionName.trim()==="" || bkSection_Description.trim()===""){
                emptyNotification();
            }else if(section.exists()){
                // console.log(name);
                duplicateNotification();
            }else{
                setDoc(doc(firestore,"BookClubs",location?.state?.id,"BookClubDiscussionSections",discussionSectionName),{
                    discussionSectionName: discussionSectionName,
                    discussionSectionNameID: discussionSectionName.replace(/ /g,"").toLowerCase(),
                    creator: user?.displayName,
                    creator_uid: user?.uid,
                    dateCreated: date,
                    description: bkSection_Description
                });


                setDoc(doc(firestore,"Users",BookClub?.creator_uid,"Notifications", BookClub?.creator_uid+ bookclubSectionCreatorID_+user.uid),{
                    DateandTime: date,
                    bookclubname: BookClub?.bookClubName,
                    sectionName: discussionSectionName,
                    creatorDisplayName: user?.displayName,
                    bookclubSectionCreatorID: bookclubSectionCreatorID_,
                    sectionCreatorUID: user?.uid,
                    type: "bookclub_section_added",
                    isRead: false
                });
                
                setDoc(doc(firestore,"mail", BookClub?.creator_uid+ bookclubSectionCreatorID_+user.uid),{
                    // to: BookClub?.creator_email,
                    to : bookClubCreatorInfo?.email,
                    message: {
                        subject: "A new section has been created in your book club!",
                        html:  user?.displayName + " added a new section called "+ discussionSectionName +" <code><br/><button><a href='https://entrepreneurshipbooksapp.com/notifications'>View</a></button></code>",
    
                    }
                });

                // console.log("Document Written with ID: ",dashboard.id )
                successNotification();

            }
  
        }catch(event){
            warningNotification();
            // console.log("Error Adding Document: ",event );

        }

    }

    const getDiscussionDiscussionSections = async() =>{
        await getDocs(collection(db, "BookClubs",location?.state?.id, "BookClubDiscussionSections")).then((querySnapshot)=>{
            const discussion_sections = querySnapshot.docs.map((doc)=>({
                ...doc.data()
            }));

            // if(discussionboards.name!==""){
                showDiscussionSections(discussion_sections);
            // }
            
            

        });   


    }

    const clickDiscussionSection = (section_name) =>{
        navigate('/'+location?.state?.id+'/'+section_name,{
            state:{
                club_name: location?.state?.id,
                section: section_name,
                club_name_creator_email: bookClubCreatorInfo.email,
                club_name_creator_uid: BookClub.creator_uid
            }
        })


    }

    const onViewReaderProfileButton  = (reader) =>{
     
        navigate('/reader_profile/'+reader.displayName.toLowerCase().replace(/ /g,''),{
        state: {
            id: reader.uid,
            username: reader.displayName,
            email: reader.email
        }
    });
    }

    useEffect(()=>{
        getBookClub();
        getBookClubResponses();
        getDiscussionDiscussionSections();
        checkJoinedClub();
    },[])


    return (
        <div>
            
                {/* if popup is open dont open */}
                {isOpen && 
                    <div>
                    <Popup
                    handleClose = {successNotification}
                    content = {
                    <div>
                        <h2 className="success-popup">Congratulations!</h2>
                        <p>Your response has been submitted!</p>
                    </div>
                    }
                />
                </div>}

                {isOpenErrorMessage&&<Popup
                  handleClose = {warningNotification}
                content = {
                <div>
                    <h2 className="error-popup">Error</h2>
                    <p>There was an issue submitting section!</p>
                </div>
                }
            />}

            {isOpenEmptyMessage&&<Popup
                  handleClose = {emptyNotification}
                content = {
                <div>
                    <h2 className="error-popup">Error</h2>
                    <p>Please enter a discussion section name!</p>
                </div>
                }
            />} 

            {isOpenDuplicateMessage&&<Popup
                  handleClose = {duplicateNotification}
                content = {
                <div>
                    <h2 className="error-popup">Error</h2>
                    <p>Discussion Section Already Created!</p>
                </div>
                }
            />} 


            {isPressedCreateNewSection && 
                    <div>
                    <Popup
                    handleClose = {onPressedCreateNewSectionButton}
                    content = {
                    <div>
                        <form onSubmit={createDiscussionSection}>
                        <input
                                class = "input_register"
                                id = "discussion-board-section"
                                name = "discussionsection"
                                type = "text"
                                required
                                placeholder='Discussion Board Section'
                                onChange={(e)=>setDiscussionSectionName(e?.target?.value)}
                            />
                        
                        <label  className="prof_bg_inputs">Book Club New Section Description</label>
                        <textarea
                            rows={5} 
                            cols={35}
                            type="text"
                            value={bkSection_Description}
                            onChange = {e => saveBKSectionDescription(e.target.value)}
                            className="prof_bg_inputs"></textarea>
                            <br/>
                            <button>Create Section</button>
                        </form>
                        
                    </div>
                    }
                />
                </div>}

           
            <h2>{BookClub?.bookClubName}</h2>


            <button onClick={()=>onCurrentReadsButton(location?.state?.id)}>Currently Reading</button>
            <button onClick={()=>onPastReadsButton(location?.state?.id)}>Past Reads</button>
            <button onClick={()=>onAddBookButton(location?.state?.id)}>Add Book +</button>
            <button onClick={()=>onPressedCreateNewSectionButton()} >Create New Section</button>
            <button onClick={()=>onViewMembers(location?.state?.id)}>Members</button>

            {/* <button onClick= {()=>onJoinBookClub()}>{isJoined?"Already Joined "+ <FontAwesomeIcon icon = {faBookOpen}/>}</button> */}
            {isJoined?<button className="button_joined_club" onClick= {()=>onJoinBookClub()}>Already Joined <FontAwesomeIcon icon = {faBookOpen}/></button>:<button onClick= {()=>onJoinBookClub()}>Join Club</button>}

            {/* <h2>Book Club General Discussions...</h2> */}

            {/* <button onClick={onSubmitCommentButton}>Comment +</button> */}

            <div className="horizontal-line"></div>
                    <h2>Club Sub Discussions</h2>
                   
                           {
                        DiscussionSections?.map((section)=>(
                            <div onClick={()=>clickDiscussionSection(section?.discussionSectionName)} className="discussion_section">
                            {/* <p><FontAwesomeIcon icon={faQuestion} size ="2xl"/></p> */}
                            <h2 >{section?.discussionSectionName}</h2>
                            </div>
                        ))
                    }

                    

                    <div className="horizontal-line"></div>
                    <h2>General Discussions</h2>

                    <div >
                    <form className="response-format" onSubmit={onSubmitCommentButton}>
                        <textarea className="textarea-format" name="bc_comment" placeholder="Enter response..."/>
                        <button type="submit">Submit Comment</button>
                    </form>
                    </div>


               
                           {
                        BookClubReplies?.map((responses, idx)=>(
                            <div className="conversations-card">
                            
                            <div className="user-info-on-conversation-card" onClick={()=>onViewReaderProfileButton(responder[idx])}>
                                <img src={responder[idx].photoURL? responder[idx].photoURL : photoURL} alt="ProfilePic" className="profilePic"/>     
                                <h2 >by {responder[idx].displayName}</h2>
                                <h2 key={idx}>Replied on {responses?.dateReplied}</h2>
                            </div>
                            <div className="info-on-conversation-card">
                                <h2 key={idx}>{responses?.reply}</h2>
                            </div>                               
                            
                            </div>
                        ))
                    }

                 



        </div>

    );
};
export default ViewBookClub;