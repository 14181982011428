import React, {useState, useEffect} from 'react';
import image_blog1 from '../../images/adeolu-eletu-E7RLgUjjazc-unsplash.jpg';
// import ReactGA from 'react-ga';
import { useNavigate, useLocation, useParams} from 'react-router-dom';

import { getAnalytics, logEvent } from 'firebase/analytics';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBook, faBookBookmark, faMagnifyingGlass, faPencil, faPeopleGroup, faThumbsUp } from '@fortawesome/free-solid-svg-icons';
import {doc, collection, DocumentSnapshot, getDoc, getDocs, QuerySnapshot, getFirestore, setDoc} from 'firebase/firestore';
import {v4 as uuidv4} from 'uuid';
import {db, auth_} from './../../firebase';
import {Helmet} from "react-helmet";
import Popup from '../Popup/Popup';
import {EmailShareButton, EmailIcon, FacebookShareButton, FacebookIcon,  TwitterShareButton,TwitterIcon,  WhatsappShareButton, WhatsappIcon} from "react-share";
import AdsComponent from './../../components/AdComponents';


const Story1 = () =>{
    // ReactGA.initialize('');
    const analytics = getAnalytics();
    const firestore = getFirestore();
    const navigate = useNavigate();

    const {title} = useParams();
    const shareURL = "https://entrepreneurshipbooksapp.com/blogs/"+title

    var blogID = title.substring(title.lastIndexOf("-")+1);
    // console.log("blogID: "+ blogID);



    const documentSnapshot = new DocumentSnapshot();
    const user = auth_.currentUser;


    const [BlogInfo, showBlogInfo] = useState([]);
    const [BlogPostComments, showBlogPostComments] = useState([]);
    const [author, getAuthorInfo] = useState([]);


    const [replier, getReplierInfo] = useState([]);




    const [isOpen, setIsOpen] = useState(false);
    const [isOpenErrorMessage, setIsOpenErrorMessage] = useState(false);
    const [isOpenEmptyMessage, setIsOpenEmptyMessage] = useState(false);
    const [photoURL, setPhotoURL] = useState("https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_1280.png");
    const repliesArray = [];
    const replierArray = [];

    const successNotification = () =>{
        // console.log("success message");
        setIsOpen(!isOpen);   
    }

    const warningNotification = () =>{
        // console.log("warning message");
        setIsOpenErrorMessage(!isOpenErrorMessage);   
    }

    const emptyNotification = () =>{
        // console.log("empty message");
        setIsOpenEmptyMessage(!isOpenEmptyMessage);   
    }

    const onViewReaderProfileButton  = (reader) =>{
     
        navigate('/reader_profile/'+reader.displayName.toLowerCase().replace(/ /g,''),{
        state: {
            id: reader.uid,
            username: reader.displayName,
            email: reader.email
        }
    });
    }

    const onLikeButton =()=>{
        const date = new Date;
        const likeUniqueID = uuidv4();

        if(user){
            setDoc(doc(firestore,"Blogs", blogID, "Likes", user?.uid),{
                date: date,
                displayName: user?.displayName,
                uid: user?.uid,
                email: user?.email,
            });
    
    
            setDoc(doc(firestore,"mail", BlogInfo?.creator_uid + likeUniqueID+user.uid),{
                to: author?.email,
                message: {
                    subject: "You have a new like on your blog!",
                    html:  user?.displayName + " liked on your blog! <code><br/><button><a href='https://entrepreneurshipbooksapp.com/notifications'>View</a></button></code>",
    
                }
            });

        }else{
            navigate("/login");  
        }

    }

    const onSaveBlogButton =()=>{
        const date = new Date;
        if(user){
            setDoc(doc(firestore,"Users", user?.uid, "EBA_Blogs", blogID),{
                dateSaved: date,
                blog_id: blogID
            });     

        }else{
            navigate("/login");
        
        }
        
    }

    const onSubmitReply = async (event) =>{
        event.preventDefault();
        let date = new Date();
        date = date.toString();
        // console.log(location.state.club_name);
        const firestore = getFirestore();
        const commentUniqueID = uuidv4();

        if(user){

            try{

                if(event?.target?.bp_comment?.value===null|| event?.target?.bp_comment?.value.trim()===""){
                    emptyNotification();
                }else{
    
                setDoc(doc(firestore,"Blogs", blogID, "Comments", user?.uid),{
                    date: date,
                    displayName: user?.displayName,
                    uid: user?.uid,
                    email: user?.email,
                    comment: event?.target?.bp_comment.value,
                });
    
                // setDoc(doc(firestore,"Users",BlogInfo?.uid,"Notifications", BlogInfo?.uid + commentUniqueID+user.uid),{
                //     DateandTime: date,
                //     blog_id: blogID,
                //     comment: event?.target?.bp_comment.value,
                //     commenterDisplayName: user?.displayName,
                //     commentID: commentUniqueID,
                //     commenterUID: user?.uid,
                //     type: "bp_comment",
                //     isRead: false
                // });
    
                setDoc(doc(firestore,"mail", BlogInfo?.creator_uid + commentUniqueID+user.uid),{
                    to: author?.email,
                    message: {
                        subject: "You have a new comment on your blog!",
                        html:  user?.displayName + " commented on your blog! <code><br/><button><a href='https://entrepreneurshipbooksapp.com/notifications'>View</a></button></code>",
    
                    }
                });
    
                successNotification();
            }
          
    
            }catch(event){
                console.log("Error Adding Response: ",event );
                warningNotification();
    
            }


            

        }else{
            navigate("/login");
        
        }


    }


    const getBlogInfo= async() =>{

        // console.log(blogID);
        // console.log(await (await getDoc(doc(db, "Blogs",blogID.trim()))).get());

        showBlogInfo( (await getDoc(doc(db, "Blogs",blogID))).data());

       const author = (await getDoc(doc(db, "Blogs",blogID))).data()["creator_uid"];

        getAuthorInfo((await getDoc(doc(db, "Users",author))).data())

    }

    useEffect(()=>{
        getBlogInfo();
        logEvent(analytics, "Blog Post: "+ BlogInfo.title);

    },[])

    return(
        <div style={{width: "50%", margin:"auto", textAlign: "left"}}>
        <AdsComponent  dataAdSlot='3973055866'/>

            {isOpen && 
                    <div>
                    <Popup
                    handleClose = {successNotification}
                    content = {
                    <div>
                        <h2 className="success-popup">Success!</h2>
                        <p>Your comment has been submitted!</p>
                    </div>
                    }
                />
                </div>}
            <Helmet>
                <title>{BlogInfo.title}</title>
                <meta name="description" content={BlogInfo.title}/>
                <meta name="keywords" content="tech, entreprenuership, artificial intelligence, blockchain, remote work, quantum computing, startup"/>
                <meta property="og:title" content={BlogInfo.title}/>
                <meta property="og:description" content={BlogInfo.title}/>
                <meta property="og:url" content="https://www.entrepreneurshipbooksapp.com/"/>
            </Helmet>

            <h1>{BlogInfo.title}</h1>
            <img src={BlogInfo.image} height={300} width={350} style={{display:"flex", margin:"auto", width:"fit-content"}}/>
            <div dangerouslySetInnerHTML={{ __html: BlogInfo.content }} />

            

            

           

             

      
        <button onClick={onLikeButton}><FontAwesomeIcon icon={faThumbsUp} /></button>

          <button onClick={onSaveBlogButton}>Save</button>
          <p>Share:</p>
          <EmailShareButton
           url={shareURL}
           title={BlogInfo.title}>
            <EmailIcon size={32} round={true}/>
            {logEvent(analytics, "web_emailShareBlogPostButton: "+ BlogInfo.title)}
          </EmailShareButton>

          <FacebookShareButton 
          url={shareURL}
          title={BlogInfo.title}>
            <FacebookIcon size={32} round={true}/>
            {logEvent(analytics, "web_facebookShareBlogPostButton: "+ BlogInfo.title)}
          </FacebookShareButton>
       

          <TwitterShareButton 
          url={shareURL}
          title={BlogInfo.title}>
        
            <TwitterIcon size={32} round={true}/>
            {logEvent(analytics, "web_twitterShareBlogPostButton: "+ BlogInfo.title)}
          </TwitterShareButton>

          <WhatsappShareButton
           url={shareURL}
           title={BlogInfo.title}>
            <WhatsappIcon size={32} round={true}/>
            {logEvent(analytics, "web_whatsappShareBlogPostButton: "+ BlogInfo.title)}
          </WhatsappShareButton>

   

            <div >
                <form className="response-format" onSubmit={onSubmitReply}>
                    <textarea className="textarea-format" name="bp_comment" placeholder="Enter comment..."/>
                 <button type="submit">Comment</button>
                </form>
            </div>

            <div>
                           {
                        BlogPostComments?.map((comment, idx)=>(
                            <div className="conversations-card">
                            <div className="user-info-on-conversation-card" onClick={()=>onViewReaderProfileButton(replier[idx])}>
                                {/* <img src={photoURL} className="profilePic"/> */}
                                <img src={replier[idx].photoURL? replier[idx].photoURL : photoURL} alt="ProfilePic" className="profilePic"/>        
                                <h2 >by {replier[idx].displayName}</h2>
                                <h2 key={idx}>Replied on {comment?.dateReplied}</h2>                                

                            </div>
                            <div className="info-on-conversation-card">
                                <h2 key={idx}>{comment?.reply}</h2>
                            </div>

                            </div>
                        ))
                    }

                    </div>
        </div>
    );

}

export default Story1;