import React, {useState} from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBookReader, faCheck} from '@fortawesome/free-solid-svg-icons';
import { useNavigate} from 'react-router-dom';
import {collection, getDoc, setDoc, deleteDoc, doc, DocumentSnapshot, getDocs, QuerySnapshot,  getFirestore} from 'firebase/firestore';
import {db, auth_} from './../firebase';
import { useEffect } from "react";
import {v4 as uuidv4} from 'uuid';
import { connectStorageEmulator } from "firebase/storage";




const Readers = () =>{
    const navigate = useNavigate();
    const documentSnapshot = new DocumentSnapshot();
    const user = auth_.currentUser;

    const searchBar = () =>{};

    const [searchInput, setSearchInput] = useState("");
    const [Users, showReaders] = useState([]);
    const [isFollowingReader, setAsFollowingReader] = useState(false);
    const [photoURL, setPhotoURL] = useState("https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_1280.png");

    const [currentFollower, showFollowers] = useState([]);
    // let currentFollower = [];

    const [options, setOptions] = useState([]);

    const followerArray = [];

   
    const readerArray = [];


  


    const followReader = async (reader, reader_uid, reader_email) =>{
        const firestore = getFirestore();
        const date = new Date;
        const followUniqueID = uuidv4();

        const followingReader = await getDoc(doc(firestore,"Users",reader_uid,"Followers", user.uid));


        // console.log("followingReader:");
        // console.log(followingReader);
        // console.log(followingReader.exist());

        // if(followingReader.exist()){
        if(followingReader.data()){ 
            console.log("Member already is following!");

            setAsFollowingReader(!isFollowingReader);
            deleteDoc(doc(firestore,"Users",reader_uid,"Followers", user.uid));
            deleteDoc(doc(firestore,"Users",user.uid,"Following", reader_uid));

            if(followingReader.followID){
                deleteDoc(doc(firestore,"Users",reader_uid,"Notifications", user.uid + followingReader.followID + reader_uid));
            }

        }else{
            console.log("Member is now following!");
            setAsFollowingReader(!isFollowingReader);

            setDoc(doc(firestore,"Users", user.uid,"Following", reader_uid),{
                DateandTime: date,
                followingDisplayName: reader.displayName,
                followingEmail: reader_email,
                followID: followUniqueID,
                followingUID: reader_uid,
            });

            
            setDoc(doc(firestore,"Users",reader_uid,"Followers", user.uid),{
                DateandTime: date,
                followerDisplayName: user.displayName,
                followerEmail: user.email,
                followID: followUniqueID,
                followerUID: user.uid,
            });

            setDoc(doc(firestore,"Users",reader_uid,"Notifications", user.uid + followUniqueID+reader_uid),{
                DateandTime: date,
                followerDisplayName: user.displayName,
                followerEmail: user.email,
                followID: followUniqueID,
                followerUID: user.uid,
                type: "follow",
                isRead: false
            });

            setDoc(doc(firestore,"mail", user.uid + followUniqueID+reader_uid),{
                to: reader_email,
                message: {
                    subject: "You have a new follower!",
                    html:  user?.displayName + " just started following you! <code><br/><button><a href='https://entrepreneurshipbooksapp.com/notifications'>View</a></button></code>",

                }
            });

        }

    }


    const onViewReaderProfileButton  = (person) =>{
     
        navigate('/reader_profile/'+person.displayName.toLowerCase().replace(/ /g,''),{
        state: {
            id: person.uid,
            username: person.displayName,
            email: person.email
        }});
    }

    const getReaders = async() =>{
        let count = 0;


        await getDocs(collection(db, "Users")).then((querySnapshot)=>{
            const reader = querySnapshot.docs.filter(doc => doc.data().displayName!=="" && doc.data().uid).map((doc)=>({
                ...doc.data()
            }));

            // if(reader.name!==""){
            //     showReaders(reader); 
            //     // console.log(Users,reader)
            // }

            reader.map(async (x)=>{
                readerArray.push(x);
                showReaders(readerArray);
                // followerArray.push(false);

                // count = count+1;
                // console.log(count)

                const userFollowingInfo =  await getDoc(doc(db, "Users", user.uid, "Following", x.uid));

                // console.log(x);
                // console.log(userFollowingInfo.data());
                
                if(userFollowingInfo.data()){
                    // console.log(true);

                    // count = count+1;
                    // console.log(count)


                // followerArray.push(!isFollowingReader);
                followerArray.push(true);
                showFollowers(followerArray);
                }
                
                else{
                    followerArray.push(false);
                    showFollowers(followerArray);

                    // count = count+1;
                    // console.log(count)

                    // console.log(false);
                // followerArray.push(isFollowingReader);
                
                
                // followerArray.push(false);
                // showFollowers(followerArray);
                }
                
               
                // showFollowers(followerArray);

                // console.log(followerArray.length)
                // console.log(followerArray)


                // currentFollower = followerArray;

            
            },

            );

            

        });   


    }

    useEffect( ()=>{
        getReaders();

        // const followingReader = await getDoc(doc(db,"Users","Pdd2SeIdebbvPSORSIIkcVPmExg1","Followers", user.uid));


        // console.log("followingReader:");
        // console.log(followingReader);
        // console.log(followingReader.exists);
    },[])

    const handleChange = (e) =>{
        e.preventDefault();
        setSearchInput(e.target.value);
        // console.log(e.target.value);
    }

    // if(searchInput.length>0){
    //     console.log("h: "+searchInput);

       
    //         return <div className="card-container">
    //                 {
    //                     Users?.filter(user => user.displayName!=="a").map((user)=>(
    //                         <div className="card">
    //                         <p><FontAwesomeIcon icon={faBook} size ="2xl"/></p>
    //                         {/* <h2 >{user.displayName}</h2> */}
    //                         <button>Follow</button>
    //                         <button>View Profile</button>
    //                         </div>

    //                     ))
    //                 }
    //                 </div> 
        
    //         // Users?.filter(user => user.displayName!=="").map((user) =>{
    //         //     return user.displayName.match(searchInput);
    //         // });
    // }

    return (
        <div>
            {/* <h1>[SEARCH READER INPUT]</h1>
             */}

             <input
             className="search_input" 
             type="search"
             placeholder="Search Reader"
             onChange={handleChange}
             value={searchInput}/>


        <h2>Suggested Readers</h2>
        <div className="card-container">
             {Users?.filter(reader=>{
                if(!searchInput) return true // set false if you do not want any reader to show before seaching
                if(reader.displayName && (reader.displayName.includes(searchInput)||reader.email.includes(searchInput))){
                    return true
                }

             }).map((reader,idx)=>(
                <div className="card">
                <img src={reader.photoURL? reader.photoURL : photoURL} alt="ProfilePic" className="profilePic"/>

                {/* <p><FontAwesomeIcon icon={faBookReader} size ="2xl"/></p> */}
                <h2 key={idx}>{reader.displayName}</h2>
                {/* <h3>{isFollowingReader}</h3> */}
                {/* <h4>{idx}</h4>
                <h3>{currentFollower.length}</h3>
                <h3>{Users.length}</h3>
                <h3>{followerArray.length}</h3>
                <h3>{currentFollower[idx] + ''}</h3>
                <h2>{currentFollower[idx]?<p>yes</p>:<p>no</p>}</h2> */}
                {currentFollower[idx]?<button onClick={()=>followReader(reader, reader.uid, reader.email)} className="button_followed_reader">Following <FontAwesomeIcon icon={faCheck}/></button>
                :<button onClick={()=>followReader(reader, reader.uid, reader.email)}>Follow</button>} 
                
                 <button onClick={() => onViewReaderProfileButton(reader)} >View Profile</button>
                </div>

             ))}
             </div>

            {/* <button>Search</button> */}

            

            {/* <p>[LIST OF READERS WITH GREATER THAN 3 BOOKS]</p> */}

        


        </div>

    );
};
export default Readers;