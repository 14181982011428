import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuestion } from '@fortawesome/free-solid-svg-icons';
import { useNavigate, useLocation} from 'react-router-dom';
import {doc, collection,  DocumentSnapshot, getDoc, getDocs, QuerySnapshot, getFirestore, setDoc} from 'firebase/firestore';
import {db, auth_} from './../../../firebase';
import { useEffect, useState } from "react";
import Popup from "../../../components/Popup/Popup";
import {v4 as uuidv4} from 'uuid';


const BookClubDiscussionSections = () =>{

    const navigate = useNavigate();
    const location = useLocation();
    const documentSnapshot = new DocumentSnapshot();
    const user = auth_.currentUser;
    const firestore = getFirestore();

    const [DiscussionBoardQuestionResponses, showDiscussionBoardSectionResponses] = useState([]);

    const [isOpen, setIsOpen] = useState(false);
    const [isOpenErrorMessage, setIsOpenErrorMessage] = useState(false);
    const [isOpenEmptyMessage, setIsOpenEmptyMessage] = useState(false);
    const [isOpenDuplicateMessage, setIsOpenDuplicateMessage] = useState(false);
    const [isPressedCreateNewSection, setNewSectionFromPressed] = useState(false);
    const [discussionSectionName, setDiscussionSectionName] = useState("");

    const [DiscussionSubSections, showDiscussionSubSections] = useState([]);

    const [BookClubReplies, showBookClubReplies] = useState([]);
    const [photoURL, setPhotoURL] = useState("https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_1280.png");


    const [responder, getResponderInfo] = useState([]);   

    const [DBSubSectionDescription, saveBKSubSectionDescription] = useState("");

    const responseArray = [];
    const responderArray = [];

    const successNotification = () =>{
        // console.log("success message");
        setIsOpen(!isOpen);   
    }

    const warningNotification = () =>{
        // console.log("warning message");
        setIsOpenErrorMessage(!isOpenErrorMessage);   
    }

    const duplicateNotification = () =>{
        // console.log("duplicate message");
        setIsOpenDuplicateMessage(!isOpenDuplicateMessage);   
    }

    const emptyNotification = () =>{
        // console.log("empty message");
        setIsOpenEmptyMessage(!isOpenEmptyMessage);   
    }
    const onSubmitButton  = () =>{
        navigate('/discussionboard/'+location?.state?.id);
    }


    // location.state.id
    // const getDiscussionBoardQuestion = async() =>{
    //     const question= await getDoc(doc(firestore, "DiscussionBoards",location.state.club_name, "Questions",location.state.user_question));
    //     showDiscussionBoardQuestion(question.data());
    // }


    const getBookClubResponses = async() =>{

        const bookClubResponses = await getDocs(collection(db, "BookClubs",location?.state?.club_name, "BookClubDiscussionSections",location?.state?.section,"Discussions"));
        const responses = bookClubResponses.docs;

        responses.forEach( async(response) => {
            const responseAuthor = await getDoc(doc(db, "Users", response.data().replier_uid));

            responseArray.push(response.data());
            showDiscussionBoardSectionResponses(responseArray);

            responderArray.push(responseAuthor.data());
            getResponderInfo(responderArray);
          });
         
        } 


    // const getDiscussionBoardSectionResponses = async() =>{
    //    await getDocs(collection(db, "BookClubs",location?.state?.club_name, "BookClubDiscussionSections","Responses")).then((querySnapshot)=>{
    //         const responses = querySnapshot.docs.map((doc)=>({
    //             ...doc.data()
    //         }));

    //         showDiscussionBoardSectionResponses(responses);

    //     });  
        
    //    }
   

        

    const onSubmitReply = async (event) =>{
        event.preventDefault();
        let date = new Date();
        date = date.toString();
        // console.log(location.state.club_name);
        const firestore = getFirestore();
        const bookClubSectionReplyUniqueID = uuidv4();


        try{

            if(event?.target?.db_reply?.value===null|| event?.target?.db_reply?.value.trim()===""){
                emptyNotification();
            }else{

            setDoc(doc(firestore,"BookClubs",location?.state?.club_name, "BookClubDiscussionSections", location?.state?.section, "Discussions", date),{
                dateReplied: date,
                replier: user?.displayName,
                replier_uid: user?.uid,
                reply: event?.target?.db_reply?.value,
            });

            setDoc(doc(firestore,"Users",location?.state?.club_name_creator_uid,"Notifications", location?.state?.club_name_creator_uid + bookClubSectionReplyUniqueID+user.uid),{
                DateandTime: date,
                bookclubname: location?.state?.club_name,
                bookclubsectionname: location?.state?.section,
                replierDisplayName: user?.displayName,
                bookClubSectionReplyID: bookClubSectionReplyUniqueID,
                responderUID: user?.uid,
                type: "bookclub_section_reply",
                isRead: false
            });



            setDoc(doc(firestore,"mail", location?.state?.club_name_creator_uid + bookClubSectionReplyUniqueID+user.uid),{
                to: location?.state?.club_name_creator_email,
                message: {
                    subject: "You have a new message!",
                    html:  user?.displayName + " added a new comment in your book club "+ location?.state?.club_name + " in " + location?.state?.section +" <code><br/><button><a href='https://entrepreneurshipbooksapp.com/notifications'>View</a></button></code>",

                }
            });

            successNotification();
        }
      

        }catch(event){
            console.log("Error Adding Response: ",event );
            warningNotification();

        }


    }


    // const getBookClubResponses = async() =>{
    //     await getDocs(collection(db, "BookClubs",location?.state?.club_name, "Replies", "v")).then((querySnapshot)=>{
    //          const replies = querySnapshot.docs.map((doc)=>({
    //              ...doc.data()
    //          }));
 
    //          showBookClubReplies(replies);
 
    //      });  
         
    //     }
    
        const onSubmitCommentButton = async (event) =>{
            event.preventDefault();
            let date = new Date();
            date = date.toString();
            // console.log(location.state.club_name);
            const firestore = getFirestore();
    
            try{
    
                if(event?.target?.db_reply?.value===null || event?.target?.db_reply?.value.trim()===""){
                    emptyNotification();
                }else{
    
                setDoc(doc(firestore,"BookClubs",location?.state?.club_name, "Replies", date),{
                    replier: user?.displayName,
                    reply: event?.target?.bc_comment?.value,
                    dateReplied: date,
                    replier_uid: user?.uid,
                    
                });
    
                successNotification();
            }
          
    
            }catch(event){
                console.log("Error Adding Response: ",event );
                warningNotification();
    
            }
    
    
        }
    
    const onPressedCreateNewSectionButton = () =>{
        setNewSectionFromPressed(!isPressedCreateNewSection)

    }

    const createDiscussionSection = async(event) =>{
        event.preventDefault();
        // console.log(discussionSectionName);
        let date = new Date();
        date = date.toString();
        const bookClubNewSectionUniqueID = uuidv4();

        try{

            const firestore = getFirestore();
            const section = await getDoc(doc(firestore,"BookClubs",location?.state?.club_name, "BookClubDiscussionSections",location.state.section,"SubSections",discussionSectionName));
            // console.log(name);
            if(discussionSectionName===null|| discussionSectionName.trim()===""|| DBSubSectionDescription.trim()){
                emptyNotification();
            }else if(section.exists()){
                // console.log(name);
                duplicateNotification();
            }else{
                setDoc(doc(firestore,"BookClubs",location?.state?.club_name,"BookClubDiscussionSections",location?.state?.section,"SubSections", discussionSectionName),{
                    discussionSubSectionName: discussionSectionName,
                    discussionSubSectionNameID: discussionSectionName.replace(/ /g,"").toLowerCase(),
                    creator: user?.displayName,
                    creator_uid: user?.uid,
                    dateCreated: date,
                    description: DBSubSectionDescription
                });

                setDoc(doc(firestore,"Users",location?.state?.club_name_creator_uid,"Notifications", location?.state?.club_name_creator_uid+ bookClubNewSectionUniqueID+user.uid),{
                    DateandTime: date,
                    bookclubname: location?.state?.club_name,
                    newSectionCreated: discussionSectionName,
                    newSectionBookClubID: bookClubNewSectionUniqueID,
                    sectionCreatorDisplayName: user?.displayName,
                    sectionCreatorUID: user?.uid,
                    type: "bookclub_subsection_added",
                    isRead: false
                });


                setDoc(doc(firestore,"mail", location?.state?.club_name_creator_uid+ bookClubNewSectionUniqueID+user.uid),{
                    to: location?.state?.club_name_creator_email,
                    message: {
                        subject: "A new sub section was created in your book club!",
                        html:  user?.displayName + " added a new sub section called "+ discussionSectionName + " in your book club " +location?.state?.club_name  +" <code><br/><button><a href='https://entrepreneurshipbooksapp.com/notifications'>View</a></button></code>",
    
                    }
                });
                // console.log("Document Written with ID: ",dashboard.id )
                successNotification();

            }
  
        }catch(event){
            warningNotification();
            console.log("Error Adding Document: ",event );

        }

    }

    const getDiscussionSubSections = async() =>{
        await getDocs(collection(db, "BookClubs",location?.state?.club_name, "BookClubDiscussionSections", location.state.section,"SubSections")).then((querySnapshot)=>{
            const discussion_sub_sections = querySnapshot.docs.map((doc)=>({
                ...doc.data()
            }));
            showDiscussionSubSections(discussion_sub_sections);
        });   
    }

    const clickDiscussionSection = (sub_section_name) =>{
        navigate('/'+location?.state?.club_name+'/'+location?.state?.section+'/'+sub_section_name,{
            state:{
                club_name: location?.state?.club_name,
                section: location?.state?.section,
                sub_section: sub_section_name,
                club_name_creator_email: location?.state?.club_name_creator_email,
                club_name_creator_uid: location?.state?.club_name_creator_uid


            }
        })

    }

    const onViewReaderProfileButton  = (reader) =>{
     
        navigate('/reader_profile/'+reader.displayName.toLowerCase().replace(/ /g,''),{
        state: {
            id: reader.uid,
            username: reader.displayName,
            email: reader.email
        }
    });
    }

    useEffect(()=>{
        getBookClubResponses();
        getDiscussionSubSections();
        // getDiscussionBoardSectionResponses();
    },[])    

    return (

        <div>
            <h1>{location?.state?.section} discussions</h1>

                {/* if popup is open dont open */}
                {isOpen && 
                    <div>
                    <Popup
                    handleClose = {successNotification}
                    content = {
                    <div>
                        <h2 className="success-popup">Congratulations!</h2>
                        <p>Your response has been submitted!</p>
                    </div>
                    }
                />
                </div>}

                {isOpenErrorMessage&&<Popup
                  handleClose = {warningNotification}
                content = {
                <div>
                    <h2 className="error-popup">Error</h2>
                    <p>There was an issue submitting your response!</p>
                </div>
                }
            />}

            {isOpenEmptyMessage&&<Popup
                  handleClose = {emptyNotification}
                content = {
                <div>
                    <h2 className="error-popup">Error</h2>
                    <p>Please enter a response!</p>
                </div>
                }
            />} 

            {isPressedCreateNewSection && 
                    <div>
                    <Popup
                    handleClose = {onPressedCreateNewSectionButton}
                    content = {
                    <div>
                        <form onSubmit={createDiscussionSection}>
                        <input
                                class = "input_register"
                                id = "discussion-board-sub-section"
                                name = "discussionsubsection"
                                type = "text"
                                required
                                placeholder='Discussion Board Section'
                                onChange={(e)=>setDiscussionSectionName(e?.target?.value)}
                            />

                        <label  className="prof_bg_inputs">Book Club New Section Description</label>
                        <textarea
                                            rows={5} 
                                            cols={35}
                                            type="text"
                                            value={DBSubSectionDescription}
                                            onChange = {e => saveBKSubSectionDescription(e.target.value)}
                                            className="prof_bg_inputs"></textarea>
                                        <br/>
                            <button type="submit">Create Section</button>
                        </form>
                        
                    </div>
                    }
                />
                </div>}

        <button onClick={()=>onPressedCreateNewSectionButton()} >Create New Section</button>

                    <div className="horizontal-line"></div>

                    <h2>{location.state.section} Sub Discussions</h2>
                   
                   {
                DiscussionSubSections?.map((section)=>(
                    <div onClick={()=>clickDiscussionSection(section?.discussionSubSectionName)} className="discussion_section">
                    {/* <p><FontAwesomeIcon icon={faQuestion} size ="2xl"/></p> */}
                    <h2 >{section?.discussionSubSectionName}</h2>
                    </div>
                ))
            }


                    <div className="horizontal-line"></div>

                    <h2>General Discussion</h2>


                    <div >
                        <form className="response-format" onSubmit={onSubmitReply}>
                        <textarea className="textarea-format" name="db_reply" placeholder="Enter response..."/>
                        <button type="submit">Submit Reply</button>
                        </form>
                    </div>

                    <div>
                           {
                        DiscussionBoardQuestionResponses?.map((responses, idx)=>(
                            <div className="conversations-card">
                            <div className="user-info-on-conversation-card" onClick={()=>onViewReaderProfileButton(responder[idx])}>
                                <img src={responder[idx].photoURL? responder[idx].photoURL : photoURL} alt="ProfilePic" className="profilePic"/>        
                                <h2 >by {responder[idx].displayName}</h2>
                                <h2 key={idx}>Replied on {responses?.dateReplied}</h2>
                            </div>
                            <div className="info-on-conversation-card">
                                <h2 key={idx}>{responses?.reply}</h2>
                            </div>                           
                            
                            {/* <img src={photoURL} className="profilePic"/>
                            <h2 >by {responses?.replier}</h2> */}
                            
                            </div>
                        ))
                    }

                    </div>                    

        </div>
    );

}

export default BookClubDiscussionSections;