import styled, { withTheme } from 'styled-components';



export const Box = styled.div `
    margin-top: 20px;
    background: #607d8b;
    padding: 80px 60px;
    // position: absolute;
    bottom: 0;
    margin-bottom: 0;


    @media screen and (max-width: 650px){
        padding: 70px 30px;
 
    }
`;

export const Container = styled.div `
    display: flex;
    flex-direction: column;
    justify-content: center;
    // max-width: 1000px;
    // margin-bottom: 0;
    bottom: 0;
    // margin: 0 auto;
`;

export const Column = styled.div `
    display: flex;
    flex-direction: column;
    text-align: left;
    margin-left: 60px;
`;

export const Row = styled.div `
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(185px, 1fr));
    grid-gap: 20px;
    @media (max-width: 1000px){
        grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));;
    }
`;

export const FooterLink = styled.a `
    color: #ffffff;
    margin-bottom: 20px;
    font-size: 18px;
    text-decoration: none;
    &:hover{
        color: #b0bec5;
        transition: 200ms ease-in;
    }
`;


export const Heading = styled.p `
    font-size: 24px;
    color: #ffffff;
    margin-bottom: 40px;
    font-weight: bold;
    }
`;