import React, { useState,useEffect } from "react";
import { useNavigate, useParams, useLocation} from 'react-router-dom';
import { auth_, db } from "../firebase";
import {collection, setDoc, doc, deleteDoc, getFirestore, getDoc, DocumentSnapshot, getDocs, QuerySnapshot, updateDoc} from 'firebase/firestore';



const Notifications = () =>{

    let location = useLocation();
    let [notifications, setNotifications] = useState([]);
    const [photoURL, setPhotoURL] = useState("https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_1280.png");
    const [userInform, getUserInfo] = useState([]);

    const user = auth_.currentUser;

    const notificationArray = [];
    const userArray = [];


    const fetchNotifications = async () =>{
        const getNotifications = await getDocs(collection(db, "Users", user.uid, "Notifications"));
        const notifications = getNotifications.docs;


        notifications.forEach( async(notification) => {
            // console.log("notifications");
            // console.log(notification.data());
            // console.log(notification.data().commentCreatorUID ||
            // notification?.data()?.sectionCreatorUID||
            // notification?.data()?.responderUID||
            // notification?.data()?.followerUID);
            // const userInfo = null;
            // const userInfo = ([]);
            const userInfo = await getDoc(doc(db, "Users", notification?.data()?.commentCreatorUID ||
            notification?.data()?.sectionCreatorUID||
            notification?.data()?.responderUID||
            notification?.data()?.followerUID  ||
            notification?.data()?.newMemberrUID));


            // if(notification.data().commentCreatorUID){
            //      userInfo = await getDoc(doc(db, "Users", notification.data().commentCreatorUID));
            
            // }else if(notification.data().responderBookClubID){
            //      userInfo = await getDoc(doc(db, "Users", notification.data().responderBookClubID));
            // }else if(notification.data().sectionCreatorUID){
            //      userInfo = await getDoc(doc(db, "Users", notification.data().sectionCreatorUID));
            // }else if(notification.data().responderUID){
            //      userInfo = await getDoc(doc(db, "Users", notification.data().responderUID));
            // }else if(notification.data().followerUID){
            //      userInfo = await getDoc(doc(db, "Users", notification.data().followerUID));
            // }

            // console.log(userInfo.data());
            // console.log("-------------");

            // console.log(notification.data());


            updateDoc(notification.ref,{
                isRead: true
            });

        
            notificationArray.push(notification.data());
            setNotifications(notificationArray);

            userArray.push(userInfo.data());
            getUserInfo(userArray);
          });

    //     await getDocs(collection(db, "Users", user.uid, "Notifications")).then((querySnapshot)=>{
    //       let messages = querySnapshot.docs.map((doc)=>({
    //           ...doc.data()
    //       }));
    //         setNotifications(messages); 
    //         console.log(messages);

    // });  
}

useEffect(()=>{
    fetchNotifications();

},[]);

    // if(!location?.state?.notificationsRead){
    //     console.log("hey")
    //     console.log(location?.state?.notificationsRead);
    //     return <h1>No notifications yet..</h1>

    // }else{
        return (
            <div>
                {/* <p>great</p> */}
                <div>
                     {notifications.filter(notification => notification.isRead===false).map(
                        (notification, idx) => <div>
                             {notification.type==="follow"? (
                                <div>
                                <img src={userInform[idx].photoURL? userInform[idx].photoURL : photoURL} alt="ProfilePic" className="profilePic"/>        
                                <p>{userInform[idx].displayName} started following you!</p></div>
                                ):
                                ""}
                                
                                {notification.type==="bookclub_member_added"? (
                                    <div>
                                    <img src={userInform[idx].photoURL? userInform[idx].photoURL : photoURL} alt="ProfilePic" className="profilePic"/>        
                                    
                                    <p >{userInform[idx].displayName}  joined your book club {notification.bookclubname}</p></div>
                                    ):""}
                                
                                {notification.type==="bookclub_section_added"? (
                                    <div>
                                    <img src={userInform[idx].photoURL? userInform[idx].photoURL : photoURL} alt="ProfilePic" className="profilePic"/>        
                                    
                                    <p>{userInform[idx].displayName}  added a new section called {notification.sectionName} to your book club {notification.bookclubname}</p></div>
                                    ):""}
                                   {notification.type==="bookclub_section_reply"? (
                                        <div>
                                        <img src={userInform[idx].photoURL? userInform[idx].photoURL : photoURL} alt="ProfilePic" className="profilePic"/>        
                                        <p >{userInform[idx].displayName} added a new comment in your book club {notification.bookclubname} in {notification.bookclubsectionname}</p></div>
                                        ):""}
                                    {notification.type==="bookclub_comment_added"? (
                                        <div>
                                        <img src={userInform[idx].photoURL? userInform[idx].photoURL : photoURL} alt="ProfilePic" className="profilePic"/>        
                                        <p >{userInform[idx].displayName} added a new comment in your book club {notification.bookclubname}</p></div>
                                        ):""}                                        
                                    {notification.type==="bookclub_subsection_added"? (
                                            <div>
                                            <img src={userInform[idx].photoURL? userInform[idx].photoURL : photoURL} alt="ProfilePic" className="profilePic"/>        
                                            <p >{userInform[idx].displayName}  added a new sub section called {notification.newSectionCreated} in your book club {notification.bookclubname}</p></div>
                                            ):""}

                                        {notification.type==="bookclub_subsection_reply"? (
                                            <div>
                                            <img src={userInform[idx].photoURL? userInform[idx].photoURL : photoURL} alt="ProfilePic" className="profilePic"/>       
                                            <p >{userInform[idx].displayName} added a new comment in your book club {notification.bookclubname} in sub section {notification.bookclubsubsectionname}</p></div>
                                            ):""} 


                                        {notification.type==="discussionboard_reply"? (
                                            <div>
                                             <img src={userInform[idx].photoURL? userInform[idx].photoURL : photoURL} alt="ProfilePic" className="profilePic"/>        
                                            <p >{userInform[idx].displayName} responded to your question: {notification.question} in {notification.discussionBoardName}</p></div>
                                            ):""}   
                                            <div className="horizontal-line"></div>  
                                 </div>
                    )} 



               </div>
               </div>
         

        );
}
// }

export default Notifications;