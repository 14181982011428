import React, {useState} from "react";
import { useEffect } from "react";
import { useNavigate} from 'react-router-dom';

import Papa from "papaparse";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBook, faMagnifyingGlass, faPencil, faPeopleGroup } from '@fortawesome/free-solid-svg-icons';
import AdsComponent from './../../components/AdComponents';
import ListCard from "../../components/ListView/ListCard";


const BusinessStrategyCategoryScreen = () =>{

    const [csvData, parsedCsvData] = useState([]);
    const navigate = useNavigate();

    const onWishlistButton  = () =>{
 
        navigate('/disover');

    }

    const onBookDetailsButton  = (title, category, image, amazon_link, apple_link, bookshop_link) =>{

        navigate('/book_details/'+title,{
            state: {
                book_title: title,
                book_category: category,
                book_image: image,
                amazon: amazon_link,
                apple: apple_link,
                bookshop: bookshop_link,
            }
        });
    }    

    var file = require("./../../books/StrategyBooks.csv")
    const parseFile = () =>{
        Papa.parse(file, {
            download: true,
            header: false,
            complete: results =>{
                // console.log(results.data)
                parsedCsvData(results.data)
            },
        });

    }

    useEffect(()=>{
        parseFile();
    },[])

    return (
        <main>
            <div>
            <AdsComponent dataAdSlot='8809066687'/>
                <h1>Books On Business Strategy</h1>
                {/* <div  className="card-container">
                        {
                            csvData?.map((data,idx)=>(
                                <div onClick ={()=>onBookDetailsButton(data[0], data[4],data[2],data[3],data[5], data[6])} className="card">
                                <br/>
                                {data[2]? <img src={data[2]} height={100} width = {50}/> :
                                <p><FontAwesomeIcon icon={faBook} size ="2xl"/></p>}
                                <p>{data[0]}</p>
                                {data[1]? "by " + data[1] : ""}
                                <button onClick={()=>onBookDetailsButton(data[0], data[4],data[2],data[3],data[5], data[6])}>View</button>
                                </div>
    
                            ))
                            
                        }


                    </div> */}


                {/* <div  className="card-container"> */}
                        {
                            csvData?.map((data,idx)=>(
                                <div onClick ={()=>onBookDetailsButton(data[0], data[4],data[2],data[3],data[5], data[6])}>
                                <ListCard  image={data[2]? data[2]:""} 
                                title= {data[0]}/>
                                </div>
                                //  <img src={data[2]? data[2]: ""} className="img-book" onClick ={()=>onBookDetailsButton(data[0], data[4],data[2],data[3],data[5], data[6])}/> 
    
                            ))
                            
                        }


                    {/* </div> */}

            </div>
        </main>
    );
};

export default BusinessStrategyCategoryScreen;