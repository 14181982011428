import React, {useState} from "react";
import { useEffect } from "react";
import { useNavigate, useLocation} from 'react-router-dom';

import Papa from "papaparse";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBook, faMagnifyingGlass, faPencil, faPeopleGroup } from '@fortawesome/free-solid-svg-icons';

const BookClubLibraryList = () =>{

    const [csvData, parsedCsvData] = useState([]);
    const navigate = useNavigate();
    const location = useLocation();


    const onBookDetailsButton  = (title, category, image, amazon_link, apple_link, bookshop_link) =>{

        navigate('/book_club_books_details/'+title,{
            state: {
                club: location.state.club_name,
                book_title: title,
                book_category: category,
                book_image: image,
                amazon: amazon_link,
                apple: apple_link,
                bookshop: bookshop_link,
            }
        });
    }

    var file = require("./../../books/bookDatabase100_General.csv")
    const parseFile = () =>{
        Papa.parse(file, {
            download: true,
            header: false,
            complete: results =>{
                // console.log(results.data)
                parsedCsvData(results?.data)
            },
        });

    }

    useEffect(()=>{
        parseFile();
    },[])

    return (
        <main>
            <div>
                <h1>Books Club Library List</h1>
                <p>Pick books from this list to add to book clubs</p>
                <div className="card-container">
                        {
                            csvData?.map((data,idx)=>(
                                <div onClick ={()=>onBookDetailsButton(data[0],data[4],data[2],data[3],data[6],data[7])} className="card">
                                    <br/>
                                    {data[2]? <img src={data[2]} height={100} width = {50}/> :
                                    <p><FontAwesomeIcon icon={faBook} size ="2xl"/></p>}
                                    <p>{data[0]}</p>
                                    {data[1]? "by " + data[1] : ""}
                                    <button onClick ={()=>onBookDetailsButton(data[0],data[4],data[2],data[3],data[6],data[7])}>View</button>
                                </div>
                            ))                   
                        }
                    </div>
            </div>
        </main>
    );
};

export default BookClubLibraryList;