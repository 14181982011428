import React from 'react';

import ChatBox from './components/ChatBox'

const InboxMessages = ()=>{

    return(
        <div>
            <ChatBox />
        </div>

    );
};

export default InboxMessages;