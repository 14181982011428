import { useNavigate, useLocation} from 'react-router-dom';
import React, {useState, useEffect} from "react";
import {collection, deleteDoc, DocumentSnapshot, getFirestore, getDoc, setDoc, getDocs, doc} from 'firebase/firestore';
import {db, auth_} from './../../firebase';


const BookClubMemberList = () =>{

    const [photoURL, setPhotoURL] = useState("https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_1280.png");
    const [Members, showMembers] = useState([]);

    const location = useLocation();
    const navigate = useNavigate();

    const memberArray = [];


    const getMembers = async() =>{

        const bookClubMembers = await getDocs(collection(db, "BookClubs", location?.state?.club_name, "Members"));
         const members = bookClubMembers.docs;

         members.forEach( async(member) => {
            const userInfo =  await getDoc(doc(db, "Users", member.data().followerUID));

            memberArray.push(userInfo.data());
            showMembers(memberArray);
          });
      
    }


    const onViewReaderProfileButton  = (user) =>{
     
        navigate('/reader_profile/'+user.displayName.toLowerCase().replace(/ /g,""),{
        state: {
            id: user.uid,
            username: user.displayName
        }
    });
    }

    useEffect(()=>{
        getMembers();
        
    },[])

    return (
        <div className="following_follower_list">
                 {
        Members?.filter(followers => followers.displayName!=="").map((followers,idx)=>( 
            <div onClick={() => onViewReaderProfileButton(followers)} className="following_follower_info"> 
                <img src={followers.photoURL? followers.photoURL : photoURL} alt="ProfilePic" className="profilePic"/>        
                <h2 key={idx}> {followers.displayName}</h2> 
                <div className='horizontal-line'></div>
            </div> 

        ))    

     }

    </div>
    );
}

export default BookClubMemberList;