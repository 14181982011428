import React, {useState} from "react";
import { useNavigate} from 'react-router-dom';
import {collection, DocumentSnapshot, getDocs, QuerySnapshot} from 'firebase/firestore';
import {db, auth} from './../../firebase';
import { FirebaseError } from "firebase/app";
import FlatList from 'flatlist-react';
import { useEffect } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBookReader} from '@fortawesome/free-solid-svg-icons';



const MyBooksScreen = () =>{
    const navigate = useNavigate();



    const onFinishedReadingButton  = () =>{
 
        navigate('/finishedreading');
    
    }

    const onCurrentlyReadingButton  = () =>{
 
        navigate('/currentlyreading');
    
    } 
    
    const onWantToReadButton  = () =>{
 
        navigate('/wantotread');
    
    }

    const onWishlistButton  = () =>{
 
        navigate('/wishlist');

    }

    const onFavoritesButton  = () =>{
 
        navigate('/favorites');
    
    } 
        
    return (
        <main>
                <div>
                    <h2>Your Library</h2>

                    <button class="book-categories" onClick={onFinishedReadingButton}>
                        Finished Reading
                    </button>
                    <button class="book-categories" onClick={onCurrentlyReadingButton}>
                        Currently Reading
                    </button>
                    <button class="book-categories" onClick={onWantToReadButton}>
                        Want To Read
                    </button>
                    <button class="book-categories" onClick={onWishlistButton}>
                        Wishlist
                    </button>   
                    <button class="book-categories" onClick={onFavoritesButton}>
                        Favorites
                    </button>                                     
                </div>
     
        </main>


    );
};

export default MyBooksScreen;