import React from 'react';
// import Navbar from './components/Navbar';
import {HeroContent,
        HeroContainer,
        HeroItems,
        HeroH1,
        HeroP,
        HeroButton} from './HeroElements';




const Hero = () => {
    return(
        <HeroContainer>
            <HeroContent>

                <HeroItems>
                    {/* <HeroP>Find your next book to help you grow financially and professionally.</HeroP> */}
                    <HeroP>Connect With Entrepreneurs. We provide educational resources and community to help you start a business.</HeroP>

                    <a href="/login"><HeroButton>Login</HeroButton></a>
                    <a href="/register"><HeroButton>Sign Up</HeroButton></a>
                </HeroItems>
            </HeroContent>
        </HeroContainer>
    );

}

export default Hero;
