import React, {useState,useEffect} from "react";
import {doc, setDoc, collection, getDoc, DocumentSnapshot, getDocs, query, getCountFromServer, getFirestore, updateDoc, where} from 'firebase/firestore';
import {auth_, uploadProfilePic, db} from './../firebase';
import {getAuth, onAuthStateChanged, updateProfile, signOut} from "firebase/auth";
import { FirebaseError } from "firebase/app";
import { useNavigate} from 'react-router-dom';
import Popup from "../components/Popup/Popup";

const AccountSettings = () =>{

    const [aboutText, saveAboutText] = useState("");
    const [profBackgroundText, saveProfBackgroundText] = useState("");
    const [companyDescriptionText, saveCompanyDescriptionText] = useState("");


    const [isDuplicate, setDuplicateMessage] = useState(false);
    const [isErrorMessage, setErrorMessage] = useState(false);
    const [isSuccess, setSuccess] = useState(false);
    const [isWhiteSpace, checkSpaces] = useState(false);
    const [isEmpty, checkEmpty] = useState(false);



    const navigate = useNavigate();

    const onFinishedBookButton  = () =>{
 
        navigate('/finishedreading');

    }


    const duplicateNotification = () =>{
        setDuplicateMessage(!isDuplicate);   
    }
    
    const errorNotification = () =>{
        setErrorMessage(!isErrorMessage);   
    }

    const whiteSpaceNotification = () =>{
        checkSpaces(!isWhiteSpace);   
    }

    const successNotification = () =>{
        setSuccess(!isSuccess);   
    }

    const emptyNotification = () =>{
        checkEmpty(!isEmpty);   
    }

    const [user, setUser] = useState("");
    const [photo, setPhoto] = useState(null);
    const [loading, setLoading] = useState(false);
    const [photoURL, setPhotoURL] = useState("https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_1280.png");

    const [userName, saveUsername] = useState("");

    const getUniqueName = async(uniqueName) =>{

        const usernames = await getDocs(query(collection(db, "Users"), where("displayName", "==", uniqueName)));
        // const usernames = findName.docs;
        // console.log("USERNAME");
        // console.log(usernames.docs.length>0);
        return usernames.docs.length>0
      
    }

    const updateUsername = async(e)=>{
        e.preventDefault();
        const firestore = getFirestore();

        if(userName.trim()===""){
            emptyNotification();

        }else if(/\s/g.test(userName)){
            whiteSpaceNotification();

        }else if(await getUniqueName(userName.toLowerCase().replace(/ /g,""))){
            duplicateNotification();

        }else{
            try {

            
                updateDoc(doc(firestore,"Users",user.uid),{
                    displayName: userName.toLowerCase().replace(/ /g,"")
                });

                updateProfile(user, {displayName: userName.toLowerCase().replace(/ /g,"")});

                successNotification();
            
            }catch(e){
                errorNotification();

            }

        }

    }

    const getUsers = async() =>{
        const auth = getAuth();
        onAuthStateChanged(auth, (user)=>{
            if(user){
                const userInfo = auth_.currentUser;
                // console.log("user:")
                // console.log(user.displayName);
                // const email =  user.email;
                // const displayName =  user.displayName;
                // const uid = user.uid;
                setUser(userInfo)
                if(userInfo?.photoURL){
                    setPhotoURL(userInfo.photoURL);
                }
                
                
    
            }
        });
    }

    const logout = async(user)=>{
        try{
            await signOut(user);
            navigate('/');
            window.location.reload();
        }catch(err){
            console.error(err);
        }
    }

    const aboutResponse = async (e)=>{
        e.preventDefault();
        const firestore = getFirestore();
        const date = new Date;
        console.log(aboutText);

        if(aboutText.trim()==""){
            emptyNotification();

        }else{

        try {

        
            updateDoc(doc(firestore,"Users",user.uid),{
                about: aboutText
            });

            successNotification();

            // setDoc(doc(firestore,"Users",user.uid),{
            //     accountCreated: date,
            //     displayName: e.target.username.value,
            //     email: email,
            //     firstName: e.target.firstName.value,
            //     lastName: e.target.lastName.value,
            //     website: "used_web_version_not_app",
            //     uid: user.uid,
                
            // });
            
        } catch (error) {
            console.log(error);
            errorNotification();
            
        }
    }

    }    

    const professionalBackgroundResponses =(e)=>{
        e.preventDefault();
        const firestore = getFirestore();
        const date = new Date;

        if(profBackgroundText.trim()===""||
        companyDescriptionText.trim()===""||
        e.target.your_company_role.value.trim()===""||
        e.target.years_in_company.value.trim()===""||
        e.target.your_industry.value.trim()===""){
            emptyNotification();

        }else{

        try {

            updateDoc(doc(firestore,"Users",user.uid),{

                experience:{
                
                        professional_background: profBackgroundText,
                        company_desc: companyDescriptionText,
                        company_role: e.target.your_company_role.value,
                        company_years_in: e.target.years_in_company.value,
                        industry: e.target.your_industry.value
                }
                    
                }
            );

            successNotification();
            
        } catch (error) {
            console.log("prof_Experience");
            console.log(error);
            errorNotification();

            
        }
    }

    }



    const handleProfilePicChange = (e)=>{
        if(e.target.files[0]){
            // console.log("hey you"); 
            // console.log(e.target.files[0].name);
            setPhoto(e.target.files[0]);
           
            setPhotoURL(  URL.createObjectURL(e.target.files[0]));
        }

    }

    const handleUploadPicButton = async()=>{
        await uploadProfilePic(photo, user, setLoading);
        // navigate("/profile");


    }

    

    useEffect(()=>{
        getUsers();

        },[])

    return (
        <div>
            {/* if popup is open dont open */}
            {isSuccess && 
                    <div>
                    <Popup
                    handleClose = {successNotification}
                    content = {
                    <div>
                        <h2 className="success-popup">Congratulations!</h2>
                        <p>Your information was added successfully!</p>
                    </div>
                    }
                />
                </div>}


                {/* if popup is open dont open */}
                {isErrorMessage && 
                    <div>
                    <Popup
                    handleClose = {errorNotification}
                    content = {
                    <div>
                        <h2 className="error-popup">Error!</h2>
                        <p>There was an issue saving your information. Try again!</p>
                    </div>
                    }
                />
                </div>}


                {/* if popup is open dont open */}
                {isDuplicate && 
                    <div>
                    <Popup
                    handleClose = {duplicateNotification}
                    content = {
                    <div>
                        <h2 className="error-popup">Username already exists!</h2>
                        <p>The username you entered already exist. Enter a new one!</p>
                    </div>
                    }
                />
                </div>}


                {/* if popup is open dont open */}
                {isWhiteSpace && 
                    <div>
                    <Popup
                    handleClose = {whiteSpaceNotification}
                    content = {
                    <div>
                        <h2 className="error-popup">Usernames can not have space!</h2>
                        <p>Usernames can not have spaces. Enter a valid username.</p>
                    </div>
                    }
                />
                </div>}


                 {/* if popup is open dont open */}
                 {isEmpty && 
                    <div>
                    <Popup
                    handleClose = {emptyNotification}
                    content = {
                    <div>
                        <h2 className="error-popup">Field can not be empty!</h2>
                        <p>Field is empty.</p>
                    </div>
                    }
                />
                </div>}



            <h1>Manage Account</h1>
            <h2>Profile Picture</h2>

            <div className="prof_bg">
                <img src={photoURL} alt="ProfilePic" className="profilePic"/>

                <input type="file" onChange={handleProfilePicChange} />
                <button disabled ={loading || !photo} onClick={handleUploadPicButton}>Upload</button>
            </div>


            <h3>USERNAME</h3>
            <p>{user.displayName}</p>

            <form onSubmit={updateUsername}>
            <div className="prof_bg">
                <div className='prof-edu-inputs'>
                    <label  className="prof_bg_inputs">Update Username?</label>
                    <input
                    className="prof_bg_inputs"
                    type="text"
                    value={userName}
                    onChange = {e => saveUsername(e.target.value)}/>
                </div>
            </div>
            <button type="submit">Save</button>
            </form>

            <h3>EMAIL</h3>
            <p>{user.email}</p>
            <div className="horizontal-line"></div>

            <h2>Bio</h2>
            {/* <button>Edit</button> */}
            <form onSubmit={aboutResponse}>
            <div className="prof_bg">
                <div className='prof-edu-inputs'>
                    <label  className="prof_bg_inputs">About</label>
                    <textarea
                    rows={5} 
                    cols={35}
                    type="text"
                    value={aboutText}
                    onChange = {e => saveAboutText(e.target.value)}
                    className="prof_bg_inputs"></textarea>
                </div>
            </div>
            <button type="submit">Save</button>
            </form>

            <h2>Experience</h2>
            <form onSubmit={professionalBackgroundResponses}>
            <div className="prof_bg">
            <div className='prof-edu-inputs'>
                <label  className="prof_bg_inputs">What is your professional background</label>
                <textarea
                    rows={5} 
                    cols={35}
                    type="text"
                    value={profBackgroundText}
                    onChange = {e => saveProfBackgroundText(e.target.value)}
                    className="prof_bg_inputs"></textarea>
            </div>
            <div className='prof-edu-inputs'>
                <label className="prof_bg_inputs">Your company</label>

            <textarea
                    rows={5} 
                    cols={35}
                    type="text"
                    value={companyDescriptionText}
                    onChange = {e => saveCompanyDescriptionText(e.target.value)}
                    className="prof_bg_inputs"></textarea>                
            </div>
            <div className='prof-edu-inputs'>
                <label className="prof_bg_inputs">Your role in that company</label>
                <input 
                name="your_company_role"
                type="text"           
                className="prof_bg_inputs"></input>
            </div>
            <div className='prof-edu-inputs'>
                <label className="prof_bg_inputs">How many years of ownership experience do you have</label>
                <input
                name="years_in_company"
                type="number" 
                className="prof_bg_inputs"></input>
            </div>
            <div className='prof-edu-inputs'>
                <label className="prof_bg_inputs">What industry are you in</label>
                <input 
                name="your_industry"
                type="text"
                className="prof_bg_inputs"></input>
            </div>
            </div>
            <button type="submit">Save</button>
            </form>

            <div className="horizontal-line"></div>
            <div className="button-settings">
                <button onClick={logout(user)}>SIGN OUT</button>  
            
                {/* <button className="settings" onClick={deleteAccount}>DELETE ACCOUNT</button>             */}
            </div>
        
        </div>

    );
};
export default AccountSettings;